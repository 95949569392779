<template>
  <SideBar :activemode="activeClass"></SideBar>
  
  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum">
        <div class="container-fluid">
          <div class="row justify-content-end">
  
            <div class="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/dashboard"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item text-white"> <i class="fa-light fa-credit-card me-1"></i>Withdraw</li>
  
                </ol>
              </nav>
            </div>
          </div>
  
        </div>
      </section>
      <section class="pb-5">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-xxl-3 col-md-6">
              <div class="card my-2 py-2">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="col">
                      <p class="mb-2 text-white">Credit</p>
                      <h3 class="mb-0 number-font text-white" >Demo</h3>
                    </div>
                    <div class="col-auto mb-0">
                      <div class="dash-icon text-orange"> <i class="fa-regular fa-credit-card f-22"></i> </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-md-6">
              <div class="card my-2 py-2">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="col">
                      <p class="mb-2 text-white">Debit</p>
                      <h3 class="mb-0 number-font text-white">Demo</h3>
                    </div>
                    <div class="col-auto mb-0">
                      <div class="dash-icon text-secondary1"> <i class="fa-light fa-credit-card-blank f-22"></i> </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-md-6">
              <div class="card my-2 py-2">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="col">
                      <p class="mb-2 text-white">Total Income</p>
                      <h3 class="mb-0 number-font text-white">Demo</h3>
                    </div>
                    <div class="col-auto mb-0">
                      <div class="dash-icon text-secondary"> <i class="fa-regular fa-money-check-dollar f-22"></i> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
       
          </div>
        </div>
      </section>
  
      <section class="pb-5">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="income_form">
                <form action="">
                  <div class="text-center my-3">
                    <h3 class="text-white">Withdraw Detail</h3>
                  </div>
  
                  <div class="input-group mb-3">
                    <select class="form-select" id="inputGroupSelect01">
                      <option selected>Choose Option</option>
                      <option value="1">USDT</option>
                      <option value="2">SLT</option>
  
                    </select>
                  </div>
                  <div class="form-floating mb-3">
                    <input type="number" class="form-control" id="floatingInput" placeholder="Amount">
                    <label for="floatingInput">Amount</label>
                  </div>
  
  
  
  <div class="mb-3 text-center">
    <button class="button_a">Withdraw</button>
  </div>
  
  
  
  
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <section class="table-sec m-lg-3 rounded-2">
      
           
  
      <div class="container-fluid">
        <div class="row justify-content-center bg-light py-2 set_radius">
                  <div class="col-lg-6 text-center ">
                    <HeadingTitle title="Wallet Detail"></HeadingTitle>
                  </div>
                </div>
      </div>
      <div class="container-fluid p-4">
        <div class="row enteries-row align-items-center mb-5">
                  <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="entry-box ">
                      <h5>Show</h5>
                      <span><select class="select">
                          <option value="0">10</option>
                          <option value="0">20</option>
                          <option value="0">30</option>
                          <option value="0">40</option>
                          <option value="0">50</option>
                        </select></span>
        
                      <h6>enteries</h6>
                    </div>
                    <!--entry-box-->
                  </div>
                
                  <!--col-xl-6 col-lg-6 col-md-6-->
        
                  <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="search-box">
                      <div class="input-group">
                        <span class="input-group-text" id="basic-addon1"><img src="../../assets/images/search.svg" alt="" class="img-fluid"></span>
                        <input type="text" class="form-control" placeholder="Search....." aria-label="Username" aria-describedby="basic-addon1">
                      </div>
                    </div>
                    <!--search-box-->
                  </div>
                  <!--col-xl-6 col-lg-6 col-md-6-->
                </div>
                <!--row enteries-row-->
        
                <div class="row table-row">
                  <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="table-responsive">
                      <table class="table table-bordered ">
                        <thead>
                          <tr>
        
                            <th scope="col" class="fw-bold">S.NO <span><img src="../../assets/images/table_icon_1.png" alt="" class="img-fluid"></span></th>
                            <th scope="col" class="fw-bold">User Name <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                            <th scope="col" class="fw-bold">Wallet Address <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                            <th scope="col" class="fw-bold">Action <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
        
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Larry the Bird</td>
                            <td>@twitter</td>
                            <td>@twitter</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!--table-responsive-->
                  </div>
                  <!--col-xl-12 col-lg-12 col-md-12-->
                </div>
                <!--row table-row-->
        
                <div class="row pagination-row align-items-center">
                  <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="pages-entries-text">
                      <h5>showing</h5>
                      <span>1 to 10 of 57</span>
                      <h6>entries</h6>
                    </div>
                    <!--pages-entries-text-->
                  </div>
                  <!--col-xl-6 col-lg-6 col-md-6-->
        
                  <div class="col-xl-6 col-lg-6 col-md-6">
                    <div class="pagenation-inner">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item"><a class="page-link active" href="#">1</a></li>
                          <li class="page-item"><a class="page-link" href="#">2</a></li>
                          <li class="page-item"><a class="page-link" href="#">3</a></li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <!--pagenation-inner-->
                  </div>
                  <!--col-xl-6 col-lg-6 col-md-6--->
                </div>
                <!--row pagination-row-->
             
              <!--container-fluid-->
      </div>
      
              
            </section>
    </div>
  <FooterDetail></FooterDetail>
  </div>
  </template>
  
  <script>
  import HeadingTitle from "@/components/HeadingTitle.vue";
  import SideBar from "@/components/SideBar.vue";
  import HeaderDashboard from "@/components/HeaderDashboard.vue";
  import FooterDetail from "@/components/FooterDetail.vue";
  export default {
    name: "HomeDashboard",
    // props: ['main_wrapper'],
    components: {
      SideBar,
      HeaderDashboard,
      HeadingTitle,
      FooterDetail
    },
    data() {
      return {
        activeClass: 'sidebar',
        main_wrapper: 'main_wrapper',
  
      }
    },
    methods: {
      changeActiveMode(payload) {
        console.log(payload);
        this.activeClass = payload.activeClass,
          this.main_wrapper = payload.mainWrapperClass
      }
    }
  }
  </script>
  
  <style scoped>
  .income_form {
    background-color: #282828;
    padding: 20px 30px;
    border-radius: 5px;
    box-shadow: #f8f9fa 0px 0px 2px;
  }
  
  .box_a {
    background-size: 100% 100%;
    padding: 20px 25px;
    transition: .5s ease-in-out;
    min-height: 145px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .dash-icon.text-orange {
      background: rgba(236, 84, 68, 0.2);
  }
  .text-orange {
      color: #f86300 !important;
  }
  .dash-icon {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin: 10px auto;
      line-height: 43px;
      position: relative;
      background: #e3e6f9;
      z-index: 1;
      text-align: center;
  }
  .dash-icon i {
      text-align: center;
      vertical-align: middle;
  }
  .fs-22 {
      font-size: 22px !important;
  }
  .dash-icon.text-orange:after {
      background: rgba(236, 84, 68, 0.1);
  }
  .dash-icon:after {
      position: absolute;
      content: "";
      width: 65px;
      height: 65px;
      border-radius: 50%;
      bottom: 0;
      top: -10px;
      right: 0;
      left: -10px;
      background: rgba(235, 237, 252, 0.4);
      z-index: -1;
  }
  .dash-icon.text-secondary1 {
      background: rgba(36, 228, 172, 0.2);
  }
  .text-secondary1 {
      color: #24e4ac !important;
  }
  .dash-icon.text-secondary1:after {
      background: rgba(36, 228, 172, 0.1);
  }
  .text-secondary {
      color: #9c52fd !important;
  }
  .dash-icon.text-secondary:after {
    background: rgb(156 82 253 / 27%);
  }
  .card{
    background-color: #282828 !important;
    box-shadow: 0px 0px 2px 0px rgb(255 255 255);
  }
  </style>
  