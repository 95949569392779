<template>
  <SideBar :activemode="activeClass"></SideBar>
  
  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <LoderWait></LoderWait>
<TabeldDetail></TabeldDetail>
  
<LoderInfo></LoderInfo>











    </div>
  
  </div>
  </template>
  
  <script>
  import SideBar from "@/components/SideBar.vue";
  import HeaderDashboard from "@/components/HeaderDashboard.vue";
  import TabeldDetail from "@/components/TabelDetail.vue"
  import LoderWait from "@/components/LoderWait.vue"
  import LoderInfo from "@/components/LoderInfo.vue"
  export default {
    name: "HomeDashboard",
    // props: ['main_wrapper'],
    components: {
      SideBar,
      HeaderDashboard,
      TabeldDetail,
      LoderWait,
      LoderInfo
    },
    data() {
      return {
        activeClass: 'sidebar',
        main_wrapper: 'main_wrapper',
      }
    },
    methods: {
      changeActiveMode(payload) {
        console.log(payload);
        this.activeClass = payload.activeClass,
          this.main_wrapper = payload.mainWrapperClass
      }
    }
  }
  </script>
  
<style scoped>

</style>
  