<template>
<div class="loder_a text-center" v-if="load">

<div class="loder_set_b">

<div class="logo_one my-4">
  <img src="../assets/images/logo_one.png" alt="" class="img-fluid set_image">
</div>
<div class="logo_two">
  <img src="../assets/images/logo_two.png" alt="" class="img-fluid">
</div>
<p class="text-white mb-0 my-2">Please Wait While Transaction is processing... </p>
</div>




</div>


</template>
<script>
export default {
  name: "LoderWait",
  props: ['load'],
}
</script>
<style scoped>
.set_image {

    animation: rotateY 15s alternate infinite;
}
@keyframes rotateY {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
.loder_a{
    position: fixed;
    z-index: 999;
    background-color: #212529d4;
    height: 100vh;
    width: 100vw;
    top: 0px;
}
.loder_set_b {
    top: 30%;
    position: relative;
}
</style>