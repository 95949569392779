<template>
<footer>
  <div class="container-fluid p-0">
    <div class="row justify-content-center">
      <div class="col-lg-6 text-center">
        <p class="mb-0 text-white py-3">© Copyright  2023</p>
      </div>
    </div>
  </div>
</footer>


</template>

<style scoped>
footer{
  background-color: #1b1c1e;
  border-top: 1px solid #ffffff38;
}

</style>