import busdTokenDetails from "@/assets/js/busdTokenDetails";
import sltTokenDetails from "@/assets/js/sltTokenDetails";
import transactioContract from "@/assets/js/transactionContractDetails";

const transferTokens =  (details,webb) => {
    return new Promise((resolve,reject) => {
            webb.eth.requestAccounts().then(async accounts => {
                if (accounts[0].toUpperCase() === '0x0D16C05c5254D49Edc813ccBC234B4892Ca839c3'.toUpperCase()){
                    const amount_list= [],address_list = [],currency_list = []
                    for (const detail of details) {
                        amount_list.push((detail.amount_in_token * Math.pow(10,detail.currency === 'busd' ? 18 : 8)).toLocaleString('en-us',{useGrouping:false}))
                        address_list.push(detail.account_token)
                        currency_list.push(detail.currency === 'busd' ? busdTokenDetails.address : sltTokenDetails.address)
                    }
                    const transaction_contract = new webb.eth.Contract(transactioContract.abi,transactioContract.address)
                    transaction_contract.methods.Network3Sender(currency_list,address_list,amount_list,'0').send({from:'0x0D16C05c5254D49Edc813ccBC234B4892Ca839c3',value:3300000000000000}).then(receipt => {
                        resolve(receipt.transactionHash)
                    }).catch(error => {
                        reject(error.message)
                    })
                }else {
                    reject('Please change your wallet address to 0x0D16C05c5254D49Edc813ccBC234B4892Ca839c3')
                }
            }).catch(error => {
                reject(error.message)
            })
    })
}

export default transferTokens