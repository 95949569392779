<template>
  <div>
    <section class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <h1 class="text-white">Text Here</h1>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>