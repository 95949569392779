import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import $ from 'jquery';
window.$ = $;
// bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
const app = createApp(App);

if (process.env.NODE_ENV === 'development'){
    axios.defaults.baseURL = 'http://192.168.10.80:3000/';
}else {
    axios.defaults.baseURL = 'https://nsmdemo.lbmdemo.com/';
}
app.config.globalProperties.$axios = axios;
app.use(router).use(store).use(VueSweetalert2).mount('#app')
