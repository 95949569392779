import {createStore} from 'vuex'

const store = createStore({
    state(){
        return {
            is_login:false,
            user:''
        }
    },
    getters:{
        getUser(state){
            return state.user
        }
    },
    mutations:{
        setUserDetails(state,userDetails){
            state.user = userDetails
        },
        updateUserDetails(state){
            state.user = '';
        },
        updateLoginStatus(state,status){
            state.is_login = status
        }
    }
})

export default store