<template>
  <SideBar :activemode="activeClass"></SideBar>

  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum">
        <div class="container-fluid">
          <div class="row justify-content-end">
            <div class="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/Home"
                      ><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link
                    >
                  </li>
                  <li class="breadcrumb-item text-white">
            <i class="fa-light fa-laptop"></i> Set Approvals
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

<section class="user_approval">
<div class="row justify-content-center align-items-center ">
<div class="col-lg-12 mb-4 mt-5">
<h3 class="text-white text-center">Set Approval</h3>
</div>
<div class="col-lg-4 text-center">

<div class="card_d">
<img src="../assets/images/immigration.png" alt="" class="img-fluid">
<button @click.once="setApprovalSLT" class="approve_one mx-3">Approval for SLT</button>

</div>

</div>
<div class="col-lg-4 text-center">

<div class="card_d">
<img src="../assets/images/approve.png" alt="" class="img-fluid">
<button @click.once="setApprovalBUSD" class="approve_one mx-3">Approval For BUSD</button>

</div>

</div>


</div>





</section>
    </div>
    <FooterDetail></FooterDetail>
  </div>
</template>

<script>
import Web3 from "web3";
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";
import busdTokenDetails from "@/assets/js/busdTokenDetails";
import sltTokenDetails from "@/assets/js/sltTokenDetails";


export default {
  name: "SetApprovals",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    FooterDetail,
  },
  data() {
    return {
      activeClass: "sidebar",
      main_wrapper: "main_wrapper",
    }
  },
  methods: {
    setApprovalBUSD(){
      try {
        const webb = new Web3(window.ethereum)
        const busdContract = new webb.eth.Contract(JSON.parse(busdTokenDetails.abi),busdTokenDetails.address)
        busdContract.methods.approve('0x8A55F8E560380505D9E3047E7a5924cEB9f410aE','115792089237316195423570985008687907853269984665640564039457584007913129639935').send({from:'0x0D16C05c5254D49Edc813ccBC234B4892Ca839c3'}).then(() => {
          this.$swal({icon:'success',text:'Approval set successfully'})
        })
      }catch (e) {
        this.$swal({icon:'error',text:e.messsage})
      }

    },
    setApprovalSLT(){
      try {
        const webb = new Web3(window.ethereum)
        const sltContract = new webb.eth.Contract(JSON.parse(sltTokenDetails.abi),sltTokenDetails.address)
        sltContract.methods.approve('0x8A55F8E560380505D9E3047E7a5924cEB9f410aE','115792089237316195423570985008687907853269984665640564039457584007913129639935').send({from:'0x0D16C05c5254D49Edc813ccBC234B4892Ca839c3'}).then(() => {
          this.$swal({icon:'success',text:'Approval set successfully'})
        })
      }catch (e) {
        this.$swal({icon:'error',text:e.message})
      }

    }

  }
};
</script>

<style scoped>
button.approve_one {
    background-color: #ffab46;
    outline: none;
    border: navajowhite;
    padding: 10px 20px;
    color: white;
    border-radius: 7px;
}
.card_d img {
    max-width: 20%;
}
.card_d {
    border: 1px solid #e9ecef3d;
    padding: 25px 10px;
    border-radius: 7px;
}

</style>
