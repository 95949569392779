<template>
<div class="heading_box">
  <h3 class="text-dark fst-italic">{{ title }}</h3>
</div>



</template>


<script>
export default {
  name: 'HeadingTitle',
  props: {
    title:String,
  }
}
</script>
<style>
.heading_box h3{
  font-weight: 700;
}

</style>