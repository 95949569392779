import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "@/views/LoginView.vue"
import HomeDashboard from "@/views/HomeDashboard.vue"
import WalletView from "@/views/WalletView.vue"
import SignUp from "@/views/SignUp.vue"
import TreeView from "@/views/TreeView.vue"
import PackageUpgrade from "@/views/PackageUpgrade.vue"
import RewardDetail from "@/views/RewardDetail.vue"
import DummyTabel from "@/views/DummyTabel.vue"
import IncomeDetail from "@/views/IncomeDetail.vue"
import LevelDetail from "@/views/LevelDetail.vue"
import AdminDashboard from "../views/Admin/AdminDashboard"
import AdminTree from "../views/Admin/AdminTree"
import DirectTeam from "../views/Admin/DirectTeam"
import AdminUserlist from "../views/Admin/AdminUserlist"
import AdminIncome from "../views/Admin/AdminIncome"
import AdminLevelincome from "../views/Admin/AdminLevelincome"
import AdminPoolincome from "../views/Admin/AdminPoolincome"
import AdminWithdrawl from "../views/Admin/AdminWithdrawl"
import AdminWithdrawlrequest from "../views/Admin/AdminWithdrawlrequest"
import TermsDetail from "@/views/TermsDetail.vue"
import GenrationTree from "@/views/GenrationTree"
import WebsiteDetail from "@/views/WebsiteDetail.vue"
import AdminAddnotification from "../views/Admin/AdminAddnotification"
import AdminCurrencies from "../views/Admin/AdminCurrencies"


import SetApprovals from "@/views/SetApprovals";
import DirectIncome from "@/views/DirectIncome";
import PoolIncome from "@/views/PoolIncome";
import LeadershipIncome from "@/views/LeadershipIncome";
import BoosterIncome from "@/views/BoosterIncome";
import AutopoolTree from "@/views/AutopoolTree";
import BoosterTree from "@/views/BoosterTree";
import ClientTicket from "@/views/ClientTicket";
import AdminTicket from "@/views/AdminTicket";
const routes = [
  {
    path: '/login:referral?',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: HomeDashboard
  },
  {
    path: '/Wallet',
    name: 'WalletView',
    component: WalletView
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/poolTree',
    name: 'TreeView',
    component: TreeView
  },
  {
    path: '/boosterTree',
    name: 'BoosterTree',
    component: BoosterTree
  },
  {
    path: '/Upgrade',
    name: 'PackageUpgrade',
    component: PackageUpgrade
  },
  {
    path: '/Reward',
    name: 'RewardDetail',
    component: RewardDetail
  },
  {
    path: '/Income',
    name: 'IncomeDetail',
    component: IncomeDetail
  },
  {
    path: '/direct',
    name: 'DirectIncome',
    component: DirectIncome
  },
  {
    path: '/Level',
    name: 'LevelDetail',
    component: LevelDetail,
  },
  {
    path: '/Dummy',
    name: 'DummyTabel',
    component: DummyTabel
  },
  // admin pages start here



  // home page
  {
    path: '/AdminDashboard',
    name: 'AdminDashboard',
    component: AdminDashboard
  },
  // tree page
  {
    path: '/AdminTree',
    name: 'AdminTree',
    component: AdminTree
  },
    // Direct Team page
  {
    path: '/AdminDirectTeam',
    name: 'DirectTeam',
    component: DirectTeam
  },
    // user list page
    {
      path: '/AdminUserlist',
      name: 'AdminUserlist',
      component: AdminUserlist
    },
     // admin income page
     {
      path: '/AdminIncome',
      name: 'AdminIncome',
      component: AdminIncome
    },
      // admin level page
      {
        path: '/AdminLevelIncome',
        name: 'AdminLevelincome',
        component: AdminLevelincome
      },
       // admin Pool page
       {
        path: '/AdminPoolIncome',
        name: 'AdminPoolincome',
        component: AdminPoolincome
      },
        // admin withdrawl page
        {
          path: '/AdminWithdrawl',
          name: 'AdminWithdrawl',
          component: AdminWithdrawl
        },
       // admin withdrawlrequest page
       {
        path: '/AdminWithdrawlrequest',
        name: 'AdminWithdrawllrquest',
        component: AdminWithdrawlrequest
      },
      {
        path: '/Terms',
        name: 'TermsDetail',
        component: TermsDetail
      },
      {
        path: '/generation',
        name: 'GenrationTree',
        component: GenrationTree
      },
      {
        path: '/',
        name: 'WebsiteDetail',
        component: WebsiteDetail
      },
      {
        path: '/autopooltree',
        name: 'AutopoolTree',
        component: AutopoolTree
      },
      
      {
        path: '/PoolIncome',
        name: 'PoolIncome',
        component: PoolIncome
      },
            
      {
        path: '/LeadershipIncome',
        name: 'LeadershipIncome',
        component: LeadershipIncome
      },
      
      {
        path: '/BoosterIncome',
        name: 'BoosterIncome',
        component: BoosterIncome
      },
      
      {
        path: '/signup:referral?',
        name: 'Signupref',
        component: SignUp
      },      
      {
        path: '/SetApprovals',
        name: 'SetApprovals',
        component: SetApprovals
      },
      // {
      //   path: '/signup:referral?',
      //   name: 'SignUpRef',
      //   component: SignUp
      // },
      {
        path: '/Addnotification',
        name: 'AdminAddnotification',
        component: AdminAddnotification
      },
      {
        path: '/Currencies',
        name: 'AdminCurrencies',
        component: AdminCurrencies
      },

      {
        path: '/Client-ticket',
        name: 'ClientTicket',
        component: ClientTicket
      },

      {
        path: '/admin-ticket',
        name: 'AdminTicket',
        component: AdminTicket
      },


     
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from) => {
    if (to.name !== 'WebsiteDetail' && to.name !== 'SignUp' ){
        if (!from.name){
            return {name:'WebsiteDetail'};
        }
    }
})

export default router
