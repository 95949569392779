<template>
<LoderInfo :load="load"></LoderInfo>

<SideBar :activemode="activeClass"></SideBar>

<div :class="main_wrapper">
  <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
  <div class="main_wrapper_b">
    <section class="bread_cum">
      <div class="container-fluid">
        <div class="row justify-content-end">

          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/Home"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                </li>
                <li class="breadcrumb-item text-white"> <i class="fa-light fa-hand-holding-dollar me-1"></i>Package Upgrade</li>

              </ol>
            </nav>
          </div>
        </div>

      </div>
    </section>
    <section class="pb-5">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-xxl-3 col-md-6">
            <div class="card my-2 py-2">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="col">
                    <p class="mb-2 text-white">Current Package</p>
                    <h3 class="mb-0 number-font text-white"> ${{ this.$store.state.user.current_package }} </h3>
                  </div>
                  <div class="col-auto mb-0">
                    <div class="dash-icon text-orange"> <i class="fa-regular fa-credit-card f-22"></i> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="pb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="income_form">
              <!-- <form action=""> -->
                <div class="text-center my-3">
                  <h3 class="text-white">Retop Up / Upgrade</h3>
                </div>

                <div class=" mb-3">
                  <label for="" class="form-label text-white">Package</label>
                  <!-- <select class="form-select" aria-label="Default select example">
                    <option selected>Select </option>
                    <option value="1">$50</option>
                    <option value="2">$150</option>

                  </select> -->

                <select class="form-select"  v-model="selected_package">
                  <option value="">Select Package</option>
                  <option v-for="(option, index) in packages" :key="index" :value="option.amount">
                    ${{ option.amount }}
                  </option>
                </select>
                </div>
                <div class=" mb-3">
                  <label class="form-label text-white">Select Currency</label>
                  <select class="form-select" v-model="currency" aria-label="Default select example">
                    <option value="" selected > Select Currency</option>
                    <option v-for="(option, index) in currencyOptions" :key="index" :value="option.name">
                      {{ (option.name).toUpperCase() }}
                    </option>
<!--                    <option value="slt">SLT</option>-->
                  </select>
                </div>

                <div class="mb-3 text-center">
                  <button class="button_a" @click.once="packageUpgrade" :key="buttonKey">Buy</button>
                </div>

              <!-- </form> -->
            </div>
          </div>
        </div>
      </div>
    </section>
<section class="m-lg-3">
  <section class="table-sec  rounded-2 mb-5 p-">

<div class="container-fluid ">
  <div class="row justify-content-center bg-light py-2 set_radius">
    <div class="col-lg-6 text-center ">
      <HeadingTitle title="Package Upgrade Detail"></HeadingTitle>
    </div>
  </div>
</div>
<div class="container-fluid p-4 ">
  <div class="row enteries-row align-items-center mb-5">
    <div class="col-xl-6 col-lg-6 col-md-6">
      <div class="entry-box ">
        <h5>Show</h5>
        <span>
          <select class="select" v-model="limit" v-on:change="getMoreResults">
            <option
              v-for="(option, index) in currencyoptions"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </span>

        <h6>enteries</h6>
      </div>
      <!--entry-box-->
    </div>

    <!--col-xl-6 col-lg-6 col-md-6-->

    <!-- <div class="col-xl-6 col-lg-6 col-md-6">
      <div class="search-box">
        <div class="input-group">
          <span class="input-group-text" id="basic-addon1"><img src="../assets/images/search.svg" alt="" class="img-fluid"></span>
          <input type="text" class="form-control" placeholder="Search....." aria-label="Username" aria-describedby="basic-addon1">
        </div>
      </div>
    </div> -->
  </div>
  <!--row enteries-row-->

  <div class="row table-row">
    <div class="col-xl-12 col-lg-12 col-md-12">
      <div class="table-responsive">
        <table class="table table-bordered ">
          <thead>
            <tr>

              <th scope="col" class="fw-bold">Amount in USD <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
              <th scope="col" class="fw-bold">Amount in Token<span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
              <th scope="col" class="fw-bold">Transaction Hash <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
              <th scope="col" class="fw-bold">Currency <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
              <th scope="col" class="fw-bold">Date<span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>

            </tr>
          </thead>
          <tbody v-if="transactions.length">
            <tr v-for="(transaction, index) in transactions" :key="index">
              <td>{{ transaction.amount_in_usd }}</td>
              <td>{{ transaction.amount_in_token }}</td>
              <td>{{ transaction.trx_hash }}</td>
              <td>{{ transaction.currency }}</td>
              <td>{{ dateFormate(transaction.created_at) }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">No Data Available</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--table-responsive-->
    </div>
    <!--col-xl-12 col-lg-12 col-md-12-->
  </div>
  <!--row table-row-->

  <div class="row pagination-row align-items-center" v-if="transactions.length">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pages-entries-text">
                <h5>showing</h5>
                <span
                  >{{ 1 + limit * currentPage - limit }} to
                  {{ limit * currentPage - limit + transactions.length }} of
                  {{ total }}</span
                >
                <h6>entries</h6>
              </div>
              <!--pages-entries-text-->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pagenation-inner">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a
                        :class="currentPage > 1 ? 'page-link' : 'page-link disabled'"
                        href="#"
                        aria-label="Previous"
                        @click="getTransactionDetails(currentPage - 1)"
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    <li
                      class="page-item"
                      v-for="index in totalPages"
                      :key="index"
                      @click="getTransactionDetails(index)"
                    >
                      <a
                        :class="index == currentPage ? 'page-link active' : 'page-link'"
                        href="#"
                      >
                        {{ index }}
                      </a>
                    </li>

                    <li class="page-item">
                      <a
                        :class="
                          currentPage < totalPages ? 'page-link' : 'page-link disabled'
                        "
                        href="#"
                        aria-label="Next"
                        @click="getTransactionDetails(currentPage + 1)"
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!--pagenation-inner-->
            </div>
            <!--col-xl-6 col-lg-6 col-md-6--->
          </div>
  <!--row pagination-row-->

  <!--container-fluid-->
</div>

</section>
</section>

  </div>
  <FooterDetail></FooterDetail>
</div>
</template>

  
<script>
import Web3 from 'web3';
import transaction from "@/assets/js/transaction";
import HeadingTitle from "@/components/HeadingTitle.vue";
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";
import LoderInfo from "@/components/LoderInfo.vue";

export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    HeadingTitle,
    FooterDetail,
    LoderInfo
  },
  data() {
    return {
      activeClass: 'sidebar',
      main_wrapper: 'main_wrapper',
      // current_package: 1 ,
      packages: [] ,
      transactions: [] ,
      selected_package: '',
      currency:'',
      currencyOptions:'',
      token_price: '',
      packageCheck: false,
      buttonKey: 1,
      totalPages: 0,
      currentPage: 1,
      total: 0,
      limit: 10,
      options: [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      load: false,
    }
  },
  methods: {
    dateFormate(date){
      let cur_date = new Date(date);
      cur_date.setTime(cur_date.getTime() + 19800000)
      cur_date = cur_date.toISOString()
      return cur_date.substr(0, (cur_date.length - 5)).replace('T', ' ');
    },
    changeActiveMode(payload) {
      this.activeClass = payload.activeClass
        this.main_wrapper = payload.mainWrapperClass
    },
    async getActiveCurrencies(){
      this.$axios.get('getcurrencies').then(result => {
        this.currencyOptions = result.data.currencies;
      });
    },
    enableClickOnce() {
      this.buttonKey++
    },
    async getResults() {
      this.$axios.get("packages").then((result) => {
        this.packages = result.data;
      })
    },

    getMoreResults() {
      let page = 1;
      this.getTransactionDetails(page, this.limit);
    },

    async getTransactionDetails(page = 1) {
      this.$axios.get("gettransactions?page=" + page + "&limit=" + this.limit).then((result) => {
        this.transactions = result.data.transactions;
        this.totalPages = result.data.totalPages;
        this.total = result.data.count;
        this.currentPage = parseInt(result.data.currentPage);
      });
    },

    async packageUpgrade(){
      try {
        if(this.selected_package === this.$store.state.user.current_package || this.selected_package === (this.$store.state.user.current_package * 2) || this.selected_package === 100){
          this.load = true;
          let amount_in_token = this.currency === 'busd' ? this.selected_package : (parseInt(this.selected_package) / parseFloat(this.token_price)).toFixed(8);

          let web = new Web3(window.ethereum);
          let trx_hash = await transaction(web,this.currency,this.$store.state.user.account_token,amount_in_token);
          let package_upgrade =  await this.$axios.post('userPackageUpgrade',{
            account_token:this.$store.state.user.account_token,
            currency:this.currency,
            amount_in_token:amount_in_token,
            amount_in_usd:this.selected_package,
            trx_hash:trx_hash ,
          })

          if (package_upgrade.data[0]){
            this.$swal({icon:'success',text:package_upgrade.data[1]}).then(() => {
              this.$store.commit('setUserDetails',package_upgrade.data[2])
              this.selected_package = ''
              this.getResults();
              this.getMoreResults();
              this.$router.push({name:'PackageUpgrade'});
            })
          }else {
            this.$swal({icon:'error',text:package_upgrade.data[1]})
          }
         }else{
           this.$swal({icon:'error',text:"You can only buy same package or package next to your Current Package."})
         }
        this.enableClickOnce();
        this.load = false;  
      }catch (error) {
        let err = typeof error === 'object' ? error.message : error;
        this.$swal({icon:'error',text:err})
        this.enableClickOnce();
        this.load = false;  
      }

    },

    getTokenPrice(){
      this.$axios.get('tokenPrice').then(price_in_usd => {
       this.token_price = price_in_usd.data[1];
      });
    }
  },
  async beforeMount() {
    this.getResults();
    this.getTokenPrice();
    this.getTransactionDetails();
    this.getActiveCurrencies();
  },
}
</script>

  
<style scoped>
.income_form {
  background-color: #282828;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: #f8f9fa 0px 0px 2px;
}

.box_a {
  background-size: 100% 100%;
  padding: 20px 25px;
  transition: .5s ease-in-out;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dash-icon.text-orange {
  background: rgba(236, 84, 68, 0.2);
}

.text-orange {
  color: #f86300 !important;
}

.dash-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 10px auto;
  line-height: 43px;
  position: relative;
  background: #e3e6f9;
  z-index: 1;
  text-align: center;
}

.dash-icon i {
  text-align: center;
  vertical-align: middle;
}

.fs-22 {
  font-size: 22px !important;
}

.dash-icon.text-orange:after {
  background: rgba(236, 84, 68, 0.1);
}

.dash-icon:after {
  position: absolute;
  content: "";
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: 0;
  top: -10px;
  right: 0;
  left: -10px;
  background: rgba(235, 237, 252, 0.4);
  z-index: -1;
}

.dash-icon.text-secondary1 {
  background: rgba(36, 228, 172, 0.2);
}

.text-secondary1 {
  color: #24e4ac !important;
}

.dash-icon.text-secondary1:after {
  background: rgba(36, 228, 172, 0.1);
}

.text-secondary {
  color: #9c52fd !important;
}

.dash-icon.text-secondary:after {
  background: rgb(156 82 253 / 27%);
}

.card {
  background-color: #282828 !important;
  box-shadow: 0px 0px 2px 0px rgb(255 255 255);
}
</style>
