<template>
<header >

    <nav class="navbar navbar-expand-lg ">
      <div class="container">
        <a class="navbar-brand" href="javascript:void(0)">LOGO</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="toggler-icon top-bar"></span>
        <span class="toggler-icon middle-bar"></span>
        <span class="toggler-icon bottom-bar"></span>

        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link  nav_link_a"  href="#home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav_link_a" href="#about">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav_link_a" href="#advantage">Advantages</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav_link_a" href="#tokenomics">Tokenomics</a>
            </li>
            <li class="nav-item">
              <a class="nav-link nav_link_a" href="#road-map">Roadmap</a>
            </li>
            <li class="nav-item login-a" v-if="!this.login">
              <router-link to="/login" class="nav-link nav_link_a">Login</router-link>
            </li>
            <li class="nav-item register-a" v-if="!this.login">
              <router-link to="/signup" class="nav-link nav_link_a">Register</router-link>
            </li>
            <li class="nav-item register-a" v-if="this.login">
              <router-link to="/dashboard" class="nav-link nav_link_a">Go To Dashboard</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

</header>
<!-- home section start -->
<section id="home" class="p-0">
  <video autoplay muted loop id="myVideo">
    <source src="../assets/images/website/earth-5(edit).mp4" type="video/mp4">
  </video>
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <div class="home-content">
          <h1 class="home-h1">Shine brightly by embracing <br>The <span>Evolution of blockchain.</span></h1>
          <p>It's designed with security in mind, it's upgradeable, and it's built to last.</p>
              <router-link to="/signup" class="nav-link nav_link_a" v-if="!this.login"><button>Register</button></router-link>
              <router-link to="/dashboard" class="nav-link nav_link_a" v-if="this.login"><button>Go To Dashboard</button></router-link>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- about section start -->
<section id="about">
  <div class="container">
    <div class="row text-white align-items-center">
      <div class="col-md-6 col-lg-6">
        <div class="about-left">
          <h1>About <span class="theme-color">Demo </span> Token</h1>
          <p>Demo Token is a BEP-20-based digital asset built on the popular Binance Smart Chain (BSC) blockchain. The developers of Demo Token have designed it to be used as a peer-to-peer payments platform, and they have also integrated it with multiple exchanges, wallets, and payment services to make it better accessible. Demo Token offers users an efficient, secure, and fast way to transact.</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-6">
        <div class="about-right">
          <!-- <img src="./assets/images/about-img.png" class="img-fluid"> -->
          <img src="../assets/images/website/about-img.png" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>
<!-- advantage -->
<section id="advantage">
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <h1>Advantages of <span class="theme-color">Demo</span> Token</h1>
      </div>
    </div>
    <div class="row text-white align-items-center">
      <div class="col-md-6 col-lg-6">
        <div class="left">
          <img src="../assets/images/website/advantage-img.png" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6 col-lg-6">
        <div class="right">
          <p>Demo Token has many advantages over other cryptocurrencies when it comes to security; its BEP-20 compatibility allows users to trust their transactions are being processed securely on the blockchain. For example, all transfers are encrypted using advanced cryptographic algorithms, making them extremely difficult to hack or tamper with. Furthermore, the token is powered by the innovative consensus protocol BFT (Byzantine Fault Tolerance), which ensures that all transactions are validated before being added to the blockchain ledger.</p>
          <p>Finally, its decentralized nature means that no single authority or entity controls how much Demo Token is available for trading or how it’s priced – allowing users more control over how they use their tokens.</p>
          <p>In terms of scalability and liquidity, Demo Token is capable of handling high transaction volumes quickly and efficiently due to its low gas fees and fast block times on the Binance Smart Chain network.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="slider_za">
  <div class="container">
    <swiper :spaceBetween="30" :centeredSlides="true" :autoplay="{
      delay: 2500,
      disableOnInteraction: false,
    }" :pagination="{
      clickable: true,
    }" :navigation="false" :modules="modules" class="mySwiper">
      <swiper-slide>
        <div class="row text-white align-items-center row_a">
          <div class="col-lg-6">
            <div class="token-left">
              <h1>What is a <span>BEP-20 Token</span> ?</h1>
              <p>A BEP-20 token is a type of token built on the Binance Smart Chain (BSC) blockchain protocol. It was created by Binance, one of the world's leading cryptocurrency exchanges, and is based on the Ethereum ERC-20 token standard. It provides developers with an easy way to create tokens for their projects, giving them access to a global network of users and developers. BEP-20 tokens offer faster transaction times than Ethereum tokens, as well as cheaper transaction fees. They also feature enhanced security, thanks to the BSC's improved consensus mechanism and its support for smart contracts.</p>
            </div>
          </div>
          <div class=" col-lg-6">
            <div class="token-right">
              <img src="../assets/images/website/bep-20token.png" class="img-fluid">
            </div>
          </div>
        </div>

      </swiper-slide>
      <swiper-slide>
        <div class="row text-white align-items-center row_a">
          <div class=" col-lg-6">
            <div class="token-left">
              <h1>What is the <span class="theme-color">Binance Smart Chain</span> Platform?</h1>
              <p>Binance Smart Chain Platform is a blockchain-based infrastructure developed by the world’s largest cryptocurrency exchange, Binance. It is a high-performance and low-cost smart contract platform that provides developers with an alternative to Ethereum. The platform enables faster and more cost-effective transactions, and its native token BNB can be used to pay for transaction fees on the network.</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="token-right">
              <img src="../assets/images/website/smart-chain.png" class="img-fluid">
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="row text-white align-items-center row_a">
          <div class="col-lg-6">
            <div class="token-left">
              <h1>How does <span class="theme-color">BEP-20</span> Differ From <span class="theme-color">ERC-20?</span></h1>
              <p>The most notable difference between BEP-20 and ERC-20 is the underlying blockchain network they are based on. While ERC-20 tokens are based on the Ethereum blockchain, BEP-20 tokens are built on Binance Chain, a public blockchain developed by Binance. This means that while ERC-20 tokens can be stored in any wallet that supports Ethereum, BEP-20 tokens must be stored in a wallet designed specifically for the Binance Chain such as Trust Wallet or Ledger hardware wallet.</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="token-right">
              <img src="../assets/images/website/ERC-20.png" class="img-fluid">
            </div>
          </div>
        </div>
      </swiper-slide>

    </swiper>
  </div>

</section>

<!-- bep-20 token for mobiles and tablet -->
<section class="for-mob">
  <section id="bep-20-token">
    <div class="container">
      <div class="row text-white align-items-center">
        <div class="col-md-6 col-lg-6">
          <div class="token-left">
            <h1>About <span class="theme-color">Demo </span> Token</h1>
            <p>Demo Token is a BEP-20-based digital asset built on the popular Binance Smart Chain (BSC) blockchain. The developers of Demo Token have designed it to be used as a peer-to-peer payments platform, and they have also integrated it with multiple exchanges, wallets, and payment services to make it better accessible. Demo Token offers users an efficient, secure, and fast way to transact.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="token-right">
            <img src="../assets/images/website/bep-20token.png" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="bep-20-token1">
    <div class="container">
      <div class="row text-white align-items-center second-bep-20">
        <div class="col-md-6 col-lg-6">
          <div class="token-left">
            <h1>What is the <span class="theme-color">Binance Smart Chain</span> Platform?</h1>
            <p>Binance Smart Chain Platform is a blockchain-based infrastructure developed by the world’s largest cryptocurrency exchange, Binance. It is a high-performance and low-cost smart contract platform that provides developers with an alternative to Ethereum. The platform enables faster and more cost-effective transactions, and its native token BNB can be used to pay for transaction fees on the network.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="token-right">
            <img src="../assets/images/website/smart-chain.png" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="bep-20-token2">
    <div class="container">
      <div class="row text-white align-items-center">
        <div class="col-md-6 col-lg-6">
          <div class="token-left">
            <h1>How does <span class="theme-color">BEP-20</span> Differ From <span class="theme-color">ERC-20?</span></h1>
            <p>The most notable difference between BEP-20 and ERC-20 is the underlying blockchain network they are based on. While ERC-20 tokens are based on the Ethereum blockchain, BEP-20 tokens are built on Binance Chain, a public blockchain developed by Binance. This means that while ERC-20 tokens can be stored in any wallet that supports Ethereum, BEP-20 tokens must be stored in a wallet designed specifically for the Binance Chain such as Trust Wallet or Ledger hardware wallet.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="token-right">
            <img src="../assets/images/website/ERC-20.png" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </section>
</section>

<!-- <main>
          <article>
             <section id="bep-20-token">
                <div class="container">
                   <div class="row text-white align-items-center">
                      <div class="col-lg-6">
                         <div class="token-left">
                            <h1>About <span class="theme-color">Sunlight </span> Token</h1>
                            <p>Sunlight Token is a BEP-20-based digital asset built on the popular Binance Smart Chain (BSC) blockchain. The developers of Sunlight Token have designed it to be used as a peer-to-peer payments platform, and they have also integrated it with multiple exchanges, wallets, and payment services to make it better accessible. Sunlight Token offers users an efficient, secure, and fast way to transact.</p>
                         </div>
                      </div>
                      <div class="col-lg-6">
                         <div class="token-right">
                            <img src="./images/bep-20token.png" class="img-fluid">
                         </div>
                      </div>
                   </div>
                </div>
             </section>
             <section id="bep-20-token">
                <div class="container">
                   <div class="row text-white align-items-center">
                      <div class="col-lg-6">
                         <div class="token-left">
                            <h1>What is the <span class="theme-color">Binance Smart Chain</span> Platform?</h1>
                            <p>Binance Smart Chain Platform is a blockchain-based infrastructure developed by the world’s largest cryptocurrency exchange, Binance. It is a high-performance and low-cost smart contract platform that provides developers with an alternative to Ethereum. The platform enables faster and more cost-effective transactions, and its native token BNB can be used to pay for transaction fees on the network.</p>
                         </div>
                      </div>
                      <div class="col-lg-6">
                         <div class="token-right">
                            <img src="./images/smart-chain.png" class="img-fluid">
                         </div>
                      </div>
                   </div>
                </div>
             </section>
             <section id="bep-20-token">
                <div class="container">
                   <div class="row text-white align-items-center">
                      <div class="col-lg-6">
                         <div class="token-left">
                            <h1>How does <span class="theme-color">BEP-20</span> Differ From <span class="theme-color">ERC-20?</span></h1>
                            <p>The most notable difference between BEP-20 and ERC-20 is the underlying blockchain network they are based on. While ERC-20 tokens are based on the Ethereum blockchain, BEP-20 tokens are built on Binance Chain, a public blockchain developed by Binance. This means that while ERC-20 tokens can be stored in any wallet that supports Ethereum, BEP-20 tokens must be stored in a wallet designed specifically for the Binance Chain such as Trust Wallet or Ledger hardware wallet.</p>
                         </div>
                      </div>
                      <div class="col-lg-6">
                         <div class="token-right">
                            <img src="./images/ERC-20.png" class="img-fluid">
                         </div>
                      </div>
                   </div>
                </div>
             </section>
          </article>
       </main> -->

<!-- tokenomics -->

<section id="tokenomics" class="bg-dark">
  <div class="container">
    <div class="row">
      <div class="col text-center text-white">
        <h1>Tokenomics</h1>
      </div>
    </div>
    <div class="row align-items-center mt-4">
      <div class="col-md-6 col-lg-7 col-xl-8 mb-5 mb-md-0">
        <div class="tokinomics-image">
          <img src="../assets/images/qwerty.png" class="img-fluid">
        </div>

      </div>
      <div class="col-md-6 col-lg-5 col-xl-4">
        <div class="row text-white">

          <ul class="tokenomics-box">
            <li>
              <span class="one"></span>
              <p>Staking Platform 40%</p>
            </li>
            <li>
              <span class="two"></span>
              <p>Community 10%</p>
            </li>
            <li>
              <span class="three"></span>
              <p>Marketing & Development 20%</p>
            </li>
            <li>
              <span class="four"></span>
              <p>Dex Liquidity 20%</p>
            </li>
            <li>
              <span class="five"></span>
              <p>Founder Team 10%</p>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>

</section>

<!-- Mission section start -->
<section id="Mission">
  <div class="container">
    <div class="row text-white align-items-center">
      <div class="col-md-6 col-lg-6">
        <div class="Mission-left">
          <h1>The Mission of <span class="theme-color"> Demo Token</span></h1>
          <p>The mission of Demo token is to create a trustless, decentralized, and immutable global cryptocurrency platform. This platform would be powered by the underlying blockchain technology, allowing for a fully transparent and reliable means of exchange. The goal is to provide users with an efficient, secure, and cost-effective way to transact both domestically and internationally.</p>
        </div>
      </div>
      <div class="col-md-6 col-lg-6">
        <div class="Mission-right">
          <img src="../assets/images/website/mission.png" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>

<!--vision section start -->
<section id="vision">
  <div class="container">
    <div class="row text-white align-items-center">
      <div class="col-md-6 col-lg-6">
        <div class="vision-right">
          <img src="../assets/images/website/vision.png" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6 col-lg-6">
        <div class="vision-left">
          <h1>The vision of <span class="theme-color"> Demo Token</span></h1>
          <p>The vision of this Token is to create a secure, transparent, and decentralized digital currency that can be used for payments and transfers. We aim to make it easy for people around the world to use and accept cryptocurrency as a valid form of payment. Demo Token will be built on top of its blockchain platform, which allows users to easily send, receive, store, and trade their tokens safely and securely.</p>
        </div>
      </div>

    </div>
  </div>
</section>

<!-- Roadmap -->

<section id="road-map">
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <h1>Roadmap</h1>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">
        <img src="../assets/images/website/road-map-img.png" class="img-fluid">
      </div>
    </div>
  </div>

</section>

<!-- faq  -->

<section id="faq">
  <div class="container">
    <div class="row">
      <div class="col text-center">
        <h1 class="text-white">Faqs <span class="theme-color">(Frequently Answered Questions)</span></h1>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-md-5 col-lg-5 mb-5 mb-md-0">
        <div class="faq-image">
          <img src="../assets/images/website/faq.png" class="img-fluid">
        </div>

      </div>
      <div class="col-md-7 col-lg-7">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                What is the Demo Token?
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>The Demo Token is a BEP-20-based token that is designed to create a new way to support and reward the growth of the Demo Network. It has been designed to allow users to become part of a decentralized network and earn rewards for their contributions. </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                How do I acquire Demo Token?
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <p>You can acquire Demo Token by participating in our Delegated Proof-of-Stake (DPoS) consensus mechanism or by exchanging it for other cryptocurrencies on supported exchanges. </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                What are the benefits of holding a Demo Token?
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <h6>Demo Token holders will have access to a range of benefits, including:</h6>
                <p>Access to exclusive rewards, such as discounts, airdrops, and giveaways</p>
                <p>Staking rewards</p>
                <p>Voting rights in the Demo Network</p>
                <p>The ability to participate in Demo Network’s governance decisions</p>
                <p>The ability to participate in the Demo Network’s Decentralized</p>
                <p>Applications (DApps) and services </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<footer>
  <div class="uper-footer">

    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-4 col-lg-6">
          <div class="footer-logo">
            <a href="javascript:void(0)"><span class="ft-logo">LOGO</span></a>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6">
          <ul class="footer-links">
            <li><a href="#about">About</a></li>
            <li><a href="#advantage">Advantages</a></li>
            <li><a href="#tokenomics">Tokenomics</a></li>
            <li><a href="#road-map">Roadmap</a></li>
          </ul>
        </div>
      </div>
    </div>

  </div>

  <div class="lower-footer">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <h6 class="mb-4">© Copyright 2023</h6>
          <ul class="social-link">
            <li class="first-li"><a href="javascript:void(0)" ><img src="../assets/images/website/you-tube.png" class="img-fluid"></a></li>
            <li><a href="javascript:void(0)" ><img src="../assets/images/website/twitter-icon.png" class="img-fluid"></a></li>
            <li><a href="javascript:void(0)" ><img src="../assets/images/website/telegram-icon.png" class="img-fluid"></a></li>
            <li><a href="javascript:void(0)" ><img src="../assets/images/website/facebook-icon.png" class="img-fluid"></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
import {
  Swiper,
  SwiperSlide
} from "swiper/vue";
// import Web3 from "web3";

// Import Swiper styles
import "swiper/css";

// import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import {
  Autoplay
} from "swiper";

export default {
  name: "WebsiteDetail",
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return {
      login:false
    }
  },
  mounted: function () {
    window.$(window).scroll(function () {
      if (window.$(this).scrollTop() > 200) {
        window.$('header').addClass("sticky");
      } else {
        window.$('header').removeClass("sticky");
      }
    });

    window.$('.nav_link_a').click(function () {
      window.$('div#navbarSupportedContent').removeClass('show');
      window.$(".navbar-toggler").addClass("collapsed");

    })
  },
  setup() {
    return {
      modules: [Autoplay],
    };
  },
  beforeMount() {
    let access_token = localStorage.getItem('token');

    if (access_token) {
      this.login = true;
    }else{
      if (this.$route.query.referral) {
          this.$router.push({path: '/signup', query: {referral: this.$route.query.referral}})
        }
    }
  }
}
</script>

<style scoped>


.theme-color {
  color: #FCAB35;
}

.theme-light {
  color: #ffdb51;
}

.navbar-brand img {
  max-width: 200px;
}

body {
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3 {
  font-family: 'Russo One', sans-serif;
}

section {
  padding: 60px 0;
}

header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;

}

header .navbar-nav .nav-item {
  margin-left: 24px;
}

header .navbar-nav .nav-item .nav-link {
  font-weight: 500;
  color: #fff;
  transition: 0.3s ease-in-out;

}

header .navbar-nav .nav-item .nav-link.active {
  color: #FCAB35;
}

header .navbar-nav .nav-item .nav-link:hover {
  color: #FCAB35;
}

header .navbar-nav .login-a {
  border: 1px solid #fff;
  padding: 0 14px;
  transition: 0.3s ease-in-out;
  border-radius: 3px;
}

header .navbar-nav .login-a:hover {
  border: 1px solid #FCAB35;
  color: #FCAB35;
}

header .navbar-nav .register-a {
  border: 1px solid transparent;
  padding: 0 14px;
  background-color: #FCAB35;
  transition: 0.3s ease-in-out;
  border-radius: 3px;

}

header .navbar-nav .register-a:hover {

  background-color: transparent;
  color: #FCAB35;
  border: 1px solid #FCAB35;

}

header .navbar-nav .register-a .nav-link {
  color: #000;
}

#myVideo {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

#home {
  position: relative;
  height: 100vh;
}

#home::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0000008c;
}

#home button {
  background-color: #FCAB35;
  padding: 6px 12px;
  color: #000;
  border: 1px solid transparent;
  position: relative;
  transition: 0.3s ease-in-out;
  border-radius: 3px;
}

#home button:hover {

  background-color: transparent;
  color: #FCAB35;
  border: 1px solid #FCAB35;

}

#home .home-h1 {
  color: #fff;
  font-size: 70px;
  letter-spacing: 2px;
  word-spacing: 2px;
  position: relative;
}

#home .home-h1 span {

  background-image: url(../assets/images/website/text-image.png);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#home p {
  font-size: 24px;
  color: #fff;
  margin: 30px 0;
  position: relative;
}

#home .home-h1::before {
  position: absolute;
  content: '';
  /* top: 10px; */
  bottom: -10px;
  height: 1px;
  width: 400px;
  background-color: #FCAB35;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.home-content {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  top: 20%;
}

.overlay_main {
  position: relative;
}

.overlay_main:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #0000009e;

}

/* about section start */
#about {

  background-image: url(../assets/images/website/About-bg-new.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

#about .about-left h1 {
  color: #fff;
  font-size: 50px;
  letter-spacing: 1px;
  position: relative;
  margin: 20px 15px;
}

#about .about-left h1::before {
  position: absolute;
  content: '';
  top: 0;
  left: -15px;
  background-color: #FCAB35;
  height: 100%;
  width: 6px;
  border-radius: 10px;
}

#about .about-left p {
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 30px;
}

/* advantage section */

#advantage h1 {
  color: #fff;
  font-size: 50px;
  letter-spacing: 1px;
  position: relative;
  margin: 20px 0;
}

#advantage h1::before {
  position: absolute;
  content: '';
  top: 0;
  left: 178px;
  /* left: 91px; */
  background-color: #FCAB35;
  height: 100%;
  width: 6px;
  border-radius: 10px;
}

#advantage .right p {
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 30px;
}

#advantage {
  background: linear-gradient(55deg, rgba(0, 0, 0, 1) 40%, rgba(56, 40, 16, 1) 100%);
}

/* token bep 20 */

#bep-20-token {

  background: rgb(26, 26, 26);
  background: linear-gradient(90deg, rgba(26, 26, 26, 1) 68%, rgba(53, 44, 29, 1) 100%);
}

#bep-20-token h1 {
  color: #fff;
  font-size: 50px;
  letter-spacing: 1px;
  position: relative;
  margin: 20px 15px;
}

#bep-20-token h1::before {
  position: absolute;
  content: '';
  top: 0;
  left: -15px;
  background-color: #FCAB35;
  height: 100%;
  width: 6px;
  border-radius: 10px;

}

#bep-20-token .token-left p {
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 30px;
}

/* ::-webkit-scrollbar {
display: none;
} */

/* main {
top: 0;
left: 0;
width: 100vh; 
height: 100vw;
transform-origin: top left;
transform: rotate(-90deg) translateX(-100vh);
overflow-x: hidden;
overflow-y: scroll;
}

article {
display: flex;
flex-direction: row;
width: fit-content;
height: fit-content;
transform-origin: top left;
transform: rotate(90deg) translateY(-100vh);
}

main article section {
width: 100vw;
height: 100vh;
overflow-y: hidden;
display: flex;
align-items: center;
justify-content: center;

font-size: 3rem;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
} */

/* main article  section:nth-of-type(odd) {
background: #111;
color: #fff;
}

main article  section:nth-of-type(even) {
background: rgb(63, 27, 27);
color: #fff;
} */

/* tokenomics */

#tokenomics .tokenomics-box li span {
  border: 1px solid transparent;
  padding: 10px 50px;
  background: red;
  margin-right: 24px;
}

#tokenomics .tokenomics-box li {
  display: flex;
  margin-bottom: 16px;
}

#tokenomics .tokenomics-box li .one {
  background-color: #c9498b;
}

#tokenomics .tokenomics-box li .two {
  background-color: #0a51bb;
}

#tokenomics .tokenomics-box li .three {
  background-color: #37e42b;
}

#tokenomics .tokenomics-box li .four {

  background-color: #10acd4;

}

#tokenomics .tokenomics-box li .five {

  background-color: #feb912;

}

#tokenomics h1 {
  color: #fff;
  font-size: 50px;
  letter-spacing: 1px;
  position: relative;
  margin: 20px 0;
}

#tokenomics h1::before {
  position: absolute;
  content: '';
  top: 0;
  left: 460px;
  /* left: -15px; */
  background-color: #FCAB35;
  height: 100%;
  width: 6px;
  border-radius: 10px;
}

#tokenomics {
  background-image: url(../assets/images/website/Tokenomics.png);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#tokenomics .tokenomics-box p {
  font-size: 15px;
}

/* mission */

#Mission {
  background: linear-gradient(253deg, rgba(0, 0, 0, 1) 85%, rgba(56, 40, 16, 1) 100%);
}

#Mission .Mission-left h1 {
  color: #fff;
  font-size: 50px;
  letter-spacing: 1px;
  position: relative;
  margin: 20px 15px;
}

#Mission .Mission-left h1::before {
  position: absolute;
  content: '';
  top: 0;
  left: -15px;
  background-color: #FCAB35;
  height: 100%;
  width: 6px;
  border-radius: 10px;
}

#Mission .Mission-left p {
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 30px;
}

/* vision */

#vision {
  background-image: url(../assets/images/website/vision-new-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#vision .vision-left h1 {
  color: #fff;
  font-size: 50px;
  letter-spacing: 1px;
  position: relative;
  margin: 20px 15px;
}

#vision .vision-left h1::before {
  position: absolute;
  content: '';
  top: 0;
  left: -15px;
  background-color: #FCAB35;
  height: 100%;
  width: 6px;
  border-radius: 10px;
}

#vision .vision-left p {
  font-size: 17px;
  letter-spacing: 1px;
  line-height: 30px;
}

/* ROAD-MAP */

#road-map h1 {
  color: #fff;
  font-size: 50px;
  letter-spacing: 1px;
  position: relative;
  margin: 20px 0;
}

#road-map h1::before {
  position: absolute;
  content: '';
  top: 0;
  left: 500px;
  /* left: -15px; */
  background-color: #FCAB35;
  height: 100%;
  width: 6px;
  border-radius: 10px;
}

#road-map {
  background-image: url(../assets/images/website/road-new.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* faq */

#faq {

  background-image: url(../assets/images/website/faq-2.png);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

#faq h1 {
  color: #fff;
  font-size: 50px;
  letter-spacing: 1px;
  position: relative;
  margin: 20px 0;
}

#faq h1::before {
  position: absolute;
  content: '';
  top: 0;
  left: 53px;
  /* left: -15px; */
  background-color: #FCAB35;
  height: 100%;
  width: 6px;
  border-radius: 10px;
}

/* Custom style */
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(.7) !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed) {
  color: #FCAB35;
  background-color: #1d1c1a;

}

.accordion-button:not(.collapsed)::after {
  filter: invert(1);
}

.accordion-button {
  color: #fcab35;
  background-color: #1d1c1a;
}

.accordion-button::after {
  filter: invert(1);
}

.accordion-body {
  background: #1d1c1a;
  color: #fff;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: unset;
  border: 1px solid #ffffff2b;
}

.accordion-button:focus {
  z-index: 3;
  border-color: unset;
  outline: 0;
  box-shadow: unset;
}

.accordion-button:focus-visible {
  outline: none;
}

/* footer */

footer {
  background-image: url(../assets/images/website/Footer.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

footer .uper-footer .container {
  padding: 30px 0;
}

footer .uper-footer .footer-logo img {
  max-width: 200px;
}

footer .footer-links {
  display: flex;
  justify-content: space-between;

}

footer .footer-links li a {
  text-decoration: none !important;
  color: #fff;
  font-weight: 500;
  font-family: 'Russo One', sans-serif;
  letter-spacing: 1px;
  transition: 0.3s ease-in-out;
}

footer .uper-footer .container {
  border-bottom: 1px solid #fcab35;
}

footer .lower-footer .container {
  padding: 30px 0;
}

footer .lower-footer .social-link {
  display: flex;
  justify-content: center;
}

footer .lower-footer h6 {
  color: #FCAB35;
  font-family: 'Russo One', sans-serif;
  letter-spacing: 1px;
}

/* footer .lower-footer .social-link li {
margin-left: 20px;
padding: 10px;
border-radius: 50%;
border: 2px solid #cb8d3d;
border-image: linear-gradient(to left, #cb8d3d, #49371a)!important;
border-image-slice: 1!important;
width: 40px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
} */

footer .lower-footer .social-link li a img {
  max-width: 60%;
}

/* footer .lower-footer .social-link li a{
color: #fff;
transition: 0.3s ease-in-out;
}
footer .lower-footer .social-link .first-li{
margin-left: -30px;
}
footer .lower-footer .social-link li a:hover{
color: #FCAB35;
}

footer  .footer-links li a:hover{
color: #FCAB35;
} */

.sticky {
  top: 0;
  width: 100%;
  background-color: black;
}

/* new bep 20 token css */
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-button {
  width: 1px;
  height: 1px;
}

#page {
  display: block;
  position: relative;
  width: 100vw;
  height: 80vh;
  margin: 0 auto;
}

.grid-horizontal {
  display: block;
  position: absolute;
  top: 100vh;
  width: 100vh;
  height: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  transform: rotate(-90deg);
  transform-origin: top left;
}

.grid-horizontal>div {
  display: block;
  position: relative;
  left: 100vh;
  width: 100vw;
  height: 100vw;
  transform: rotate(90deg);
  transform-origin: top left;
}

/* .grid-horizontal > div:nth-child(even) {
background-color: #cab;
}
.grid-horizontal > div:nth-child(odd) {
background-color: #abc;
} */
.grid-horizontal>div>.grid-inner {
  display: block;
  position: relative;
  width: 100vw;
  height: 80vh;
}

.grid-inner section {
  height: 80vh !important;
  display: flex;
  align-items: center;
}

body {
  background-color: #000;
}

.second-bep-20 {
  flex-direction: row-reverse;
}

.for-mob {
  display: none;
}

#home .home-h1::before {
  width: 500px;
}

h1 {
  text-transform: uppercase;
}

.navbar-toggler {
  background: none !important;
}

.navbar-toggler-icon {
  width: 2em;
  height: 2em;
  filter: invert();
}

.navbar-toggler-icon:focus-visible {
  outline: none;
  border: none;
}

.navbar-toggler:focus-visible {
  outline: none;
  border: none;
}

button:focus:not(:focus-visible) {
  outline: 0 !important;
  border: none !important;
  ;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.second-bep-token {
  background: linear-gradient(90deg, rgba(26, 26, 26, 1) 68%, rgb(36 30 20) 100%) !important;
}

@media screen and (max-width: 1399px) {
  #home .home-h1 {
    font-size: 60px;
  }

  #advantage h1::before {
    left: 87px;
  }

  #tokenomics h1::before {
    left: 368px;
  }

  #road-map h1::before {

    left: 411px;

  }

  #faq h1::before {

    left: 131px;

  }
}

@media screen and (max-width: 1199px) {
  #home .home-h1 {
    font-size: 50px;
  }

  .navbar-brand img {
    max-width: 170px;
  }

  header .navbar-nav .nav-item {
    margin-left: 14px;
  }

  #advantage h1::before {
    left: -2px;
  }

  #about .about-left p {
    font-size: 16px;
    letter-spacing: 0px;
  }

  #bep-20-token .token-left p {
    font-size: 16px;
    letter-spacing: 0px;
  }

  #advantage .right p {
    font-size: 16px;
    letter-spacing: 0px;
  }

  #Mission .Mission-left p {
    font-size: 16px;
    letter-spacing: 0px;
  }

  #vision .vision-left p {
    font-size: 16px;
    letter-spacing: 0px;
  }

  #tokenomics h1::before {
    left: 280px;
  }

  #road-map h1::before {
    left: 322px;

  }

  #faq h1::before {
    left: 44px;
  }
}

@media screen and (max-width: 991px) {
  header .navbar-nav .nav-item {
    margin-left: 14px;
    margin-right: 14px;
  }

  .navbar-toggler {
    padding: 4px 8px;
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: unset;
    background-color: transparent;
    border: transparent;
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
    background: #fff;
  }

  header .navbar-nav .nav-item {
    margin-bottom: 20px;
  }

  .navbar-collapse.collapse.show {
    background: rgb(0, 0, 0);
  }

  #home .home-h1 {
    font-size: 40px;
  }

  #home p {
    font-size: 18px;
  }

  #about .about-left h1 {
    font-size: 40px;
    margin: 10px 0;
  }

  #bep-20-token .token-left h1 {
    font-size: 40px;
    margin: 10px 0;
  }

  #advantage h1 {
    font-size: 40px;
  }

  #advantage h1::before {
    left: 42px;
  }

  #about .about-left p {
    font-size: 15px;
    letter-spacing: 0px;
  }

  #bep-20-token .token-left p {
    font-size: 15px;
    letter-spacing: 0px;
  }

  #advantage .right p {
    font-size: 15px;
    letter-spacing: 0px;
  }

  #Mission .Mission-left p {
    font-size: 15px;
    letter-spacing: 0px;
  }

  #vision .vision-left p {
    font-size: 15px;
    letter-spacing: 0px;
  }

  #tokenomics h1 {
    font-size: 40px;
  }

  #tokenomics h1::before {
    left: 194px;
  }

  #tokenomics .tokenomics-box li span {
    padding: 10px 37px;
    margin-right: 16px;
  }

  #Mission .Mission-left h1 {
    font-size: 40px;
  }

  #vision .vision-left h1 {
    font-size: 40px;
  }

  #road-map h1::before {
    left: 203px;
  }

  #faq h1::before {
    left: 16px;
  }

  footer .uper-footer .footer-logo img {
    max-width: 170px;
  }

  .home-content {
    top: 20%;
  }

  header .navbar-nav .login-a {
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  header .navbar-nav .register-a {
    width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #myVideo {
    height: 75vh;
  }

  #home {
    height: 75vh;
  }

  .row_a {
    flex-direction: column-reverse;
  }

}

@media screen and (max-width: 767px) {
  #about .row {
    flex-direction: column-reverse;
  }

  #bep-20-token .row {
    flex-direction: column-reverse;
  }

  #home .home-h1 {
    font-size: 34px;
  }

  #home p {
    font-size: 15px;
  }

  .home-content {
    top: 20%;
  }

  h1,
  p {
    text-align: center;
  }

  #about .about-left h1::before {
    left: 15px;
  }

  #advantage h1::before {
    left: -7px;
  }

  #tokenomics h1::before {
    left: 118px;
  }

  #vision .row {
    flex-direction: column-reverse;
  }

  #road-map h1::before {
    left: 124px;
  }

  .footer-logo {
    text-align: center;
    margin-bottom: 24px;
  }

  .for-mob {
    display: block;
  }

  #page {
    display: none;
  }

  #about .about-left h1 {
    margin-bottom: 25px;
  }

  #advantage .right {
    margin-top: 25px;
  }

  #bep-20-token .token-left h1 {
    margin-bottom: 25px;
  }

  #about h1::before {
    left: 1px;
  }

  h1::before {
    display: none;
  }

  img {
    max-width: 70% !important;

  }

  .about-right {
    text-align: center;
    margin-bottom: 16px;
  }

  #advantage .left {
    text-align: center;
    margin-bottom: 16px;
  }

  #bep-20-token .token-right {
    text-align: center;
    margin-bottom: 16px;
  }

  #tokenomics .tokinomics-image {
    text-align: center;
    margin-bottom: 16px;
  }

  #Mission .Mission-right {
    text-align: center;
    margin-bottom: 16px;
  }

  #vision .vision-right {
    text-align: center;
    margin-bottom: 16px;
  }

  #road-map .col {
    text-align: center;
    margin-bottom: 16px;
  }

  #road-map .col img {
    max-width: 100% !important;
  }

  #faq .faq-image {
    text-align: center;
    margin-bottom: 16px;
  }

  section {
    padding: 40px 0 !important;
  }

  .accordion-body p {
    text-align: start;
  }
}

@media screen and (max-width: 575px) {
  #faq h1 {
    font-size: 25px;
  }

  #home .home-h1::before {
    height: 1px;
    width: 250px;
  }

  footer .footer-links {
    display: block;
    text-align: center;
  }

  footer .footer-links li {
    margin-bottom: 10px;
  }

  .navbar-brand img {
    max-width: 160px !important;
  }

  header .navbar-nav .nav-item {
    margin-bottom: 15px;
  }

  #home .home-h1 {
    font-size: 28px;
  }

  .home-content {
    top: 10%;
  }

  #about .about-left h1 {
    font-size: 30px;
  }

  #bep-20-token .token-left h1 {
    font-size: 30px;
  }

  #advantage h1::before {
    display: none;
  }

  #advantage h1 {
    font-size: 30px;
  }

  #about .about-left h1::before {
    display: none;
  }

  #bep-20-token .token-left h1::before {
    display: none;
  }

  #tokenomics h1::before {
    display: none;
  }

  #Mission .Mission-left h1::before {
    display: none;
  }

  #vision .vision-left h1::before {
    display: none;
  }

  #road-map h1::before {
    display: none;
  }

  #tokenomics h1 {
    font-size: 30px;
  }

  #Mission .Mission-left h1 {
    font-size: 30px;
  }

  #vision .vision-left h1 {
    font-size: 30px;
  }

  #road-map h1 {
    font-size: 30px;
  }

  #tokenomics .tokenomics-box li span {
    padding: 10px 25px;
  }

  ul.tokenomics-box {
    margin-top: 40px;
    margin-left: 10px;
  }

  .footer-links li {
    margin-right: 30px;
  }

  .lower-footer .social-link .first-li {
    margin-left: -30px;
  }

  .tokenomics-box li p {
    font-size: 15px;
  }

  #faq {
    background-size: cover;
  }
}

html,
body {
  width: 100%;
  overflow-x: hidden;
}

p,
a.nav-link {
  font-family: 'Poppins', sans-serif !important;
}

section.slider_za {
  background: linear-gradient(90deg, rgba(26, 26, 26, 1) 68%, rgba(53, 44, 29, 1) 100%);
}

.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

/* Lines of the Toggler */
.toggler-icon{
  width: 30px;
  height: 3px;
  background-color: #ffa629;
  display: block;
  transition: all 0.2s;
}

/* Adds Space between the lines */
.middle-bar{
  margin: 5px auto;
}

/* State when navbar is opened (START) */

.navbar-toggler[aria-expanded="true"] .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler[aria-expanded="true"] .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler[aria-expanded="true"] .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}
/* State when navbar is collapsed (END) */

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
  background-color: #ffa629;
}
.sticky {
  top: 0;
  width: 100%;
  background-color: black;
}
</style>
