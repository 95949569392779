<template>
  <SideBar :activemode="activeClass"></SideBar>

  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum">
        <div class="container-fluid">
          <div class="row justify-content-end">
            <div class="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/Home"
                      ><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link
                    >
                  </li>
                  <li class="breadcrumb-item text-white">
                    <i class="fa-solid fa-file-invoice-dollar me-1"></i>Booster Income
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section class="table-sec m-lg-3 rounded-2">
        <div class="container-fluid">
          <div class="row justify-content-center bg-light py-2 set_radius">
            <div class="col-lg-6 text-center">
              <HeadingTitle title="Booster Income Detail"></HeadingTitle>
            </div>
          </div>
        </div>
        <div class="container-fluid p-4">
          <div class="row enteries-row align-items-center mb-5">
            <div class="col-xl-2 col-lg-5 col-md-6">
              <div class="entry-box">
                <h5>Show</h5>
                <span>
                  <select class="select" v-model="limit" v-on:change="getMoreResults">
                    <option
                      v-for="(option, index) in options"
                      :key="index"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </span>

                <h6>enteries</h6>
              </div>
              <!--entry-box-->
            </div>
            <!-- <div class="col-xl-2 col-lg-4">
              <div class="input-group my-3">
                <select
                  class="form-select"
                  id="inputGroupSelect01"
                  v-model="pool"
                  v-on:change="getMoreResults"
                >
                  <option
                    v-for="(option, index) in poolOptions"
                    :key="index"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div> -->
            <!--col-xl-6 col-lg-6 col-md-6-->

            <!-- <div class="col-xl-7 col-lg-3 col-md-6">
              <div class="search-box">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1"
                    ><img src="../assets/images/search.svg" alt="" class="img-fluid"
                  /></span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search....."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div> -->
            <!--col-xl-6 col-lg-6 col-md-6-->
          </div>
          <!--row enteries-row-->

          <div class="row table-row">
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="fw-bold">
                        Amount
                        <span
                          ><img
                            src="../assets/images/table_icon_2.png"
                            alt=""
                            class="img-fluid"
                        /></span>
                      </th>
                      <th scope="col" class="fw-bold">
                        Note
                        <span
                          ><img
                            src="../assets/images/table_icon_2.png"
                            alt=""
                            class="img-fluid"
                        /></span>
                      </th>
                      <th scope="col" class="fw-bold">
                        Date
                        <span
                          ><img
                            src="../assets/images/table_icon_2.png"
                            alt=""
                            class="img-fluid"
                        /></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="income_details.length">
                    <tr v-for="(income, index) in income_details" :key="index">
                      <td>{{ income.credit }}</td>
                      <td>{{ income.note }}</td>
                      <td>{{ dateFormate(income.created_at) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4">No Data Available</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--table-responsive-->
            </div>
            <!--col-xl-12 col-lg-12 col-md-12-->
          </div>
          <!--row table-row-->

          <div class="row pagination-row align-items-center" v-if="income_details.length">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pages-entries-text">
                <h5>showing</h5>
                <span
                  >{{ 1 + limit * currentPage - limit }} to
                  {{ limit * currentPage - limit + income_details.length }} of
                  {{ total }}</span
                >
                <h6>entries</h6>
              </div>
              <!--pages-entries-text-->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pagenation-inner">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a
                        :class="currentPage > 1 ? 'page-link' : 'page-link disabled'"
                        href="#"
                        aria-label="Previous"
                        @click="getResults(currentPage - 1)"
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    <li
                      class="page-item"
                      v-for="index in totalPages"
                      :key="index"
                      @click="getResults(index)"
                    >
                      <a
                        :class="index == currentPage ? 'page-link active' : 'page-link'"
                        href="#"
                      >
                        {{ index }}
                      </a>
                    </li>

                    <li class="page-item">
                      <a
                        :class="
                          currentPage < totalPages ? 'page-link' : 'page-link disabled'
                        "
                        href="#"
                        aria-label="Next"
                        @click="getResults(currentPage + 1)"
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!--pagenation-inner-->
            </div>
            <!--col-xl-6 col-lg-6 col-md-6--->
          </div>
          <!--row pagination-row-->

          <!--container-fluid-->
        </div>
      </section>
    </div>
    <FooterDetail></FooterDetail>
  </div>
</template>

<script>
import HeadingTitle from "@/components/HeadingTitle.vue";
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";
export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    HeadingTitle,
    FooterDetail,
  },
  data() {
    return {
      activeClass: "sidebar",
      main_wrapper: "main_wrapper",
      income_details: [],
      totalPages: 0,
      currentPage: 1,
      total: 0,
      limit: 10,
      options: [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ]
    };
  },
  methods: {
    dateFormate(date){
      let cur_date = new Date(date);
      cur_date.setTime(cur_date.getTime() + 19800000)
      cur_date = cur_date.toISOString()
      return cur_date.substr(0, (cur_date.length - 5)).replace('T', ' ');
    },
    changeActiveMode(payload) {
      console.log(payload);
      (this.activeClass = payload.activeClass),
        (this.main_wrapper = payload.mainWrapperClass);
    },

    getMoreResults() {
      let page = 1;
      this.getResults(page, this.limit);
    },

    async getResults(page = 1) {
      this.$axios
        .get("boosterIncome?page=" + page + "&limit=" + this.limit)
        .then((income) => {
          console.log("income", income);
          this.income_details = income.data.booster_income;
          this.totalPages = income.data.totalPages;
          this.total = income.data.count;
          this.currentPage = parseInt(income.data.currentPage);
        });
    },
  },
  beforeMount() {
    this.getResults();
  },
};
</script>

<style scoped>
.income_form {
  background-color: #282828;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: #f8f9fa 0px 0px 2px;
}
select#inputGroupSelect01 {
  background-color: #282828;
  color: white;
  border: 1px solid #e9ecef52;
}
select#inputGroupSelect01 {
  background-image: url("../assets/images/down_arrow.svg") !important;
}
</style>
