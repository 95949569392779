<template>
  <SideBar :activemode="activeClass"></SideBar>
  
  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum">
        <div class="container-fluid">
          <div class="row justify-content-end">
  
            <div class="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/dashboard"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item text-white"> <i class="fa-light fa-users me-1"></i>User List</li>
  
                </ol>
              </nav>
            </div>
          </div>
  
        </div>
      </section>
  
        
        <section class="table-sec m-lg-3 rounded-2">
          <div class="container-fluid">
            <div class="row justify-content-center bg-light py-2 set_radius">
              <div class="col-lg-6 text-center">
                <HeadingTitle title="Admin User List"></HeadingTitle>
              </div>
            </div>
          </div>

        <div class="container-fluid p-4">
          <div class="row enteries-row align-items-center mb-5">
            <div class="col-xl-2 col-lg-5 col-md-6">
              <div class="entry-box">
                <h5>Show</h5>
                <span>
                  <select class="select" v-model="limit" v-on:change="getMoreResults">
                    <option
                      v-for="(option, index) in options"
                      :key="index"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </span>

                <h6>enteries</h6>
              </div>
            </div>
            <div class="col-xl-8 col-lg-3 col-md-6">
              <button @click="getExcel" class="approved">Download Excel</button>
            </div>  
            <div class="col-xl-8 col-lg-3 col-md-6">
              <div class="search-box">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1"><img src="../../assets/images/search.svg" alt="" class="img-fluid"/></span>
                  <input type="text" @input="getResults()" class="form-control" v-model="user" placeholder="Search....." aria-label="Username" aria-describedby="basic-addon1"/>
                </div>
              </div>
            </div>
          </div>
          <!--row enteries-row-->
    
            <div class="row table-row">
              <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered ">
                    <thead>
                      <tr>
    
                        <!-- <th scope="col" class="fw-bold">S.NO <span><img src="../../assets/images/table_icon_1.png" alt="" class="img-fluid"></span></th> -->
                        <th scope="col" class="fw-bold">User Name <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                        <th scope="col" class="fw-bold">Wallet Address <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                        <th scope="col" class="fw-bold">Current Package <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                        <th scope="col" class="fw-bold">Action <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
    
                      </tr>
                    </thead>
                    <tbody v-if="users.length">
                    <tr v-for="(user, index) in users" :key="index">
                      <td>{{ user.username }}</td>
                      <td>{{ user.account_token }}</td>
                      <td>${{ user.current_package }}</td>
                      <td>
                        <div class="dropdown">
                          <button class="btn btn-success dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Action
                          </button>
                          <ul class="dropdown-menu">
                            <li><button class="dropdown-item" type="button" @click="openModal(user.id,user.account_token)" data-bs-toggle="modal" data-bs-target="#exampleModal">
                              Change Wallet Address
                            </button></li>
                            <li><button class="dropdown-item" type="button" @click="openModal(user.id)" data-bs-toggle="modal" data-bs-target="#addOrDeduct">
                             Add Or Deduct Fund
                            </button></li>
                            <li> <button type="button" class="dropdown-item" @click="loginAsUser(user.account_token)" >
                              Login As User
                            </button></li>
                            <li v-if="!user.block"><button class="dropdown-item"  @click="blockUnblockUser(user.id)"> Block </button></li>
                            <li v-else><button class="dropdown-item" v-if="user.block" @click="blockUnblockUser(user.id)"> UnBlock </button></li>
                            <li></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4">No Data Available</td>
                    </tr>
                  </tbody>
                  </table>
                </div>
                <!--table-responsive-->
              </div>
              <!--col-xl-12 col-lg-12 col-md-12-->
            </div>
            <!--row table-row-->
    
            <div class="row pagination-row align-items-center" v-if="users.length">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pages-entries-text">
                <h5>showing</h5>
                <span
                  >{{ 1 + limit * currentPage - limit }} to
                  {{ limit * currentPage - limit + users.length }} of
                  {{ total }}</span
                >
                <h6>entries</h6>
              </div>
              <!--pages-entries-text-->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pagenation-inner">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a
                        :class="currentPage > 1 ? 'page-link' : 'page-link disabled'"
                        href="#"
                        aria-label="Previous"
                        @click="getResults(currentPage - 1)"
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    <li
                      class="page-item"
                      v-for="index in totalPages"
                      :key="index"
                      @click="getResults(index)"
                    >
                      <a
                        :class="index == currentPage ? 'page-link active' : 'page-link'"
                        href="#"
                      >
                        {{ index }}
                      </a>
                    </li>

                    <li class="page-item">
                      <a
                        :class="
                          currentPage < totalPages ? 'page-link' : 'page-link disabled'
                        "
                        href="#"
                        aria-label="Next"
                        @click="getResults(currentPage + 1)"
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!--pagenation-inner-->
            </div>
            <!--col-xl-6 col-lg-6 col-md-6--->
          </div>
            <!--row pagination-row-->
         
          <!--container-fluid-->
  </div>
  
          
        </section>
        <!-- Button trigger modal -->

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Update Wallet Address</h1>
        <button type="button" class="btn-close mt-3 me-2" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <label for="wallet_address" class="col-form-label">Wallet Address:</label>
            <input type="text" class="form-control" id="wallet_address" readonly>
            <input type="hidden" class="form-control" id="user_id" readonly>
          </div>
          <div class="mb-3">
            <label for="message-text" class="col-form-label">New Wallet Address:</label>
            <input type="text" class="form-control" v-model="wallet_address">
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" id="close" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" @click="changeAdress">Update</button>
      </div>
    </div>
  </div>
</div>
<!--      modal for add or deduct funds-->
      <div class="modal fade" id="addOrDeduct" tabindex="-1" aria-labelledby="addOrDeductLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="addOrDeductLabel">Add Or Deduct Fund</h1>
              <button id="closeAddDeduct" type="button" class="btn-close mt-3 me-2" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="mb-3">
                  <label for="wallet_address" class="col-form-label">Fund Type</label>
                  <select class="form-select" v-model="fund_type" aria-label="Default select example">
                    <option value="add">Add</option>
                    <option value="deduct">Deduct</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label class="col-form-label">Amount</label>
                  <input type="number" class="form-control" v-model="fund_amount">
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary" @click="updateFund">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterDetail></FooterDetail>
  </div>
  </template>
  
    
  <script>
  import HeadingTitle from "@/components/HeadingTitle.vue";
  import SideBar from "@/components/SideBar.vue";
  import HeaderDashboard from "@/components/HeaderDashboard.vue";
  import FooterDetail from "@/components/FooterDetail.vue";
  export default {
    name: "HomeDashboard",
    // props: ['main_wrapper'],
    components: {
      SideBar,
      HeaderDashboard,
      HeadingTitle,
      FooterDetail
    },
    data() {
      return {
        activeClass: 'sidebar',
        main_wrapper: 'main_wrapper',
        users: [],
        totalPages: 0,
        currentPage: 1,
        total: 0,
        limit: 25,
        wallet_address:'',
        user_id:'',
        user:'',
        options: [
          { label: "25", value: 25 },
          { label: "50", value: 50 },
          { label: "100", value: 100 },
        ],
        fund_amount:0,
        fund_type:'add'
        }
    },
    methods: {
      changeActiveMode(payload) {
        this.activeClass = payload.activeClass
          this.main_wrapper = payload.mainWrapperClass
      },
      async changeAdress(){
        let result =  await this.$axios.post('changeWalletAddress',{
            wallet_address:this.wallet_address,
            user_id:this.user_id
          })
        if(result.data[0]){
          this.$swal({icon:'success',text:"Wallet Address Updated successfully."});
          document.getElementById('close').click()
          this.getResults();
          this.wallet_address = '';
          this.user_id = '';
        }else{
          this.$swal({icon:'error',text:result.data[1]});
          this.wallet_address = '';
          this.user_id = '';
        }
      },
    openModal(user_id,address = null){
        if (address){
          document.getElementById('wallet_address').value = address
        }
        this.fund_amount = 0;
        this.user_id = user_id;
    },
    getMoreResults() {
      let page = 1;
      this.getResults(page, this.limit);
    },

    async getResults(page = 1) {
      let url;
      if(this.user !="" ){
        url="userSrch?user="+this.user;
      }else{
        url="usersList?page=" + page + "&limit=" + this.limit ;
      }
      this.$axios.get(url).then((result) => {
          this.users = result.data.users;
          this.totalPages = result.data.totalPages;
          this.total = result.data.count;
          this.currentPage = parseInt(result.data.currentPage);
        });
    },

    async loginAsUser(user_token) {
        this.$axios.post('loginAsUser',{account_token:user_token}).then(response => {
            if (response.data.islogged){
              localStorage.removeItem('token');
              this.$store.commit('updateUserDetails');
              localStorage.setItem('token',response.data.accessToken)
              this.$axios.defaults.headers.common['Authorization'] = response.data.accessToken;
              this.$store.commit('setUserDetails', response.data.user)
              this.$router.push({name:'Dashboard'})
            }else {
              this.$swal({icon:'error',text:response.data.message})
            }
          }).catch(() => {
            this.$swal({icon:'error',text:'something went wrong..please try again'})
          })
    },
    
    async blockUnblockUser(user_id) {
      let result =  await this.$axios.get('blockUnblockUser?id='+user_id);
      if(result.data[0]){
        this.$swal({icon:'success',text:"User status changed successfully."});
      }else{
        this.$swal({icon:'error',text:result.data[1]});
      }
      this.getResults();
    },
    async getExcel() {
      this.$axios.get("downloadExcel").then((result) => {
          if(result.data.status == "success"){
            this.$swal({icon:'success',text:result.data.message});
            window.open(`${this.$axios.defaults.baseURL}/users.xlsx`, '_blank');
          }else{
            this.$swal({icon:'error',text:result.data.message});
          }
      });
    },
    async updateFund(){
      this.$axios.post('updateUserFund',{user_id:this.user_id,fund_amount:this.fund_amount,fund_type:this.fund_type}).then(status => {
        document.getElementById('closeAddDeduct').click();
        if (status.data[0]){
          this.$swal({icon:'success',text:status.data[1]});
        }else {
          this.$swal({icon:'error',text:status.data[1]});
        }
      }).catch(error => {
        this.$swal({icon:error,text:error.message})
      })
      }
  },
  beforeMount() {
    this.getResults();
  },
};
  </script>
  
  <style scoped>
  .income_form {
    background-color: #282828;
    padding: 20px 30px;
    border-radius: 5px;
    box-shadow: #f8f9fa 0px 0px 2px;
  }
  select#inputGroupSelect01 {
      background-color: #282828;
      color: white;
      border: 1px solid #e9ecef52;
      background-image: url('../../assets/images/down_arrow.svg') !important;
  }

  button.change_wallet
  {
      background-color: #ffab46;
      outline: none;
      border: navajowhite;
      padding: 10px 20px;
      color: white;
      border-radius: 7px;
  } 

  button.block_user
  {
      background-color: #ed2713eb;
      outline: none;
      border: navajowhite;
      padding: 10px 20px;
      color: white;
      border-radius: 7px;
  }

   button.unBlock_user
  {
      background-color: #f17f3ceb;
      outline: none;
      border: navajowhite;
      padding: 10px 20px;
      color: white;
      border-radius: 7px;
  }
  .approved {
    outline: none;
    background-color: #28a745;
    color: white;
    padding: 6px 15px;
    border-radius: 5px;
}
  </style>
  