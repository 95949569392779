<template>
  <LoderWait :activemode="loadClass" :load="load"></LoderWait>
  <SideBar :activemode="activeClass"></SideBar>

  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum ">
        <div class="container-fluid">
          <div class="row justify-content-end">

            <div class="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/dashboard"><i  class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                  </li>
                  <!-- <li class="breadcrumb-item " >Library</li> -->

                </ol>
              </nav>
            </div>
          </div>

        </div>
      </section>
      <section class="mb-5">
        <div class="row">
          <div class="col-md-12">
            <div class="container-fluid">
              <div class="card  banner px-4">
                <div class="card-body">
                  <div class="row align-items-center">
                 
                    <div class="col-xl-9 col-lg-9 ps-lg-0">
                      <div class="row align-itemcenter">
                        <div class="col-xl-12 ">
                          <div class="text-start text-white mt-xl-4">
                          <h2>Welcome User </h2>
                          <p class="mb-lg-0 ">The goal is to provide users with an efficient, secure, and cost-effective way to transact both domestically and internationally.
                          </p>
                       <marquee>
                          <ul class="d-flex set_add"  v-if="notifications.length">
                              <li class="me-2 ms-5" v-for="(notification, index) in notifications" :key="index">
                                  <span>{{ notification.body }}</span>
                              </li>
                          </ul>
                       </marquee>
                   
                          </div>
                        </div>
                        <!--                      <div class="col-xl-5 col-lg-6 text-lg-center mt-xl-4">-->
                        <!--                        <h5 class="font-weight-semibold mb-1 text-white">This Month Your Income</h5>-->
                        <!--                        <h2 class="mb-3 number-font text-white">$10M</h2>-->
                        <!--                        <div class="btn-list mb-xl-0">-->

                        <!--                          <router-link to="/Income" class="go_income_page"> Check Details </router-link>-->
                        <!--                        </div>-->
                        <!--                      </div>-->
                      </div>
                    </div>
                       <div class="col-xl-3 col-lg-3 text-center mb-3 mb-lg-0"><img src="../assets/images/profit.png"
                                                                                 alt="img" class="w-95 zoom_in"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="home_page_data mb-5">
        <div class="container-fluid">
          <div class="row">

            <div class="col-lg-6 col-md-6 my-3">
              <div class="box rounded-1 bg_new_1 h-100">
                <div class="row align-items-center">
                  <div class="col-lg-8 p-0">
                    <h6 class="text-white">Wallet Address</h6>
                    <h4 class="text-white fs-6 set_word_break">123456789987654321</h4>
                  </div>
                  <div class="col-lg-4 text-center"><img src="../assets/images/wallet.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

          <div  class="col-lg-6 col-md-6 my-3">
            <div  class="box rounded-1 bg_new_2 h-100" >
              <div  class="row align-items-center">
                <div  class="col-12 p-0">
                  <h6  class="text-white">Referral Link</h6>
                  <div class="d-flex set_input flex-wrap">
                    <input type="text" name="" value="123456789987654321" class="form-control" id="myInput" disabled="">
                    <button  @click="copyToClipboard"  id="success-alert" class="bg-c-yellow mt-2 copy-button">Copy</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_a">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">User Name</h6>
                    <h4 class="text-white">Demo</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/first.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_c">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Rank</h6>
                    <h4 class="text-white">{{ dashboardDetails.rank_name ? dashboardDetails.rank_name : 'Not Achieved' }}</h4>
                  </div>
                  <div d class="col-5"><img src="../assets/images/pool.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_d">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Direct Bonus</h6>
                    <h4 class="text-white">${{ dashboardDetails.direct_bonus ? dashboardDetails.direct_bonus : 0 }}</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/direct_bonus.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_e">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Level Bonus</h6>
                    <h4 class="text-white">${{ dashboardDetails.level_bonus ? dashboardDetails.level_bonus : 0 }}</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/graph.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_f">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Leaderships Bonus</h6>
                    <h4 class="text-white">${{
                        dashboardDetails.leadership_bonus ? dashboardDetails.leadership_bonus : 0
                      }}</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/gift.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

          <div class="col-lg-3 col-md-6 my-3">
            <div class="box rounded-1 bg_f">
              <div class="row align-items-center">
                <div class="col-7 p-0">
                  <h6 class="text-white">Income Capping</h6>
                  <h4 class="text-white">${{ dashboardDetails.income_capping ? dashboardDetails.income_capping : 0 }}</h4>
                </div>
                    <div class="col-5"><img src="../assets/images/capping.png" alt="" class="img-fluid"></div>
                
              </div>
            </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_g">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Direct Sponsor </h6>
                    <h4 class="text-white"> {{ this.user.sponsor_name }} </h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/trophy.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_h">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Total Team</h6>
                    <h4 class="text-white">{{ dashboardDetails.total_team ? dashboardDetails.total_team : 0 }}</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/total_team.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_i">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Direct Team</h6>
                    <h4 class="text-white">{{ dashboardDetails.direct_team ? dashboardDetails.direct_team : 0 }}</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/d_team.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_a">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Current Package</h6>
                    <h4 class="text-white">${{ this.$store.state.user.current_package }}</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/package.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_c">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Booster Income</h6>
                    <h4 class="text-white">${{
                        dashboardDetails.booster_bonus ? dashboardDetails.booster_bonus : 0
                      }}</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/booster.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_f">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Total Income</h6>
                    <h4 class="text-white">${{ dashboardDetails.total_income ? dashboardDetails.total_income : 0 }}</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/arrow_up.png" alt="" class="img-fluid"></div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_a">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Pool Income</h6>
                    <h4 class="text-white">${{
                        dashboardDetails.pool_income ? dashboardDetails.pool_income : 0
                      }}</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/moneyhand.png" alt="" class="img-fluid"></div>
                <img :src="imageUrl" width="auto" height="auto">

                </div>
              </div>
            </div>


            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_i">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Total Rebirth</h6>
                    <h4 class="text-white">demo</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/bb.png" alt="" class="img-fluid"></div>
                <img :src="imageUrl" width="auto" height="auto">

                </div>
              </div>
            </div>


            <div class="col-lg-3 col-md-6 my-3">
              <div class="box rounded-1 bg_e">
                <div class="row align-items-center">
                  <div class="col-7 p-0">
                    <h6 class="text-white">Total Booster Boards</h6>
                    <h4 class="text-white">demo</h4>
                  </div>
                  <div class="col-5"><img src="../assets/images/totalrebirth.png" alt="" class="img-fluid"></div>
                <img :src="imageUrl" width="auto" height="auto">

                </div>
              </div>
            </div>


            

          </div>

          <!-- <div class="row align-items-center justify-content-center">
            <div class="col-lg-3 col-md-6 my-3" v-for="(data_a, index) in data_a" :key="index">
              <div class="box rounded-1" :style="{'background-image': 'url(' + require('@/assets/images/'+data_a.bg+'.png') + ')'}">
                <div class="row align-items-center">
                  <div class="col-7 p-0">

                    <h6 class="text-white">{{ data_a.heading }}</h6>
                    <h4 class="text-white ">{{ data_a.info}}</h4>
                  </div>
                  <div class="col-5">

                    <img :src="require(`@/assets/images/${data_a.path1}`)" alt="" class="img-fluid ">
                  </div>
                </div>

              </div>

            </div>

          </div> -->

        </div>
        </div>

      </section>
      <div v-if="this.notifications.length">
        <button type="button" class="btn btn-primary" id="announcement" style="display:none;" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Launch demo modal
        </button>

        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5 text-center mx-auto" id="exampleModalLabel">Announcement</h1>
                <button type="button" class="btn-close mt-3 me-3" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <img :src="image" class="img-fluid">
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <FooterDetail></FooterDetail>
  </div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import LoderWait from "@/components/LoderWait.vue";
import FooterDetail from "@/components/FooterDetail.vue";
import dashboardData from "@/assets/js/homeDashboardIcons";
import Web3 from "web3";
import ClipboardJS from 'clipboard';

export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    FooterDetail,
    LoderWait

  },
  data() {
    return {
      activeClass: 'sidebar',
      loadClass: '',
      load: false,
      main_wrapper: 'main_wrapper',
      data_a: dashboardData,
      user: this.$store.getters.getUser,
      referral_link: '',
      active_date: '',
      dashboardDetails: '',
      notifications:[],
      image:""
    }
  },
  watch: {
    '$store.state.user': function () {
      this.user = this.$store.getters.getUser
    }
  },
  methods: {
  changeActiveMode(payload) {
    this.activeClass = payload.activeClass
    this.main_wrapper = payload.mainWrapperClass
    this.loadClass = payload.loaderclass

  },
  copyToClipboard() {
    const clipboard = new ClipboardJS(".copy-button", {
      text: () => this.referralLink
    });
    
    clipboard.on("success", () => {
      console.log("Text copied to clipboard");
      clipboard.destroy();
    });
    
    clipboard.on("error", () => {
      console.error("Failed to copy text to clipboard");
      clipboard.destroy();
    });
  },
  async getResults() {
    this.$axios.get("getDashboardDetails").then((details) => {
        this.dashboardDetails = details.data;
        if(this.dashboardDetails.income_capping < 50){
            this.$swal({icon:'warning',text: `Your income capping i0 less than $50.Please Upgrade Your package to recieve more income.` })
        }
        if(this.notifications[this.notifications.length-1])
        {
          this.image = 'https://node.thesunlight.io/'+this.notifications[this.notifications.length-1].image;
          document.getElementById("announcement").click();
        }
      });
  },
  async getNotificationDetails() {
    this.$axios.get("getactivenotifications").then((result) => {
      this.notifications = result.data.notifications;
    });
  }
  },
  computed: {
    referralLink() {
      return this.user ? window.location.origin + '?referral=' + this.user.username : ''
    },
  },
  beforeMount() {
    if (!this.$store.state.is_login) {
      let access_token = localStorage.getItem('token');
      if (access_token) {
        this.$axios.get('getUser', {
          headers: {'Authorization': access_token}
        }).then(response => {
          // console.log(response);
          if (response.data[0]) {
            if (response.data[1].type != "viewer") {
              if (window.ethereum) {
                let web = new Web3(window.ethereum);
                web.eth.requestAccounts().then(accounts => {
                  if (accounts[0].toUpperCase() === response.data[1].account_token.toUpperCase()) {
                    this.$axios.defaults.headers.common['Authorization'] = access_token;
                    this.$store.commit('setUserDetails', response.data[1])
                    this.$store.commit('updateLoginStatus', true)
                    this.getResults();
                    this.getNotificationDetails();
                    this.load = false;
                  } else {
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                  }
                }).catch(() => {
                  localStorage.removeItem('token')
                  this.$router.push('/login')
                })
              } else {
                localStorage.removeItem('token')
                this.$router.push('/login')
              }
            } 
          } else {
            localStorage.removeItem('token')
            this.$router.push('/login')
          }
        }).catch(() => {
          localStorage.removeItem('token')
          this.$router.push('/login')
        })
      } else {
        if (this.$route.query.referral) {
          this.$router.push({path: '/signup', query: {referral: this.$route.query.referral}})
        } else {
          this.$router.push('/')
        }
      }

    } else {
      this.getResults();
      this.load = false;
    }
  }
}
</script>

<style scoped>
.box {
  background-size: 100% 100%;
  padding: 20px 25px;
  transition: .5s ease-in-out;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner {
  background-image: linear-gradient(to right, rgba(82, 92, 229, 0.7) 0%, rgba(82, 92, 229, 0.6) 100%), url(../assets/images/bg.png);
  background-size: cover;
  position: relative;

  border: 0px !important;
}

.banner .w-95 {

    max-width: 70%;
}

.go_income_page {
  background-color: #fcab35;
  color: white;
  padding: 10px 15px;
  font-weight: 500;
  letter-spacing: .6px;
  border-radius: 5px;
}

.bg_a {
  background-image: url(../assets/images/skyblue.png);
}

.bg_c {
  background-image: url(../assets/images/green.png);
}

.bg_d {
  background-image: url(../assets/images/lb.png);
}

.bg_e {
  background-image: url(../assets/images/red.png);
}

.bg_f {
  background-image: url(../assets/images/up.png);
}

.bg_g {
  background-image: url(../assets/images/purple.png);
}

.bg_h {
  background-image: url(../assets/images/yellow.png);
}

.bg_i {
  background-image: url(../assets/images/orange.png);
}

.bg_new_1 {
  background-image: url(../assets/images/box_a.png);
}

.bg_new_2 {
  background-image: url(../assets/images/box_b.png);
}

.box h6 {
  font-size: 23px;
}

.box h4 {
  font-size: 20px;
}

input#myInput {
  background-color: transparent !important;
}

button#success-alert {
  border: none;
  color: white;
  padding: 6px 20px;
  min-width: 100px;
  background-color: gray;
}

button#success-alert:active {
  font-size: 0;
}

button#success-alert:active::after {
  content: "Copied";
  color: #fff;
  font-size: 14px;
}
.set_add {
    font-size: 18px;
    margin-top:10px;
}
</style>
