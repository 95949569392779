<template>
  <SideBar :activemode="activeClass"></SideBar>

  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum">
        <div class="container-fluid">
          <div class="row justify-content-end">
            <div class="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/Home"
                      ><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link
                    >
                  </li>
                  <li class="breadcrumb-item text-white">
                    <i class="fa-sharp fa-regular fa-clipboard-list-check me-1"></i>Admin Ticket
           
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
     

      <section class="table-sec m-lg-3 rounded-2">
  <div class="container-fluid">
    <div class="row justify-content-center bg-light py-2 set_radius">
      <div class="col-lg-6 text-center">
        <HeadingTitle title="Client Ticket"></HeadingTitle>
      </div>
    </div>
    <div class="row enteries-row align-items-center mb-5">
      <div class="col-xl-6 col-lg-6 col-md-6">
        <div class="entry-box">
          <h5>Show</h5>
          <span><select class="select">
              <option value="0">10</option>
              <option value="0">20</option>
              <option value="0">30</option>
              <option value="0">40</option>
              <option value="0">50</option>
            </select></span>

          <h6>enteries</h6>
        </div>
        <!--entry-box-->
      </div>
      <!--col-xl-6 col-lg-6 col-md-6-->

      <div class="col-xl-6 col-lg-6 col-md-6">
        <div class="search-box">
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1"><img src="../assets/images/search.svg" alt="" class="img-fluid"></span>
            <input type="text" class="form-control" placeholder="Search....." aria-label="Username" aria-describedby="basic-addon1">
          </div>
        </div>
        <!--search-box-->
      </div>
      <!--col-xl-6 col-lg-6 col-md-6-->
    </div>
    <!--row enteries-row-->

    <div class="row table-row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="table-responsive">
          <table class="table table-bordered ">
            <thead>
              <tr>
                <th scope="col" class="fw-bold">S.NO <span><img src="../assets/images/table_icon_1.png" alt="" class="img-fluid"></span></th>
                    <th scope="col" class="fw-bold">User Name <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                    <th scope="col" class="fw-bold">Wallet Address <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                    <th scope="col" class="fw-bold">Image <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                    <th scope="col" class="fw-bold">Action <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>
                  <img src="@/assets/images/adhar1.png" class="img-fluid set_adhar_1">


                </td>

                <td><button type="button" class="button_a" data-bs-toggle="modal" data-bs-target="#exampleModal">
Open Ticket
</button></td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>
                  <img src="@/assets/images/adhar1.png" class="img-fluid set_adhar_1">


                </td>
                <td><button type="button" class="button_a" data-bs-toggle="modal" data-bs-target="#exampleModal">
Open Ticket
</button></td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry the Bird</td>
                <td>@twitter</td>
                <td>
                  <img src="@/assets/images/adhar1.png" class="img-fluid set_adhar_1">


                </td>
                <td><button type="button" class="button_a" data-bs-toggle="modal" data-bs-target="#exampleModal">
Open Ticket
</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--table-responsive-->
      </div>
      <!--col-xl-12 col-lg-12 col-md-12-->
    </div>
    <!--row table-row-->

    <div class="row pagination-row align-items-center">
      <div class="col-xl-6 col-lg-6 col-md-6">
        <div class="pages-entries-text">
          <h5>showing</h5>
          <span>1 to 10 of 57</span>
          <h6>entries</h6>
        </div>
        <!--pages-entries-text-->
      </div>
      <!--col-xl-6 col-lg-6 col-md-6-->

      <div class="col-xl-6 col-lg-6 col-md-6">
        <div class="pagenation-inner">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <!--pagenation-inner-->
      </div>
      <!--col-xl-6 col-lg-6 col-md-6--->
    </div>
    <!--row pagination-row-->
  </div>
  <!--container-fluid-->
</section>
    </div>
    <FooterDetail></FooterDetail>
  </div>












<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" class="btn-close mt-3 me-2" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form >
  <div class="mb-3">
    <div class="d-flex align-items-center border border-warning-subtle p-2">
  <div >
    <p class="mb-0"><strong>Subject:- </strong></p>
  </div>
  <div class="ms-2">
    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
  </div>
 </div>
   
  </div>
  <div class="mb-3">
    <div class="d-flex align-items-center border border-warning-subtle p-2">
  <div>
    <p class="mb-0"><strong>Description:- </strong></p>
  </div>
  <div class="ms-2">
    <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
  </div>
 </div>
  </div>
  <div class="mb-3">
    <div><p class="mb-0"><strong>Image :- </strong></p></div>
    <figure>
      <img src="@/assets/images/adhar1.png" class="img-fluid set_adhar_2">
    </figure>
  
  </div>
 <div class="mb-3">
  <select class="form-select w-75" id="inputGroupSelect01">
    <option selected>Open</option>
    <option value="1">Close</option>

  
  </select>
 </div>
  
</form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
        <button type="button" class="btn btn-success">Submit</button>
      </div>
    </div>
  </div>
</div>























</template>

<script>
import HeadingTitle from "@/components/HeadingTitle.vue";
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";
export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    HeadingTitle,
    FooterDetail,
  },
  data() {
    return {
      activeClass: "sidebar",
      main_wrapper: "main_wrapper",
  
    };
  }


};
</script>

<style scoped>
.income_form {
  background-color: #282828;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: #f8f9fa 0px 0px 2px;
}
select#inputGroupSelect01 {
  background-color: #282828;
  color: white;
  border: 1px solid #e9ecef52;
}
select#inputGroupSelect01 {
  background-image: url("../assets/images/down_arrow.svg") !important;
}

.button_a {
  background-color: #FCAB35;
    color: white;
    font-size: 20px;
    padding: 5px 22px;
    border-radius: 5px;
    border: 2px solid transparent;
}
.button_a:hover {
    border: 2px solid #FCAB35;
    background-color: transparent;
    transition: .5s ease-in-out;
}
.input-group .form-control {

    min-height: 38px !important;
}
.set_adhar_1 {
  height: 100px;
    width: 100px;
   
}
.set_adhar_2 {
    width: 400px;
    height: auto;
}
</style>
