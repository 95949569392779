<template>
<SideBar :activemode="activeClass"></SideBar>
<div :class="main_wrapper">
  <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
  <div class="main_wrapper_b">
   <section class="bread_cum">
      <div class="container-fluid">
        <div class="row justify-content-end">

          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/Home"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                </li>
                <li class="breadcrumb-item text-white"> <i class="fa-thin fa-users me-1"></i> Tree</li>

              </ol>
            </nav>
          </div>
        </div>

      </div>
    </section>
    <section class="mt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">

            <div class="generation_tree">
              <h2 class="header-title"><i class="fa-solid fa-screen-users"></i> Tree</h2>
              <ul>
                <li class="main-user">
                  <p>
                    <i class="fa-solid fa-user"></i>
                    <span> {{this.root}} </span>
                  </p>
                  <div class="generation_tree_child_outer">
                    <div class="generation_tree_child">
                      <ul>
                        <li v-for="(child, index) in childs" :key="index">
                          <p class="tooltip_custom">
                            <a href="javascript:void(0)" @click="getResults(child.id , child.username)"><i class="fa-solid fa-user"></i></a>
                            <span> {{ child.username }} </span>
                            <span class="tooltiptext">
                            <p class="mb-1">Name:- Username</p>

                            </span>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <FooterDetail></FooterDetail>
</div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";
export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    FooterDetail
  },
  data() {
    return {
      activeClass: 'sidebar',
      main_wrapper: 'main_wrapper',
      childs: [],
      root:this.$store.state.user.username
    }
  },
  methods: {
    changeActiveMode(payload) {
      console.log(payload);
      this.activeClass = payload.activeClass,
        this.main_wrapper = payload.mainWrapperClass
    },
    async getResults(child = this.$store.state.user.id , child_username = this.$store.state.user.username) {
      this.root = child_username;
      this.$axios.get("userTree?child="+child).then((childs) => {
        this.childs = childs.data;
      });
    },
  },
  beforeMount(){
    this.getResults();
  },
}
</script>

<style scoped>
.generation_tree {
  background: #242424;
  border: 1px solid #6d637e;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 70px;
}



.generation_tree ul {
  padding: 0;
  margin: 0;
}

.generation_tree>ul>li {
  width: 100%;
}

.generation_tree ul li {
  list-style: none;
}

.generation_tree>ul>li p {
 
  margin-inline: auto;
  text-align: center;
}

.generation_tree ul li ul {
  display: flex;
}

.generation_tree ul li ul li {
  width: 150px;
}

.generation_tree ul li p span {
  display: block;
}

.generation_tree_child {
  width: fit-content;
  margin-inline: auto;
}

.generation_tree_child_outer {
  overflow: auto;
  min-height: 185px;
}

.generation_tree_child ul li:before {
  position: absolute;
  content: '';
  border-top: 1px solid #fff;
  width: 100%;
  height: 1px;
  top: 0;
}

.generation_tree_child ul li {
  position: relative;
  padding-top: 20px;
}

.generation_tree_child ul li:first-child::before {
  height: 20px;
  background: transparent;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-top-left-radius: 5px;
  width: 50%;
  right: 0;
}

.generation_tree_child ul li:last-child::before {
  height: 20px;
  background: transparent;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top-right-radius: 5px;
  width: 50%;
  left: 0;
}

.generation_tree_child ul li:after {
  position: absolute;
  content: '';
  background: #fff;
  width: 1px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.generation_tree_child ul li:last-child::after {
  content: unset;
}

.generation_tree_child ul li:first-child::after {
  content: unset;
}

li.main-user>p:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 1px;
  height: 20px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

li.main-user>p {
  padding-bottom: 20px;
  margin-bottom: 0;
  position: relative;
}

.generation_tree ul li p i {
  width: 50px;
  height: 50px;
  background: #dca43f;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  margin-bottom: 7px;
}

li.main-user>p span {
  margin-bottom: 7px;
}

li.main-user p span {
  color: #fff;
}

.generation_tree_child ul li p {
  margin-bottom: 0;
}



.generation_tree_child ul li:only-child::before {
  border-top-right-radius: unset;
  border-top: unset;
  border-top-left-radius: unset;
  border-left: unset;
}



.generation_tree h2 {
  background-color: white;
  padding: 15px 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 700;
  font-style: italic;
  margin-top: 0;
  text-align: center;
  margin-bottom: 20px;
  font-size: 25px;
}

.generation_tree h2 i {
  margin-right: 6px;
  font-size: 20px;
}
.tooltip_custom {
  position: relative;
}

.tooltip_custom .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 60%;
  left: 50%;
  margin-left: -60px;
}

.tooltip_custom .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip_custom:hover .tooltiptext {
  visibility: visible;
}
</style>
