<template>
<aside :class="activemode">

  <div class="logo_b">
    <!-- <img src="../assets/images/only_logo.png" alt="" class="img-fluid only_logo "> -->
    <span class="only_logo">LOGO</span>
    <!-- <img src="../assets/images/logo_text.png" alt="" class="img-fluid logo_text "> -->
    <span class="logo_text">LOGO</span>
  </div>
  <div class="menubar">
    <ul class="p-0">
      <li>
        <router-link to="/dashboard" title="Dashboard">
          <span class="icon"><i class="fa-thin fa-grid"></i></span>
          <span class="text_info">Dashboard</span>
        </router-link>
      </li>

      <li>
        <router-link to="/generation" title="Tree">
          <span class="icon"><i class="fa-thin fa-users"></i></span>
          <span class="text_info">Tree</span>

        </router-link>
      </li>
      
      <li>
        <router-link to="/poolTree" title="Tree">
          <span class="icon"><i class="fa-light fa-circle-user"></i></span>
          <span class="text_info">Autopool Tree</span>

        </router-link>
      </li>
      <li>
        <router-link to="/boosterTree" title="Tree">
          <span class="icon"><i class="fa-sharp fa-regular fa-user-plus"></i></span>
          <span class="text_info">Booster Tree</span>

        </router-link>
      </li>
     <li>
        <a class="" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" title="Income">
          <span class="icon"><i class="fa-light fa-badge-dollar"></i></span>
          <span class="text_info dropdown-toggle">Income </span>
        </a>
      </li> 

    

<div class="collapse" id="collapseExample" >
  <div class="set_dropdown_a">
    <li>
      <router-link to="/direct" title="Level Income">
          <span class="icon"><i class="fa-solid fa-dollar-sign"></i></span>
          <span class="text_info">Direct Income</span>
      </router-link>

      <router-link to="/Level" title="Level Income">
        <span class="icon"><i class="fa-sharp fa-regular fa-comments-dollar"></i></span>
        <span class="text_info">Level Income</span>
      </router-link>

      <router-link to="/PoolIncome" title="Pool Income">
        <span class="icon"><i class="fa-solid fa-circle-dollar-to-slot"></i></span>
        <span class="text_info">Pool Income</span>
      </router-link>

      <router-link to="/BoosterIncome" title="Booster Income">
        <span class="icon"><i class="fa-solid fa-file-invoice-dollar"></i></span>
        <span class="text_info">Booster Income</span>
      </router-link>

      <router-link to="/LeadershipIncome" title="Booster Income">
        <span class="icon"><i class="fa-regular fa-money-check-dollar"></i></span>
        <span class="text_info">Leadership Income</span>
      </router-link>

      <!-- <router-link to="/Income" title="Income">
          <span class="icon"><i class="fa-light fa-sack-dollar"></i></span>
          <span class="text_info">All Incomes</span>
      </router-link> -->
          </li>
  </div>
</div>

      <!-- <li v-if="this.$store.state.user.id !=1 "> -->
      <li>
        <router-link to="/Upgrade" title="Package Upgrade">
          <span class="icon"><i class="fa-light fa-hand-holding-dollar"></i></span>
          <span class="text_info">Package Upgrade</span>

        </router-link>
      </li>
      <!-- <li  v-if="this.$store.state.user.id !=1"> -->
      <li>
        <router-link to="/Wallet" title="Wallet">
          <span class="icon"><i class="fa-regular fa-wallet"></i></span>
          <span class="text_info">Wallet</span>

        </router-link>
      </li>
     <li>
       <router-link to="/Reward" title="Rewards">
         <span class="icon"><i class="fa-regular fa-trophy"></i></span>
         <span class="text_info">Rewards</span>
       </router-link>
     </li>
      <!-- <li v-if="this.$store.state.user.id == 1"> -->
      <li>
       <router-link to="/Addnotification" title="Notifications">
         <span class="icon"><i class="fa-regular fa-bell "></i></span>
         <span class="text_info">Notifications</span>
<!--        </button>-->
       </router-link>
      </li>
<!--      <li>-->
<!--        <router-link to="/Dummy" title="Dummy Pages">-->
<!--          <span class="icon"><i class="fa-light fa-credit-card"></i></span>-->
<!--          <span class="text_info">DummyTabel</span>-->

<!--        </router-link>-->
<!--      </li>-->
      <!-- admin pages start -->
<!--      <li>-->
<!--        <router-link to="/Admindashboard" title="Admin Dashboard">-->
<!--          <span class="icon"><i class="fa-thin fa-grid "></i></span>-->
<!--          <span class="text_info"> Admin Dashboard</span>-->

<!--        </router-link>-->
<!--      </li>-->
<!--      <li>-->
<!--        <router-link to="/AdminTree" title=" Admin Tree">-->
<!--          <span class="icon"><i class="fa-solid fa-users "></i></span>-->
<!--          <span class="text_info"> Admin Tree</span>-->

<!--        </router-link>-->
<!--      </li>-->
<!--      <li>-->
<!--        <router-link to="/AdminDirectTeam" title="Admin Direct Team">-->
<!--          <span class="icon"><i class="fa-regular fa-user-plus"></i></span>-->
<!--          <span class="text_info"> Admin Direct Team</span>-->

<!--        </router-link>-->
<!--      </li>-->
     <!-- <li v-if="this.$store.state.user.id == 1"> -->
     <li>
       <router-link to="/Adminuserlist" title="Admin User List">
         <span class="icon"><i class="fa-light fa-users"></i></span>
         <span class="text_info"> Admin User List</span>

       </router-link>
     </li>
<!--      <li>-->
<!--        <router-link to="/AdminIncome" title="Admin Income">-->
<!--          <span class="icon"><i class="fa-light fa-sack-dollar"></i></span>-->
<!--          <span class="text_info"> Admin Income</span>-->

<!--        </router-link>-->
<!--      </li>-->
<!--      <li>-->
<!--        <router-link to="/AdminLevelIncome" title="Admin Level Income">-->
<!--          <span class="icon"><i class="fa-light fa-comments-dollar"></i></span>-->
<!--          <span class="text_info"> Admin Level Income</span>-->

<!--        </router-link>-->
<!--      </li>-->
<!--      <li>-->
<!--        <router-link to="/AdminPoolincome" title=" Admin Pool Income">-->
<!--          <span class="icon"><i class="fa-regular fa-hands-holding-dollar"></i></span>-->
<!--          <span class="text_info"> Admin Pool Income</span>-->

<!--        </router-link>-->
<!--      </li>-->
<!--      <li>-->
<!--        <router-link to="/AdminWithdrawl" title="Admin Withdraw">-->
<!--          <span class="icon"><i class="fa-light fa-credit-card"></i></span>-->
<!--          <span class="text_info"> Admin Withdraw</span>-->

<!--        </router-link>-->
<!--      </li>-->
      <!-- <li v-if="this.$store.state.user.id == 1"> -->
      <li>
        <router-link to="/AdminWithdrawlrequest" title="Admin Withdraw">
          <span class="icon"><i class="fa-light fa-credit-card"></i></span>
          <span class="text_info"> Withdrawal Request</span>

        </router-link>
      </li>
      
      <!-- <li v-if="this.$store.state.user.id == 1"> -->
      <li>
        <router-link to="/Currencies" title="Available Currencies">
          <span class="icon"><i class="fa-sharp fa-solid fa-dollar-sign"></i></span>
          <span class="text_info"> Currencies</span>

        </router-link>
      </li>
<!--      <li>-->
<!--        <router-link to="/Genration" title="Genration Tree">-->
<!--          <span class="icon"><i class="fa-light fa-credit-card"></i></span>-->
<!--          <span class="text_info"> Genration Tree</span>-->

<!--        </router-link>-->
<!--      </li>-->
   

      <li>
       <router-link to="/Client-ticket" title="Client Ticket">
         <span class="icon"><i class="fa-sharp fa-light fa-ticket-airline"></i></span>
         <span class="text_info">Client Ticket</span>
       </router-link>
     </li>
     <li>
       <router-link to="/admin-ticket" title="Admin-Ticket">
         <span class="icon"><i class="fa-sharp fa-regular fa-clipboard-list-check"></i></span>
         <span class="text_info">Admin Ticket</span>
       </router-link>
     </li>
    
     <li>
        <button class="logout_all" @click="logout" title="Logout">
          
          <span class="icon"><i class="fa-light fa-arrow-up-left-from-circle"></i></span>
          <span class="text_info" >Logout</span>

        </button>
      </li>
    </ul>

  </div>

</aside>
</template>

<script>
export default {
  props: ['activemode'],
  methods:{
    logout() {
      localStorage.removeItem('token');
      this.$store.commit('updateUserDetails');
      this.$store.commit('updateLoginStatus',false);
      this.$router.push({name:'Login'});
    }
  }
}
</script> 

<style scoped>
.sidebar {
  width: 210px;
  background-color: #242424;
  top: 0;
  padding: .5rem 0;
  position: fixed;
  height: 100vh;
  transition: .5s ease-in-out;
  z-index: 9;


}

.logo_b {
top: 0;
  padding: 10px 2px;
  position: fixed;
  background-color: #242424;
  z-index: 999;
}

.menubar ul li {
  width: 100%;
  list-style: none;
}

.menubar ul li a {
  position: relative;
  width: 100%;
  display: flex;
  color: #707070;
  font-weight: 500;
  align-items: center;
  padding: 5px 0;
}

.menubar ul li a .icon {
  min-width: 60px;
  height: 44px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
}

.menubar ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  text-align: start;
  white-space: nowrap;
}

/* class add css */

aside.sidebar.active {
  width: 80px;
}
.logout_all {
    background-color: transparent;
    outline: none;
    border: none;
    position: relative;
    width: 100%;
    display: flex;
    color: #707070;
    font-weight: 500;
    align-items: center;
    padding: 5px 0;
}
.logout_all .icon{
  min-width: 60px;
    height: 44px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
}

</style>
