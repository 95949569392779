
<template>
<LoderInfo :load="load"></LoderInfo>

<SideBar :activemode="activeClass"></SideBar>

<div :class="main_wrapper">
  <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
  <div class="main_wrapper_b">
    <section class="bread_cum">
      <div class="container-fluid">
        <div class="row justify-content-end">

          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                </li>
                <li class="breadcrumb-item text-white"> <i class="fa-regular fa-bell me-1"></i>Add Notifications</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </section>

    <section class="pb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="income_form">
              <!-- <form action=""> -->
                <form @submit.prevent="addNotification" enctype="multipart/form-data">
                <div class="text-center my-3">
                  <h3 class="text-white">Add Notifications</h3>
                </div>
                <div class=" mb-3">
                    <label>
                      Image:
                    </label>
                      <input type="file" @change="onFileChange( $event )" name="image" id="image" class="form-control" />
                    </div>
                <div class=" mb-3">
                  <label for="" class="form-label text-white">Add Text Here</label>
                <div class="form-floating">
                  <textarea class="form-control" placeholder="Leave a comment here" v-model="body" id="floatingTextarea2" style="height: 100px"></textarea>
                  <label for="floatingTextarea2"></label>
                </div>
                </div>
                <div class="mb-3 text-center">
                  <button type="submit" class="button_a" >Add</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
<section class="m-lg-3">
  <section class="table-sec  rounded-2 mb-5 p-">

<div class="container-fluid ">
  <div class="row justify-content-center bg-light py-2 set_radius">
    <div class="col-lg-6 text-center ">
      <HeadingTitle title="Notifications"></HeadingTitle>
    </div>
  </div>
</div>
<div class="container-fluid p-4 ">
  <div class="row enteries-row align-items-center mb-5">
    <div class="col-xl-6 col-lg-6 col-md-6">
      <div class="entry-box ">
        <h5>Show</h5>
        <span>
          <select class="select" v-model="limit" v-on:change="getMoreResults">
            <option
              v-for="(option, index) in options"
              :key="index"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </span>
        <h6>enteries</h6>
      </div>
    </div>
  </div>
  <!--row enteries-row-->

  <div class="row table-row">
    <div class="col-xl-12 col-lg-12 col-md-12">
      <div class="table-responsive">
        <table class="table table-bordered ">
          <thead>
            <tr>

              <!-- <th scope="col" class="fw-bold">S.no</th> -->
              <th scope="col" class="fw-bold" style="width:40%">Notification</th>
              <th scope="col" class="fw-bold">Status <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
              <th scope="col" class="fw-bold">Date<span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
              <th scope="col" class="fw-bold">Action </th>

            </tr>
          </thead>
          <tbody v-if="notifications.length">
            <tr v-for="(notification, index) in notifications" :key="index">
              <!-- <td>{{ index + 1 }}</td> -->
              <td>{{ notification.body }}</td>
              <td> <span :class="notification.status ? 'badge bg-success': 'badge bg-danger'">{{ notification.status ? 'Active': 'Inactive'}} </span></td>
              <td>{{ dateFormate(notification.created_at) }}</td>
              <td> 
                <button type="button" :class="notification.status ? 'unBlock_user mx-3': 'change_wallet mx-3'" @click="showHideNotification(notification.id)">
                  {{ notification.status ? 'Disable': 'Enable'}}
                </button>
                <button class="block_user mx-3" @click="deleteNotification(notification.id)"> Delete </button>
               </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5">No Data Available</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--table-responsive-->
    </div>
    <!--col-xl-12 col-lg-12 col-md-12-->
  </div>
  <!--row table-row-->

  <div class="row pagination-row align-items-center" v-if="notifications.length">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pages-entries-text">
                <h5>showing</h5>
                <span
                  >{{ 1 + limit * currentPage - limit }} to
                  {{ limit * currentPage - limit + notifications.length }} of
                  {{ total }}</span
                >
                <h6>entries</h6>
              </div>
              <!--pages-entries-text-->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pagenation-inner">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a
                        :class="currentPage > 1 ? 'page-link' : 'page-link disabled'"
                        href="#"
                        aria-label="Previous"
                        @click="getNotificationDetails(currentPage - 1)"
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    <li
                      class="page-item"
                      v-for="index in totalPages"
                      :key="index"
                      @click="getNotificationDetails(index)"
                    >
                      <a
                        :class="index == currentPage ? 'page-link active' : 'page-link'"
                        href="#"
                      >
                        {{ index }}
                      </a>
                    </li>

                    <li class="page-item">
                      <a
                        :class="
                          currentPage < totalPages ? 'page-link' : 'page-link disabled'
                        "
                        href="#"
                        aria-label="Next"
                        @click="getNotificationDetails(currentPage + 1)"
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!--pagenation-inner-->
            </div>
            <!--col-xl-6 col-lg-6 col-md-6--->
          </div>
  <!--row pagination-row-->

  <!--container-fluid-->
</div>

</section>
</section>

  </div>
  <FooterDetail></FooterDetail>
</div>
</template>

  
<script>
import HeadingTitle from "@/components/HeadingTitle.vue";
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";
import LoderInfo from "@/components/LoderInfo.vue";

export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    HeadingTitle,
    FooterDetail,
    LoderInfo
  },
  data() {
    return {
      activeClass: 'sidebar',
      main_wrapper: 'main_wrapper',
      notifications: [] ,
      totalPages: 0,
      currentPage: 1,
      total: 0,
      limit: 10,
      body: '',
      image: null,
      options: [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ]
    }
  },
  methods: {
    dateFormate(date){
      let cur_date = new Date(date);
      cur_date.setTime(cur_date.getTime() + 19800000)
      cur_date = cur_date.toISOString()
      return cur_date.substr(0, (cur_date.length - 5)).replace('T', ' ');
    },
    changeActiveMode(payload) {
      this.activeClass = payload.activeClass
        this.main_wrapper = payload.mainWrapperClass
    },
    onFileChange(event) {
      this.image = event.target.files[0];
    },

    getMoreResults() {
      let page = 1;
      this.getNotificationDetails(page, this.limit);
    },

    async getNotificationDetails(page = 1) {
      this.$axios.get("getnotifications?page=" + page + "&limit=" + this.limit).then((result) => {
        this.notifications = result.data.notifications;
        this.totalPages = result.data.totalPages;
        this.total = result.data.count;
        this.currentPage = parseInt(result.data.currentPage);
        this.image = null;
      });
    },

    async addNotification(){
      const formData = new FormData();
      
      formData.append('image', this.image);
      formData.append('body', this.body);

      console.log(formData);
      let result =  await this.$axios.post('addNotification',formData,{headers: {'Content-Type': 'multipart/form-data'}});
      console.log(result);
      if(result.data[0]){
        this.$swal({icon:'success',text:"Notification is added successfully."});
        this.body = '';
        this.image = '';
        document.getElementById("image").value = "";
      }else{
        this.$swal({icon:'error',text:result.data[1]});
        this.body = '';
        this.image = '';
      }
        this.getNotificationDetails();
    },

    async deleteNotification(id){
      let result =  await this.$axios.get('deleteNotification?id='+id);
      if(result.data[0]){
        this.$swal({icon:'success',text:"Notification deleted successfully."});
        this.body = '';
      }else{
        this.$swal({icon:'error',text:result.data[1]});
        this.body = '';
      }
      this.getNotificationDetails();
    },
    async showHideNotification(id){
      let result =  await this.$axios.get('showHideNotification?id='+id);
      if(result.data[0]){
        this.$swal({icon:'success',text:"Notification status changed successfully."});
        this.body = '';
      }else{
        this.$swal({icon:'error',text:result.data[1]});
        this.body = '';
      }
      this.getNotificationDetails();
    }

  },
  async beforeMount() {
    this.getNotificationDetails();
  },
}
</script>

  
<style scoped>
.income_form {
  background-color: #282828;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: #f8f9fa 0px 0px 2px;
}

.box_a {
  background-size: 100% 100%;
  padding: 20px 25px;
  transition: .5s ease-in-out;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dash-icon.text-orange {
  background: rgba(236, 84, 68, 0.2);
}

.text-orange {
  color: #f86300 !important;
}

.dash-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 10px auto;
  line-height: 43px;
  position: relative;
  background: #e3e6f9;
  z-index: 1;
  text-align: center;
}

.dash-icon i {
  text-align: center;
  vertical-align: middle;
}

.fs-22 {
  font-size: 22px !important;
}

.dash-icon.text-orange:after {
  background: rgba(236, 84, 68, 0.1);
}

.dash-icon:after {
  position: absolute;
  content: "";
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: 0;
  top: -10px;
  right: 0;
  left: -10px;
  background: rgba(235, 237, 252, 0.4);
  z-index: -1;
}

.dash-icon.text-secondary1 {
  background: rgba(36, 228, 172, 0.2);
}

.text-secondary1 {
  color: #24e4ac !important;
}

.dash-icon.text-secondary1:after {
  background: rgba(36, 228, 172, 0.1);
}

.text-secondary {
  color: #9c52fd !important;
}

.dash-icon.text-secondary:after {
  background: rgb(156 82 253 / 27%);
}

.card {
  background-color: #282828 !important;
  box-shadow: 0px 0px 2px 0px rgb(255 255 255);
}

button.change_wallet
{
    background-color: #ffab46;
    outline: none;
    border: navajowhite;
    padding: 10px 20px;
    color: white;
    border-radius: 7px;
} 

button.block_user
{
    background-color: #ed2713eb;
    outline: none;
    border: navajowhite;
    padding: 10px 20px;
    color: white;
    border-radius: 7px;
}

  button.unBlock_user
{
    background-color: #916044eb;
    outline: none;
    border: navajowhite;
    padding: 10px 20px;
    color: white;
    border-radius: 7px;
  }

</style>
