<template>
  <SideBar :activemode="activeClass"></SideBar>

  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum">
        <div class="container-fluid">
          <div class="row justify-content-end">
            <div class="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/Home"
                    ><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link
                    >
                  </li>
                  <li class="breadcrumb-item text-white">
                    <i class="fa-regular fa-money-check-dollar me-1"></i>Leadership Income
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
        <div class="col-lg-12 bg-secondary ">
          <h3 class="text-warning text-center mb-4 p-2">Leadership Income will be distributed on every Sunday</h3>
        </div>
  <section class="pb-5">
    <div class="container-fluid">
      <div class="row justify-content-center text-center">
        <div class="col-lg-6 ">
          <h2 class="text-white text-center mb-4">Weekly Preformence</h2>
        </div>

      </div>
      <div class="row justify-content-center">
        <div class="col-xxl-3 col-md-6">
          <div class="card my-2 py-2">
            <div class="card-body">
              <div class="row mb-1">
                <div class="col">
                  <p class="mb-2 text-white">Total Team</p>
                  <h3 class="mb-0 number-font text-white">{{this.performance.directs + this.performance.powerLegTeam + this.performance.otherLegTeam}}</h3>
                </div>
<!--                <div class="col-auto mb-0">-->
<!--                  <div class="dash-icon text-orange"><i class="fa-regular fa-credit-card f-22"></i></div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-md-6">
          <div class="card my-2 py-2">
            <div class="card-body">
              <div class="row mb-1">
                <div class="col">
                  <p class="mb-2 text-white">Power Leg Team</p>
                  <h3 class="mb-0 number-font text-white"> {{ this.performance.powerLegTeam }} </h3>
                </div>
<!--                <div class="col-auto mb-0">-->
<!--                  <div class="dash-icon text-secondary1"><i class="fa-light fa-credit-card-blank f-22"></i></div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>



      </div>
      <div class="row justify-content-center">
        <div class="col-xxl-3 col-md-6">
          <div class="card my-2 py-2">
            <div class="card-body">
              <div class="row mb-1">
                <div class="col">
                  <p class="mb-2 text-white">Other Leg Team</p>
                  <h3 class="mb-0 number-font text-white">{{this.performance.otherLegTeam}}</h3>
                </div>
<!--                <div class="col-auto mb-0">-->
<!--                  <div class="dash-icon text-secondary"><i class="fa-regular fa-money-check-dollar f-22"></i></div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-3 col-md-6">
          <div class="card my-2 py-2">
            <div class="card-body">
              <div class="row mb-1">
                <div class="col">
                  <p class="mb-2 text-white">Direct Team</p>
                  <h3 class="mb-0 number-font text-white">{{this.performance.directs}}</h3>
                </div>
<!--                <div class="col-auto mb-0">-->
<!--                  <div class="dash-icon text-orange"><i class="fa-regular fa-credit-card f-22"></i></div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
        <!-- <div class="col-lg-12 mt-3 bg-secondary p-1">
          <h3 class="text-white text-center mb-4">Leadership Income will be distributed every Sunday</h3>
        </div> -->
  </section>

      <section class="table-sec m-lg-3 rounded-2">
        <div class="container-fluid">
          <div class="row justify-content-center bg-light py-2 set_radius">
            <div class="col-lg-6 text-center">
              <HeadingTitle title="Leadership Income Detail"></HeadingTitle>
            </div>
          </div>
        </div>
        <div class="container-fluid p-4">
          <div class="row enteries-row align-items-center mb-5">
            <div class="col-xl-2 col-lg-5 col-md-6">
              <div class="entry-box">
                <h5>Show</h5>
                <span>
                  <select class="select" v-model="limit" v-on:change="getMoreResults">
                    <option
                      v-for="(option, index) in options"
                      :key="index"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </span>

                <h6>enteries</h6>
              </div>
              <!--entry-box-->
            </div>
            <!--col-xl-6 col-lg-6 col-md-6-->

            <!-- <div class="col-xl-7 col-lg-3 col-md-6">
              <div class="search-box">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1"
                    ><img src="../assets/images/search.svg" alt="" class="img-fluid"
                  /></span>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search....."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div> -->
            <!--col-xl-6 col-lg-6 col-md-6-->
          </div>
          <!--row enteries-row-->

          <div class="row table-row">
            <div class="col-xl-12 col-lg-12 col-md-12">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col" class="fw-bold">
                        Amount
                        <span
                          ><img
                            src="../assets/images/table_icon_2.png"
                            alt=""
                            class="img-fluid"
                        /></span>
                      </th>
                      <th scope="col" class="fw-bold">
                        Note
                        <span
                          ><img
                            src="../assets/images/table_icon_2.png"
                            alt=""
                            class="img-fluid"
                        /></span>
                      </th>
                      <th scope="col" class="fw-bold">
                        Date
                        <span
                          ><img
                            src="../assets/images/table_icon_2.png"
                            alt=""
                            class="img-fluid"
                        /></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="income_details.length">
                    <tr v-for="(income, index) in income_details" :key="index">
                      <td>{{ income.credit }}</td>
                      <td>{{ income.note }}</td>
                      <td>{{ dateFormate(income.created_at) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4">No Data Available</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--table-responsive-->
            </div>
            <!--col-xl-12 col-lg-12 col-md-12-->
          </div>
          <!--row table-row-->

          <div class="row pagination-row align-items-center" v-if="income_details.length">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pages-entries-text">
                <h5>showing</h5>
                <span
                  >{{ 1 + limit * currentPage - limit }} to
                  {{ limit * currentPage - limit + income_details.length }} of
                  {{ total }}</span
                >
                <h6>entries</h6>
              </div>
              <!--pages-entries-text-->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pagenation-inner">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a
                        :class="currentPage > 1 ? 'page-link' : 'page-link disabled'"
                        href="#"
                        aria-label="Previous"
                        @click="getResults(currentPage - 1)"
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    <li
                      class="page-item"
                      v-for="index in totalPages"
                      :key="index"
                      @click="getResults(index)"
                    >
                      <a
                        :class="index == currentPage ? 'page-link active' : 'page-link'"
                        href="#"
                      >
                        {{ index }}
                      </a>
                    </li>

                    <li class="page-item">
                      <a
                        :class="
                          currentPage < totalPages ? 'page-link' : 'page-link disabled'
                        "
                        href="#"
                        aria-label="Next"
                        @click="getResults(currentPage + 1)"
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!--pagenation-inner-->
            </div>
            <!--col-xl-6 col-lg-6 col-md-6--->
          </div>
          <!--row pagination-row-->

          <!--container-fluid-->
        </div>
      </section>
    </div>
    <FooterDetail></FooterDetail>
  </div>
</template>

<script>
import HeadingTitle from "@/components/HeadingTitle.vue";
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";
export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    HeadingTitle,
    FooterDetail,
  },
  data() {
    return {
      activeClass: "sidebar",
      main_wrapper: "main_wrapper",
      income_details: [],
      totalPages: 0,
      currentPage: 1,
      performance:{directs:0,powerLegTeam:0,otherLegTeam:0},
      total: 0,
      limit: 10,
      options: [
        { label: "10", value: 10 },
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
    };
  },
  methods: {
    dateFormate(date){
      let cur_date = new Date(date);
      cur_date.setTime(cur_date.getTime() + 19800000)
      cur_date = cur_date.toISOString()
      return cur_date.substr(0, (cur_date.length - 5)).replace('T', ' ');
    },
    changeActiveMode(payload) {
      console.log(payload);
      (this.activeClass = payload.activeClass),
        (this.main_wrapper = payload.mainWrapperClass);
    },

    getMoreResults() {
      let page = 1;
      this.getResults(page);
    },

    async getResults(page = 1) {
      this.$axios.get("leadershipIncome?page=" + page + "&limit=" + this.limit).then((income) => {
          console.log(income);
          this.income_details = income.data.leadership_income;
          this.performance = income.data.performance;
          this.totalPages = income.data.totalPages;
          this.total = income.data.count;
          this.currentPage = parseInt(income.data.currentPage);
        });
    },
  },
  beforeMount() {
    this.getResults();
  },
};
</script>

<style scoped>
.income_form {
  background-color: #282828;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: #f8f9fa 0px 0px 2px;
}
select#inputGroupSelect01 {
  background-color: #282828;
  color: white;
  border: 1px solid #e9ecef52;
}
select#inputGroupSelect01 {
  background-image: url("../assets/images/down_arrow.svg") !important;
}
.inner_text_a {
  background-color: #845fcb;
  
}
.inner_text.a{
  background-color: #4384fa;
}
.inner_text.b{
  background-color: #5fba67;
}
.inner_text.c{
  background-color: #845fcb;
}
.inner_text.d{
  background-color: #00bdc3;
}
.income_form {
  background-color: #282828;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: #f8f9fa 0px 0px 2px;
}

.box_a {
  background-size: 100% 100%;
  padding: 20px 25px;
  transition: .5s ease-in-out;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dash-icon.text-orange {
  background: rgba(236, 84, 68, 0.2);
}

.text-orange {
  color: #f86300 !important;
}

.dash-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 10px auto;
  line-height: 43px;
  position: relative;
  background: #e3e6f9;
  z-index: 1;
  text-align: center;
}

.dash-icon i {
  text-align: center;
  vertical-align: middle;
}

.fs-22 {
  font-size: 22px !important;
}

.dash-icon.text-orange:after {
  background: rgba(236, 84, 68, 0.1);
}

.dash-icon:after {
  position: absolute;
  content: "";
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: 0;
  top: -10px;
  right: 0;
  left: -10px;
  background: rgba(235, 237, 252, 0.4);
  z-index: -1;
}

.dash-icon.text-secondary1 {
  background: rgba(36, 228, 172, 0.2);
}

.text-secondary1 {
  color: #24e4ac !important;
}

.dash-icon.text-secondary1:after {
  background: rgba(36, 228, 172, 0.1);
}

.text-secondary {
  color: #9c52fd !important;
}

.dash-icon.text-secondary:after {
  background: rgb(156 82 253 / 27%);
}

.card {
  background-color: #282828 !important;
  box-shadow: 0px 0px 2px 0px rgb(255 255 255);
}
</style>

