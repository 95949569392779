<template>
<SideBar :activemode="activeClass"></SideBar>

<div :class="main_wrapper">
  <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
  <div class="main_wrapper_b">
    <section class="bread_cum ">
      <div class="container-fluid">
        <div class="row justify-content-end">

          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard"><i  class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                </li>
                <!-- <li class="breadcrumb-item " >Library</li> -->

              </ol>
            </nav>
          </div>
        </div>

      </div>
    </section>
    <section class="mb-5">
      <div class="row">
        <div class="col-md-12">
          <div class="container-fluid">
            <div class="card  banner">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-3 col-lg-3 text-center mb-3 mb-lg-0"> <img src="../../assets/images/profit.png" alt="img" class="w-95 zoom_in"> </div>
                  <div class="col-xl-9 col-lg-9 ps-lg-0">
                    <div class="row">
                      <div class="col-xl-7 col-lg-6">
                        <div class="text-start text-white mt-xl-4">
                          <h3 class="font-weight-semibold">Welcome Username</h3>

                          <p class="mb-lg-0 ">The goal is to provide users with an efficient, secure, and cost-effective way to transact both domestically and internationally.

                          </p>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-6 text-lg-center mt-xl-4">
                        <h5 class="font-weight-semibold mb-1 text-white">This Month Your Income</h5>
                        <h2 class="mb-3 number-font text-white">$10M</h2>
                        <div class="btn-list mb-xl-0">

                          <router-link to="/Income" class="go_income_page"> Check Details </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="home_page_data mb-5">
      <div class="container-fluid">

        <div class="row align-items-center justify-content-center">
          <div class="col-lg-3 col-md-6 my-3" v-for="(data_a, index) in data_a" :key="index">
            <div class="box rounded-1" :style="{'background-image': 'url(' + require('@/assets/images/'+data_a.bg+'.png') + ')'}">
              <div class="row align-items-center">
                <div class="col-7 p-0">

                  <h6 class="text-white">{{ data_a.heading }}</h6>
                  <h4 class="text-white ">{{ data_a.info}}</h4>
                </div>
                <div class="col-5">

                  <img :src="require(`@/assets/images/${data_a.path1}`)" alt="" class="img-fluid ">
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

    </section>

  </div>
  <FooterDetail></FooterDetail>
</div>
</template>

  
<script>
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";

export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    FooterDetail

  },
  data() {
    return {
      activeClass: 'sidebar',
      main_wrapper: 'main_wrapper',

      data_a: [{
          bg: "skyblue",
          heading: "User Name",
          path1: "first.png",
          info: "Full Name"
        },
        {
          bg: "yellow",
          heading: "Wallet Address",
          path1: "wallet.png",
          info: "123456"
        },
        {

          bg: "orange",
          heading: "Referral Link",
          path1: "link.png",
          info: "Demo"
        },
        {

          bg: "green",
          heading: "Active date",
          path1: "pool.png",
          info: "Demo"
        },
        {
          bg: "lb",
          heading: "Direct Bonous",
          path1: "direct_bonus.png",
          info: "123456"
        },
        {
          bg: "red",
          heading: "Level Bonous",
          path1: "graph.png",
          info: "123456"
        },
        {
          bg: "up",
          heading: "Leaderships Bonous",
          path1: "gift.png",
          info: "123456"
        },

        {
          bg: "purple",
          heading: "Universal Prize",
          path1: "trophy.png",
          info: "123456"
        },
        {
          bg: "skyblue",
          heading: "Total Team",
          path1: "total_team.png",
          info: "123456"
        },
        {

          bg: "yellow",
          heading: "Direct Team",
          path1: "d_team.png",
          info: "123456"
        },
        {
          bg: "orange",
          heading: "Current Package",
          path1: "package.png",
          info: "Demo"
        },
        {
          bg: "green",
          heading: "Booster Income",
          path1: "booster.png",
          info: "Demo"
        },

      ],

    }
  },
  methods: {
    changeActiveMode(payload) {
      console.log(payload);
      this.activeClass = payload.activeClass,
        this.main_wrapper = payload.mainWrapperClass
    }
  }
}
</script>

  
<style scoped>
.box {
  background-size: 100% 100%;
  padding: 20px 25px;
  transition: .5s ease-in-out;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner {
  background-image: linear-gradient(to right, rgba(82, 92, 229, 0.7) 0%, rgba(82, 92, 229, 0.6) 100%), url(../../assets/images/bg.png);
  background-size: cover;
  position: relative;
  overflow: hidden;
  border: 0px !important;
}

.banner .w-95 {
  width: 250px !important;
}

.go_income_page {
  background-color: #fcab35;
  color: white;
  padding: 10px 15px;
  font-weight: 500;
  letter-spacing: .6px;
  border-radius: 5px;
}
</style>
