<template>
<section class="login_detail">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">

        <div class="login_a text-center">
          <div class="logo_login  mb-4">
         <span class="logo_a">LOGO</span>
          </div>
       
        
          <div class="my-3">
            <button  type="button" @click="login" class="button_a">Connect To Wallet</button>
          </div>
<div class="go_sign_up">

  <router-link to="signup" class="back_signup">
          <p class="text-white">Does not hava an account? Signup here</p>
        </router-link>
</div>

        </div>
      </div>
    </div>
  </div>

</section>
</template>

<script>
import Web3 from "web3";
export default {
  data(){
    return {
      is_metamask:false
    }
  },
  methods:{
    login(){
      if (this.is_metamask){
        let web = new Web3(window.ethereum);
        web.eth.requestAccounts().then(accounts => {
          this.$axios.post('login',{account_token:accounts[0]}).then(response => {
            if (response.data.islogged){
              localStorage.setItem('token',response.data.accessToken)
              this.$router.push({name:'Dashboard'})
            }else {
              this.$swal({icon:'error',text:response.data.message})
            }
          }).catch(() => {
            this.$swal({icon:'error',text:'something went wrong..please try again'})
          })
        }).catch(error => {
          this.$swal({icon:'error',text:error.message})
        })
      }
    }
  },
  mounted() {
    if (window.ethereum){
      this.is_metamask = true;
    }else {
      this.$swal('Please install MetaMask Extension First')
    }
  }
}
</script>

<style scoped>
.login_detail {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(../assets/images/bg_login.jpg); */
  background-size: 100% 100%;
  background: linear-gradient(55deg, rgba(0, 0, 0, 1) 40%, rgba(56, 40, 16, 1) 100%);
}

.login_a {
  background-color: #000000bd;
  padding: 40px 30px;
  border-radius: 5px;
  box-shadow: 1px 5px 24px 0 #f8f9fa3d;
}

.button_a {
  background-color: #FCAB35;
  color: white;
  font-size: 20px;
  padding: 8px 30px;
  border-radius: 5px;
  border: 2px solid transparent;
}

.button_a:hover {
  border: 2px solid #FCAB35;
  background-color: transparent;
  transition: .5s ease-in-out;
}
.back_signup p:hover {
    color: #fcab35 !important;
    text-decoration: underline;
}
span.logo_a {
    color: white;
    font-size: 25px;
}
</style>
