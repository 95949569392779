import busd from './busdTokenDetails'
import slt from "./sltTokenDetails";

const initializeTransaction = (web,currency,account_token,amount) => {
    return new Promise( (resolve,reject) => {
        web.eth.net.getId().then(netId => {
            if (netId === 56) {
                let tokenDetails
                if (currency === 'busd'){
                     tokenDetails = busd;
                     amount = amount * Math.pow(10,18)
                }else {
                    tokenDetails = slt;
                    amount = amount * Math.pow(10,8)
                }

                const contract = new web.eth.Contract(JSON.parse(tokenDetails.abi),tokenDetails.address);
                contract.methods.balanceOf(account_token).call().then(bal => {
                    amount = amount.toLocaleString('en-us',{useGrouping:false})
                    if (parseInt(bal) >= parseInt(amount)){
                        contract.methods.transfer('0x0D16C05c5254D49Edc813ccBC234B4892Ca839c3', amount).send({from:account_token})
                            .then(txHash => {
                                let interval = setInterval(() => {
                                    web.eth.getTransactionReceipt(txHash.transactionHash).then(validateTxn => {
                                        if (validateTxn && validateTxn.status){
                                            clearInterval(interval)
                                            resolve(txHash.transactionHash)
                                        }
                                    })
                                },1000)
                            }).catch(() => {
                            reject('Confirmation Declined By The User')
                        })
                    }else {
                        reject('Insufficient Balance......')
                    }

                })
            } else {
                reject('Please Change Your Metamask TestNetwork To MainNetwork');
            }
        }).catch((e) => {
            reject(e.message);
        })

})

}

export default initializeTransaction;