<template>
<section class="table-sec m-lg-3 rounded-2">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-lg-6 text-center">
        <HeadingTitle title="Heading Here"></HeadingTitle>
      </div>
    </div>
    <div class="row enteries-row align-items-center mb-5">
      <div class="col-xl-6 col-lg-6 col-md-6">
        <div class="entry-box">
          <h5>Show</h5>
          <span><select class="select">
              <option value="0">10</option>
              <option value="0">20</option>
              <option value="0">30</option>
              <option value="0">40</option>
              <option value="0">50</option>
            </select></span>

          <h6>enteries</h6>
        </div>
        <!--entry-box-->
      </div>
      <!--col-xl-6 col-lg-6 col-md-6-->

      <div class="col-xl-6 col-lg-6 col-md-6">
        <div class="search-box">
          <div class="input-group">
            <span class="input-group-text" id="basic-addon1"><img src="../assets/images/search.svg" alt="" class="img-fluid"></span>
            <input type="text" class="form-control" placeholder="Search....." aria-label="Username" aria-describedby="basic-addon1">
          </div>
        </div>
        <!--search-box-->
      </div>
      <!--col-xl-6 col-lg-6 col-md-6-->
    </div>
    <!--row enteries-row-->

    <div class="row table-row">
      <div class="col-xl-12 col-lg-12 col-md-12">
        <div class="table-responsive">
          <table class="table table-bordered ">
            <thead>
              <tr>
                <th scope="col" class="fw-bold">S.NO <span><img src="../assets/images/table_icon_1.png" alt="" class="img-fluid"></span></th>
                    <th scope="col" class="fw-bold">User Name <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                    <th scope="col" class="fw-bold">Wallet Address <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                    <th scope="col" class="fw-bold">Action <span><img src="../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Larry the Bird</td>
                <td>@twitter</td>
                <td>@twitter</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--table-responsive-->
      </div>
      <!--col-xl-12 col-lg-12 col-md-12-->
    </div>
    <!--row table-row-->

    <div class="row pagination-row align-items-center">
      <div class="col-xl-6 col-lg-6 col-md-6">
        <div class="pages-entries-text">
          <h5>showing</h5>
          <span>1 to 10 of 57</span>
          <h6>entries</h6>
        </div>
        <!--pages-entries-text-->
      </div>
      <!--col-xl-6 col-lg-6 col-md-6-->

      <div class="col-xl-6 col-lg-6 col-md-6">
        <div class="pagenation-inner">
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <!--pagenation-inner-->
      </div>
      <!--col-xl-6 col-lg-6 col-md-6--->
    </div>
    <!--row pagination-row-->
  </div>
  <!--container-fluid-->
</section>
</template>

<script>
import HeadingTitle from "@/components/HeadingTitle.vue";
export default {
  name: "TabelDetail",
  // props: ['main_wrapper'],
  components: {
    HeadingTitle

  }
}
</script>

  
<style scoped>
.box {
  background-size: 100% 100%;
  padding: 20px 25px;

  transition: .5s ease-in-out;
}

.entry-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.entry-box h5 {
  color: white;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.entry-box h6 {
  color: white;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.select {
  background-color: #282828;
  padding: 5px 5px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

option {
  color: var(--black);
}

.search-box {
  text-align: right;
}

.search-box .input-group {
  max-width: 450px;
  margin-left: auto;
}

.search-box .input-group .input-group-text {
  background-color: transparent;
  border-color: var(--form-border);
  border-right: none;
}

.search-box .input-group .form-control {
  background-color: transparent;
  border: 1px solid #e9ecef52;
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.search-box .input-group .form-control:focus {
  box-shadow: none;
}

.table thead {
  background-color: var(--heading-bg);
}

.table thead tr th {
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-bottom: none;
  text-align: center;
  padding: 15px 15px;
}

.table tbody {
  border-top: none !important;
}

.table tbody tr td {
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  padding: 15px 10px;
}

.table tbody tr td #setprice {
  min-width: 130px;
  background-color: transparent;

  height: 35px;
  padding: 5px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
}

.table tbody tr td #setprice:focus {
  box-shadow: none;
}

.pages-entries-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pages-entries-text h5 {
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
}

.pages-entries-text span {
  font-weight: 600;
  color: white;
}

.pages-entries-text h6 {
  color: white;
  margin: 0;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
}

.previous-text span {
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.next-text span {
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.pagenation-inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 45px;
}

.pagelist {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  justify-content: center;
}

.pagelist li {
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.pagelist li.active {
  width: 35px;
  height: 35px;
  background-color: var(--heading-bg);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-heading {
  text-align: center;
  margin-bottom: 30px;
}

.form-heading h5 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  color: white;
  margin: 0;
}

.input-group .input-group-text {
  background-color: transparent;
  border-color: var(--form-border);
  color: white
}

.input-group .form-control {
  background-color: transparent;
  border: 1px solid var(--form-border);
  font-size: 16px;
  font-weight: 500;
  color: white;
  border-left: none;
  min-height: 40px;
}

.input-group .form-control:focus {
  box-shadow: none;
}

.limit-butt {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 10px;
}

.limit-butt li .btn.btn-primary {
  min-width: 228px;
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.limit-butt li .btn.btn-primary:focus {
  box-shadow: none;
}

.form-box {
  margin-bottom: 35px;
}

#green {
  background-color: var(--green);
  border-color: var(--green);
}

#red {
  background-color: var(--red);
  border-color: var(--red);
}

.btn-close {
  position: absolute;
  top: -10px;
  right: 0;
  border: 2px solid white;
  width: 25px;
  height: 25px;
  opacity: 1;
  border-radius: 35px;
  background: none;
}

.btn-close:focus {
  box-shadow: none;
}

section.table-sec {
  background-color: hsla(0, 0%, 100%, 0.11);
  padding: 30px 20px;
}

.table>:not(caption)>*>* {
  color: white;
}

@media all and (min-width:768px) and (max-width: 991px) {
  .pagenation-inner {
    gap: 20px;
  }

  .pagelist {
    gap: 20px;
  }

}

@media all and (min-width: 320px) and (max-width: 767px) {
  .table thead tr th {
    padding: 15px 10px;
    font-size: 14px;
  }

  .table tbody tr td {
    font-size: 13px;
    min-width: 150px;
  }

  .entry-box {
    margin-bottom: 15px;
    justify-content: center;
  }

  .pages-entries-text {
    justify-content: center;
    margin-bottom: 10px;
  }

  .pagenation-inner {
    justify-content: center;
    gap: 9px;
  }

  .pagelist {
    gap: 20px;
  }

  .pagelist li.active {
    width: 25px;
    height: 25px;
  }

  .limit-butt li .btn.btn-primary {
    min-width: 125px;
  }
}
</style>
