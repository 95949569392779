<template>
  <SideBar :activemode="activeClass"></SideBar>
  
  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum">
        <div class="container-fluid">
          <div class="row justify-content-end">
  
            <div class="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/dashboard"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item text-white"> <i class="fa-solid fa-users me-1"></i> Tree</li>
  
                </ol>
              </nav>
            </div>
          </div>
  
        </div>
      </section>
      <section class="tree_detail">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="main_div mt-4">
                <div class="card set_radius ">
                  <h2 class="header-title text-center fs-2  text-dark"> Tree View </h2>
                  <div class="card-body set_a_bg pb-5">
  
                    <div class="col-lg-8 tree mx-auto">
                      <div class="panel panel-default">
                        <div class="panel-wrapper">
                          <div>
                            <ul class="custom-tree-data">
                              <li>
                                <div class="text-center">
                                  <div class="img-data-tree">
                                    <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                  </div>
                                  <a href="" class="">
                                    <span data-id="1" id="user_1" class="set_text text-white">Demo1</span>
                                  </a>
                                </div>
                                <ul>
                                  <li class="left">
                                    <div class="text-center">
                                      <div class="img-data-tree">
                                        <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                      </div>
                                      <a href="" class="">
                                        <span class="set_text text-white">Demo2</span>
                                      </a>
                                    </div>
                                    <ul>
  
                                      <li class="left">
                                        <div class="text-center">
                                          <div class="img-data-tree">
                                            <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                          </div>
                                          <a href="" class="">
                                            <span class="set_text text-white">Demo3</span>
                                          </a>
                                        </div>
                                      </li>
                                      <li class="left">
                                        <div class="text-center">
                                          <div class="img-data-tree">
                                            <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                          </div>
                                          <a href="" class="">
                                            <span class="set_text text-white">Demo4</span>
                                          </a>
                                        </div>
                                      </li>
                                      <li class="left">
                                        <div class="text-center">
                                          <div class="img-data-tree">
                                            <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                          </div>
                                          <a href="" class="">
                                            <span class="set_text text-white">Demo5</span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                  <li class="left">
                                    <div class="text-center">
                                      <div class="img-data-tree">
                                        <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                      </div>
                                      <a href="" class="">
                                        <span class="set_text text-white">Demo6</span>
                                      </a>
                                    </div>
                                   
                                    <ul>
                                     
                                      <li class="left">
                                        <div class="text-center">
                                          <div class="img-data-tree">
                                            <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                          </div>
                                          <a href="" class="">
                                            <span class="set_text text-white">Demo7</span>
                                          </a>
                                        </div>
                                      </li>
  
                                      <li class="left">
                                        <div class="text-center">
                                          <div class="img-data-tree">
                                            <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                          </div>
                                          <a href="" class="">
                                            <span class="set_text text-white">Demo8</span>
                                          </a>
                                        </div>
                                      </li>
                                      <li class="left">
                                        <div class="text-center">
                                          <div class="img-data-tree">
                                            <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                          </div>
                                          <a href="" class="">
                                            <span class="set_text text-white">Demo9</span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                    
                                  </li>
                                  <li class="left">
                                    <div class="text-center">
                                      <div class="img-data-tree">
                                        <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                      </div>
                                      <a href="" class="">
                                        <span class="set_text text-white">Demo2</span>
                                      </a>
                                    </div>
                                    <ul>
  
                                      <li class="left">
                                        <div class="text-center">
                                          <div class="img-data-tree">
                                            <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                          </div>
                                          <a href="" class="">
                                            <span class="set_text text-white">Demo3</span>
                                          </a>
                                        </div>
                                      </li>
                                      <li class="left">
                                        <div class="text-center">
                                          <div class="img-data-tree">
                                            <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                          </div>
                                          <a href="" class="">
                                            <span class="set_text text-white">Demo4</span>
                                          </a>
                                        </div>
                                      </li>
                                      <li class="left">
                                        <div class="text-center">
                                          <div class="img-data-tree">
                                            <img src="../../assets/images/usericon.png" alt="" class="img-fluid">
                                          </div>
                                          <a href="" class="">
                                            <span class="set_text text-white">Demo5</span>
                                          </a>
                                        </div>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
  
                  </div>
                </div>
              </div>
  
            </div>
  
          </div>
        </div>
  
      </section>
  
    </div>
  <FooterDetail></FooterDetail>
  </div>
  </template>
  
  <script>
  import SideBar from "@/components/SideBar.vue";
  import HeaderDashboard from "@/components/HeaderDashboard.vue";
  import FooterDetail from "@/components/FooterDetail.vue";
  export default {
    name: "HomeDashboard",
    // props: ['main_wrapper'],
    components: {
      SideBar,
      HeaderDashboard,
      FooterDetail
    },
    data() {
      return {
        activeClass: 'sidebar',
        main_wrapper: 'main_wrapper',
      }
    },
    methods: {
      changeActiveMode(payload) {
        console.log(payload);
        this.activeClass = payload.activeClass,
          this.main_wrapper = payload.mainWrapperClass
      }
    }
  }
  </script>
  
  <style scoped>
  .tree {
    overflow-x: scroll;
  }
  
  .panel {
    position: relative;
    border: 0 solid transparent;
  }
  
  .tree ul {
    padding-top: 20px;
    position: relative;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  .tree ul {
    list-style: none;
    padding-left: 0;
  }
  
  .tree li:only-child {
    padding-top: 0;
  }
  
  .tree li:only-child {
    padding-top: 0;
  }
  
  .tree li {
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  .tree ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
  }
  
  .tree ul li.left {
    width: 33%;
    /* display: inline-table; */
    float: left;
  }
  
  .tree li:first-child::before,
  .tree li:last-child::after {
    border: 0 none;
  }
  
  .tree li:first-child::after {
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
  }
  
  .tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
  }
  
  .tree li::before,
  .tree li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #ccc;
    width: 50%;
    height: 20px;
  }
  
  .tree li:last-child::before {
    border-right: 1px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
  }
  
  .tree li:only-child::before {
    padding-top: 0;
    display: none;
  }
  
  .main .toggle i.fa-light.fa-bars {
    display: block;
  }
  
  .main .toggle i.fa-light.fa-xmark {
    display: none;
  }
  
  .main.active .toggle i.fa-light.fa-bars {
    display: none;
  }
  
  .main.active .toggle i.fa-light.fa-xmark {
    display: block;
  }
  
  .set_button {
    background-color: #29b6fe;
    padding: 6px 16px;
    font-size: 20px;
    border-radius: 5px;
    color: white;
    border: none;
  }
  
  .card.set_radius {
    background-color: #242424;
  }
  
  .img-data-tree img {
    border-radius: 50%;
  }
  
  h2.header-title {
    background-color: white;
    padding: 10px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  
    font-weight: 700;
    font-style: italic;
  
  }
  </style>
  