<template>
<router-view />
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;

}
:root {
  --form-border: #677388;
}

a {
  text-decoration: none !important;
}

.main_wrapper {

  margin-left: 209px;

  background-color: #0d0d0d;

  transition: .5s ease-in-out;
  position: relative;

}

.main_wrapper_b {
  margin-top: 70px;
  min-height: calc(100vh - 120px);
  padding: 20px 10px 0 10px;

}

.active.main_wrapper {
  margin-left: 80px;
  transition: .5s ease-in-out;
}

ol.breadcrumb li a {
  color: white !important;
}

body,
html {
  overflow-x: hidden;
  background-color: #0d0d0d !important;
}

/*=================== footer  start============*/
footer {

  width: 100%;

}

/* animation  start*/
.box:hover img,
.zoom_in {
  animation: scalell 04s alternate infinite;
}

@keyframes scalell {
  0% {
    transform: scale(1.1, 1.1);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1.1, 1.1);
  }
}

/* animation end */

/* ==================common button============= */
.button_a {
  background-color: #FCAB35;
  color: white;
  font-size: 20px;
  padding: 8px 30px;
  border-radius: 5px;
  border: 2px solid transparent;
}

.button_a:hover {
  border: 2px solid #FCAB35;
  background-color: transparent;
  transition: .5s ease-in-out;
}

/* ==================common end============= */

/* common css */
.main_wrapper .top_header .toggle_a i.fa-light.fa-xmark {
  display: none;
}

.active.main_wrapper .top_header .toggle_a i.fa-light.fa-xmark {
  display: block;
}

.active.main_wrapper .top_header .toggle_a i.fa-light.fa-bars {
  display: none;
}

aside.sidebar.active .logo_text {
  padding: 5px 10px;
}

.active.main_wrapper .top_header {

  width: calc(100% - 80px);
  left: 80px;
}

aside.sidebar.active .menubar ul li a span.text_info {
  display: none;
}

.sidebar .logo_b .only_logo {
  display: none;
}

aside.sidebar.active .logo_b .logo_text {
  display: none;
}

aside.sidebar.active .logo_b .only_logo {
  display: block;
    padding: 11px 10px;
    width: 68px;
    color: white;
}

.menubar a.router-link-active {
  color: white !important;
}

.dropdown-item.active,
.dropdown-item:active {

  background-color: transparent !important;
  color: black !important;
}

.page-link {
  color: white !important;
  background-color: transparent !important;
  border: 1px solid #FFD707 !important;

}

.page-link:focus {

  box-shadow: none !important;
}

.form-control:focus {
  border-color: none !important;

  box-shadow: none !important;
}

nav {
  display: flex;
  justify-content: end;
}

.form-select:focus {
  border-color: none !important;
  outline: none !important;
  box-shadow: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: white !important;
}

.set_radius {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* media query */
@media only screen and (min-width: 320px) and (max-width: 575px) {

  .logo_b .logo_text {
    display: none;
  }

  .logo_b .only_logo {
    display: block !important;
  }

  .sidebar {
    left: -236px !important;
  }

  .main_wrapper {
    margin-left: 0px !important;

  }

  .top_header {

    width: 100% !important;
    top: 0 !important;
    left: 0 !important;

  }

  aside.sidebar.active {
    left: 0 !important;
    width: 210px !important;
    top: 65px;
  }

  aside.sidebar.active .menubar ul li a span.text_info {
    display: block;
  }
  aside.sidebar.active .logo_b{
top: unset ;
  }
}

.menubar ul {
  height: calc(100vh - 110px);
  overflow-y: scroll;
}

/* tabel css start here */
.entry-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.entry-box h5 {
  color: white;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.entry-box h6 {
  color: white;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.select {
  background-color: #282828;
  padding: 5px 5px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

option {
  color: var(--black);
}

.search-box {
  text-align: right;
}

.search-box .input-group {
  max-width: 450px;
  margin-left: auto;
}

.search-box .input-group .input-group-text {
  background-color: transparent;
  border-color: var(--form-border);
  border-right: none;
}

.search-box .input-group .form-control {
  background-color: transparent;
  border: 1px solid #e9ecef52;
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.search-box .input-group .form-control:focus {
  box-shadow: none;
}

.table thead {
  background-color: var(--heading-bg);
}

.table thead tr th {
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-bottom: none;
  text-align: center;
  padding: 15px 15px;
}

.table tbody {
  border-top: none !important;
}

.table tbody tr td {
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  padding: 15px 10px;
}

.table tbody tr td #setprice {
  min-width: 130px;
  background-color: transparent;

  height: 35px;
  padding: 5px 5px;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
}

.table tbody tr td #setprice:focus {
  box-shadow: none;
}

.pages-entries-text {
  display: flex;
  align-items: center;
  gap: 10px;
}

.pages-entries-text h5 {
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
}

.pages-entries-text span {
  font-weight: 600;
  color: white;
}

.pages-entries-text h6 {
  color: white;
  margin: 0;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
}

.previous-text span {
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.next-text span {
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.pagenation-inner {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 45px;
}

.pagelist {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  justify-content: center;
}

.pagelist li {
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.pagelist li.active {
  width: 35px;
  height: 35px;
  background-color: var(--heading-bg);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-heading {
  text-align: center;
  margin-bottom: 30px;
}

.form-heading h5 {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  color: white;
  margin: 0;
}

.input-group .input-group-text {
  background-color: transparent;
  border-color: var(--form-border);
  color: white
}

.input-group .form-control {
  background-color: transparent;
  border: 1px solid var(--form-border);
  font-size: 16px;
  font-weight: 500;
  color: white;
  border-left: none;
  min-height: 40px;
}

.input-group .form-control:focus {
  box-shadow: none;
}

.limit-butt {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 10px;
}

.limit-butt li .btn.btn-primary {
  min-width: 228px;
  font-size: 16px;
  font-weight: 500;
  color: white;
}

.limit-butt li .btn.btn-primary:focus {
  box-shadow: none;
}

.form-box {
  margin-bottom: 35px;
}

#green {
  background-color: var(--green);
  border-color: var(--green);
}

#red {
  background-color: var(--red);
  border-color: var(--red);
}

.btn-close {
  position: absolute;
  top: -10px;
  right: 0;
  border: 2px solid white;
  width: 25px;
  height: 25px;
  opacity: 1;
  border-radius: 35px;
  background: none;
}

.btn-close:focus {
  box-shadow: none;
}

section.table-sec {
  background-color: hsla(0, 0%, 100%, 0.11);

}

.table>:not(caption)>*>* {
  color: white;
}

@media all and (min-width:768px) and (max-width: 991px) {
  .pagenation-inner {
    gap: 20px;
  }

  .pagelist {
    gap: 20px;
  }

}

@media all and (min-width: 320px) and (max-width: 767px) {
  .table thead tr th {
    padding: 15px 10px;
    font-size: 14px;
  }

  .table tbody tr td {
    font-size: 13px;
    min-width: 150px;
  }

  .entry-box {
    margin-bottom: 15px;
    justify-content: center;
  }

  .pages-entries-text {
    justify-content: center;
    margin-bottom: 10px;
  }

  .pagenation-inner {
    justify-content: center;
    gap: 9px;
  }

  .pagelist {
    gap: 20px;
  }

  .pagelist li.active {
    width: 25px;
    height: 25px;
  }

  .limit-butt li .btn.btn-primary {
    min-width: 125px;
  }
}

/* tabel css end here */

.menubar {

  margin-top: 105px;
}

/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgb(238, 190, 118);
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.table-responsive{
  white-space: no-wrap;
}

.set_word_break{
    word-break: break-all;
}

a.navbar-brand {
    color: white;
    font-size: 23px;
}
span.ft-logo {
    color: white;
    font-size: 23px;
}
span.logo_text {
    color: white;
    font-size: 43px;
    /* width: inherit; */
    width: 183px;
    display: block;
    text-align: center;
}
</style>
