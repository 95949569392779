<template>
<div class="top_header">
<div class="toggle_a">
  <i class="fa-light fa-bars " @click="check()"></i>
  <i class="fa-light fa-xmark " @click="check()"></i>
</div>
<div class="user_detail">
  <div class="dropdown">
  <button class="set_dropdown dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
   {{this.$store.state.user.username}}
  </button>
  <ul class="dropdown-menu">
    <li><button class="dropdown-item" @click="logout">LogOut</button></li>
  </ul>
</div>
</div>

</div>
</template>
<script>
export default {
  name: "HomeDashboard",
  data() {
    return {
      flag: true,
      user:''
    }
  },
  methods: {
    check() {
      this.flag ? this.$emit('send', { activeClass: 'sidebar active', mainWrapperClass : 'active main_wrapper' , loaderclass: 'left_loader'}) : 
      this.$emit('send', {activeClass: 'sidebar', mainWrapperClass : 'main_wrapper' ,loaderclass: ''});
      this.flag = !this.flag;
    },
    logout(){
      localStorage.removeItem('token');
      this.$store.commit('updateUserDetails');
      this.$store.commit('updateLoginStatus',false);
      this.$router.push({name:'Login'})
    }
  },
  mounted() {}
}
</script>
<style scoped>
button.set_dropdown {
    background-color: #FCAB35;
    outline: none;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
}
.toggle_a i {
    font-size: 30px;
    padding: 6px 5px;
    color: white;
}
.top_header {
  display: flex;
    justify-content: space-between;
    position: fixed;
    background-color: #1b1c1e;
    width: calc(100% - 210px);
    top: 0;
    left: 209px;
    padding: 10px 10px;
    z-index: 9;
    transition: .5s ease-in-out;
    border-bottom: 1px solid #ffffff38;
}
</style>
