<template>
  <LoderInfo :load="load"></LoderInfo>
<SideBar :activemode="activeClass"></SideBar>

<div :class="main_wrapper">
  <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
  <div class="main_wrapper_b">
    <section class="bread_cum">
      <div class="container-fluid">
        <div class="row justify-content-end">

          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/dashboard"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                </li>
                <li class="breadcrumb-item text-white"> <i class="fa-light fa-credit-card me-1"></i>Withdraw</li>

              </ol>
            </nav>
          </div>
        </div>

      </div>
    </section>

    <!-- <section class="pb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="income_form">
              <form action="">
                <div class="text-center my-3">
                  <h3 class="text-white">Withdrawl Request</h3>
                </div>
                <div class="mb-3 d-flex justify-content-around">
                  <div>
                    <label for="startdate" class="form-label text-white d-block">Start Date :</label>
                    <input type="date" id="startdate">
                  </div>
                  <div>
                    <label for="endtdate" class="form-label text-white d-block">End Date :</label>
                    <input type="date" id="endtdate">
                  </div>
                </div>

                <div class="mb-3 text-center">
                  <button class="button_a">Export</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <section class="table-sec m-lg-3 rounded-2">

      <div class="container-fluid">
        <div class="row justify-content-center bg-light py-2 set_radius">
          <div class="col-lg-6 text-center ">
            <HeadingTitle title="WithDrawl Request"></HeadingTitle>
          </div>
        </div>
      </div>
      <div class="container-fluid p-4">
        <div class="row enteries-row align-items-center mb-5">
          <div class="col-xl-4 col-lg-4 col-md-6">
            <div class="entry-box ">
              <h5>Show</h5>
              <span>
              <select class="select" v-model="limit" v-on:change="getMoreResults">
              <option
                v-for="(option, index) in options"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </select>
          </span>

              <h6>enteries</h6>
            </div>
            <!--entry-box-->
          </div>

          <!--col-xl-6 col-lg-6 col-md-6-->

<!--          <div class="col-xl-4 col-lg-4 col-md-6">-->
<!--            <div class="search-box">-->
<!--              <div class="input-group">-->
<!--                <span class="input-group-text" id="basic-addon1"><img src="../../assets/images/search.svg" alt="" class="img-fluid"></span>-->
<!--                <input type="text" class="form-control" placeholder="Search....." aria-label="Username" aria-describedby="basic-addon1">-->
<!--              </div>-->
<!--            </div>-->
            <!--search-box-->
<!--          </div>-->
          <!--col-xl-6 col-lg-6 col-md-6-->
          <div class="col-xl-4 col-lg-4 text-center text-lg-end my-2">
            <button @click.once="transfer" :key="key" class="approved">Approve</button>
          </div>
          
          <div class="col-xl-4 col-lg-4 text-center text-lg-end my-2">
            <button @click="exportRequests" class="approved">Download Excel Report</button>
          </div>
          
        </div>
        <!--row enteries-row-->

        <div class="row table-row">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="table-responsive">
              <table class="table table-bordered ">
                <thead>
                  <tr>

                    <th scope="col" class="fw-bold"><input v-model="select_all" type="checkbox"></th>
                    <th scope="col" class="fw-bold" style="cursor:pointer;" @click="sortBy('created_at')">Date <span> <img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span> </th>
                    <th scope="col" class="fw-bold">Username </th>
                    <th scope="col" class="fw-bold">Wallet Address </th>
                    <th scope="col" class="fw-bold">Amount In USD</th>
                    <th scope="col" class="fw-bold">Admin Charge</th>
                    <th scope="col" class="fw-bold">Final Amount IN USD</th>
                    <th scope="col" class="fw-bold">Total Tokens</th>
                    <th scope="col" class="fw-bold">Currency</th>
                    <th scope="col" class="fw-bold" style="cursor:pointer;" @click="sortBy('status')">Status <span> <img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid" ></span></th>

                  </tr>
                </thead>
                <tbody v-if="withdrawals.length">
                <tr v-for="(withdraw, index) in withdrawals" :key="index">
                  <td v-if="withdraw.status == 0">  <input class="withdraw" @click="setUnsetIds(withdraw.id,$event.target)" :value="withdraw.id" type="checkbox"></td>
                  <td v-else></td>
                  <td>{{dateFormate(withdraw.created_at)}}</td>
                  <td>{{withdraw.username}}</td>
                  <td>{{withdraw.account_token}}</td>
                  <td>{{withdraw.requested_amount}}</td>
                  <td>{{withdraw.admin_charge}}</td>
                  <td>{{withdraw.approved_amount}}</td>
                  <td>{{withdraw.amount_in_token}}</td>
                  <td>{{withdraw.currency}}</td>
                  <td v-if="withdraw.status == 0"><span class="badge bg-warning">Pending</span></td>
                  <td v-else><span class="badge bg-success">Approved</span></td>
                </tr>
                </tbody>
                <tbody v-else>
                <tr>
                  <td colspan="12">No Data Available></td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--table-responsive-->
          </div>
          <!--col-xl-12 col-lg-12 col-md-12-->
        </div>
        <!--row table-row-->

        <div class="row pagination-row align-items-center" v-if="withdrawals.length">
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pages-entries-text">
                <h5>showing</h5>
                <span
                  >{{ 1 + limit * currentPage - limit }} to
                  {{ limit * currentPage - limit + withdrawals.length }} of
                  {{ total }}</span
                >
                <h6>entries</h6>
              </div>
              <!--pages-entries-text-->
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6">
              <div class="pagenation-inner">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a
                        :class="currentPage > 1 ? 'page-link' : 'page-link disabled'"
                        href="#"
                        aria-label="Previous"
                        @click="getWithdrawalDetails(currentPage - 1)"
                      >
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>

                    <li
                      class="page-item"
                      v-for="index in totalPages"
                      :key="index"
                      @click="getWithdrawalDetails(index)"
                    >
                      <a
                        :class="index == currentPage ? 'page-link active' : 'page-link'"
                        href="#"
                      >
                        {{ index }}
                      </a>
                    </li>

                    <li class="page-item">
                      <a
                        :class="
                          currentPage < totalPages ? 'page-link' : 'page-link disabled'
                        "
                        href="#"
                        aria-label="Next"
                        @click="getWithdrawalDetails(currentPage + 1)"
                      >
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <!--pagenation-inner-->
            </div>
            <!--col-xl-6 col-lg-6 col-md-6--->
          </div>
        <!--row pagination-row-->

        <!--container-fluid-->
      </div>

    </section>
  </div>
  <FooterDetail></FooterDetail>
</div>
</template>

  
<script>
import Web3 from "web3";
import transfer from "@/assets/js/transfer";
import HeadingTitle from "@/components/HeadingTitle.vue";
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";
import LoderInfo from "@/components/LoderInfo.vue";
export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    HeadingTitle,
    FooterDetail,
    LoderInfo
  },
  data() {
    return {
      activeClass: 'sidebar',
      main_wrapper: 'main_wrapper',
      withdrawals:[],
      sortedWithdrawals:[],
      totalPages: 0,
      currentPage: 1,
      total: 0,
      limit: 25,
      order_by:'status',
      order : 'asc',
      options: [
        { label: "25", value: 25 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
      ],
      select_all:false,
      approved_ids:[],
      key:1,
      load:false
    }
  },
  watch:{
    select_all(){
      let all_elements = document.getElementsByClassName('withdraw')
      if (this.select_all){
        this.approved_ids = [];
        for (const element of all_elements) {
          this.approved_ids.push(element.value)
          element.setAttribute("checked", "checked");
        }
      }else {
        this.approved_ids = [];
        for (const element of all_elements) {
          element.removeAttribute('checked')
        }
      }
    }
  },
  methods: {
    dateFormate(date){
      let cur_date = new Date(date);
      cur_date.setTime(cur_date.getTime() + 19800000)
      cur_date = cur_date.toISOString()
      return cur_date.substr(0, (cur_date.length - 5)).replace('T', ' ');
    },
    changeActiveMode(payload) {
      console.log(payload);
      this.activeClass = payload.activeClass
        this.main_wrapper = payload.mainWrapperClass
    },
    getMoreResults() {
      let page = 1;
      this.getWithdrawalDetails(page, parseInt(this.limit));
    },
    sortBy(field){
      if (this.order_by === field){

        this.order = this.order === 'asc' ? 'desc' : 'asc';
      }else {
        this.order_by = field
        this.order = 'asc'
      }
      this.getWithdrawalDetails(this.currentPage)
    },
    async getWithdrawalDetails(page = 1) {
      this.$axios.get('/getWithdrawalRequest',{params:{page,limit:parseInt(this.limit),order_by:this.order_by,order:this.order}}).then(result => {
        this.withdrawals = result.data.withdrawals;
        this.totalPages = result.data.totalPages;
        this.total = result.data.count;
        this.currentPage = parseInt(result.data.currentPage);
      });
    },
    async exportRequests() {
      this.$axios.get("downloadWithdrawal").then((result) => {
          if(result.data.status == "success"){
            this.$swal({icon:'success',text:result.data.message });
            window.open(`${this.$axios.defaults.baseURL}/withdrawals.xlsx`, '_blank');
          }else{
            this.$swal({icon:'error',text:result.data.message});
          }
      });
    },
    setUnsetIds(value,element){
      if (element.checked){
        this.approved_ids.push(value)
      }else {
        this.approved_ids.splice(this.approved_ids.indexOf(value),1)
      }
    },
    async transfer(){
      if (this.approved_ids.length){
        try {
          let webb = new Web3(window.ethereum)
          let net_id = await webb.eth.net.getId()
          if (net_id === 56){
            this.load = true
            let approved_details = await this.$axios.post('/verifyApprovedWithdrawals',{listOfIds:JSON.stringify(this.approved_ids)})
            approved_details = approved_details.data
            const trx_hash = await transfer(approved_details,webb)
            this.$axios.post('/updateWithdrawalStatus',{listOfIds:JSON.stringify(this.approved_ids),trx_hash}).then(async res => {
              if (res.data[0]){
                this.load = false
                await this.$swal({icon:'success',text:'Withdrawal done Successfully'})
                this.getWithdrawalDetails();
              }else {
                this.load = false
                this.$swal({icon:'error',text:'Something Went Wrong'})
                this.getWithdrawalDetails();
              }
              this.approved_ids = []
              this.key++
            })
          }else {
            this.load = false
            this.$swal({icon:'error',text:'Please change your matamask network to Mainnet'})
            this.key++
          }
        }catch (error) {
          let err = typeof error === 'object' ? error.message : error;
          this.load = false;
          this.$swal({icon:'error',text:err})
          this.key++
        }

      }else {
        this.$swal({icon:'info',text:'Nothing selected'})
      }

    }
  },
  beforeMount() {
    this.getWithdrawalDetails();
  }

}
</script>

  
<style scoped>
.income_form {
  background-color: #282828;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: #f8f9fa 0px 0px 2px;
}

.box_a {
  background-size: 100% 100%;
  padding: 20px 25px;
  transition: .5s ease-in-out;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dash-icon.text-orange {
  background: rgba(236, 84, 68, 0.2);
}

.text-orange {
  color: #f86300 !important;
}

.dash-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 10px auto;
  line-height: 43px;
  position: relative;
  background: #e3e6f9;
  z-index: 1;
  text-align: center;
}

.dash-icon i {
  text-align: center;
  vertical-align: middle;
}

.fs-22 {
  font-size: 22px !important;
}

.dash-icon.text-orange:after {
  background: rgba(236, 84, 68, 0.1);
}

.dash-icon:after {
  position: absolute;
  content: "";
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: 0;
  top: -10px;
  right: 0;
  left: -10px;
  background: rgba(235, 237, 252, 0.4);
  z-index: -1;
}

.dash-icon.text-secondary1 {
  background: rgba(36, 228, 172, 0.2);
}

.text-secondary1 {
  color: #24e4ac !important;
}

.dash-icon.text-secondary1:after {
  background: rgba(36, 228, 172, 0.1);
}

.text-secondary {
  color: #9c52fd !important;
}

.dash-icon.text-secondary:after {
  background: rgb(156 82 253 / 27%);
}

.card {
  background-color: #282828 !important;
  box-shadow: 0px 0px 2px 0px rgb(255 255 255);
}
input#startdate,input#endtdate {
    padding: 5px 10px;
    border-radius: 5px;
    border-color: transparent;
}
.approved {
    outline: none;
    background-color: #28a745;
    color: white;
    padding: 6px 15px;
    border-radius: 5px;
}
</style>
