<template>
  <SideBar :activemode="activeClass"></SideBar>
  
  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum">
      <div class="container-fluid">
        <div class="row justify-content-end">

          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/Home"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                </li>
                <li class="breadcrumb-item text-white"> <i class="fa-regular fa-trophy me-1"></i> Rewards</li>

              </ol>
            </nav>
          </div>
        </div>

      </div>
    </section>




    <section class="pb-5">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-xxl-3 col-md-6">
              <div class="card my-2 py-2">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="col">
                      <p class="mb-2 text-white">Total Business</p>
                      <h3 class="mb-0 number-font text-white"> ${{total_business}} </h3>
                    </div>
                    <div class="col-auto mb-0">
                      <div class="dash-icon text-orange"><i class="fa-solid fa-briefcase"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-md-6">
              <div class="card my-2 py-2">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="col">
                      <p class="mb-2 text-white">Power Leg</p>
                      <h3 class="mb-0 number-font text-white">  ${{power_leg}} </h3>
                    </div>
                    <div class="col-auto mb-0">
                      <div class="dash-icon text-secondary1"><i class="fa-solid fa-user-plus"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-md-6">
              <div class="card my-2 py-2">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="col">
                      <p class="mb-2 text-white">Other Leg </p>
                      <h3 class="mb-0 number-font text-white">  ${{other_leg}} </h3>
                    </div>
                    <div class="col-auto mb-0">
                      <div class="dash-icon text-secondary"><i class="fa-solid fa-user-tie"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row justify-content-center">
            <div class="col-xxl-3 col-md-6">
              <div class="card my-2 py-2">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="col">
                      <p class="mb-2 text-white">Current Rank</p>
                      <h3 class="mb-0 number-font text-white"> {{current_rank}} </h3>
                    </div>
                    <div class="col-auto mb-0">
         
                      <div class="dash-icon text-secondary-a"><i class="fa-regular fa-award"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-3 col-md-6">
              <div class="card my-2 py-2">
                <div class="card-body">
                  <div class="row mb-1">
                    <div class="col">
                      <p class="mb-2 text-white">Next Rank</p>
                      <h3 class="mb-0 number-font text-white"> {{next_rank}} </h3>
                    </div>
                    <div class="col-auto mb-0">
                      <div class="dash-icon text-secondary-ab"><i class="fa-solid fa-crown"></i></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>








    <section class="m-lg-3">
  <section class="table-sec  rounded-2 mb-5 p-">

<div class="container-fluid ">
  <div class="row justify-content-center bg-light py-2 set_radius">
    <div class="col-lg-6 text-center ">
      <HeadingTitle title="Rank And Reward"></HeadingTitle>
    </div>
  </div>
</div>
<div class="container-fluid p-4 ">
  <div class="row enteries-row align-items-center mb-5">
    <div class="col-xl-6 col-lg-6 col-md-6">
      <!-- <div class="entry-box ">
        <h5>Show</h5>
        <span>
        <select class="select">
            <option value="0">10</option>
            <option value="0">20</option>
            <option value="0">30</option>
            <option value="0">40</option>
            <option value="0">50</option>
          </select>
          </span>

        <h6>enteries</h6>
      </div> -->
    </div>


    <!-- <div class="col-xl-6 col-lg-6 col-md-6">
      <div class="search-box">
        <div class="input-group">
          <span class="input-group-text" id="basic-addon1"><img src="../assets/images/search.svg" alt="" class="img-fluid"></span>
          <input type="text" class="form-control" placeholder="Search....." aria-label="Username" aria-describedby="basic-addon1">
        </div>
      </div>
    </div> -->
  </div>
  <!--row enteries-row-->

  <div class="row table-row">
    <div class="col-xl-12 col-lg-12 col-md-12">
      <div class="table-responsive">
        <table class="table table-bordered ">
          <thead>
            <tr>

              <th scope="col" class="fw-bold">Rank Achieved</th>
              <th scope="col" class="fw-bold">Team Matching Business </th>
              <th scope="col" class="fw-bold">Rewards </th>
              <th scope="col" class="fw-bold">Status </th>
              

            </tr>
          </thead>
          <tbody v-if="ranks.length">
            <tr v-for="(rank, index) in ranks" :key="index">
              <td>{{ rank.name }}</td>
              <td>${{ rank.team_matching_business }}</td>
              <td>{{ rank.reward }}</td>
              <td>
               <span :class="this.$store.state.user.rank >= rank.id ? 'badge bg-success': 'badge bg-danger'">{{ this.$store.state.user.rank >= rank.id ? 'Achieved': 'Not Achieved'}} </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="4">No Data Available</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--table-responsive-->
    </div>
    <!--col-xl-12 col-lg-12 col-md-12-->
  </div>
  <!--row table-row-->

  <!-- <div class="row pagination-row align-items-center">
    <div class="col-xl-6 col-lg-6 col-md-6">
      <div class="pages-entries-text">
        <h5>showing</h5>
        <span>1 to 10 of 57</span>
        <h6>entries</h6>
      </div>
    </div>

    <div class="col-xl-6 col-lg-6 col-md-6">
      <div class="pagenation-inner">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item"><a class="page-link active" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div> -->
  <!--row pagination-row-->

  <!--container-fluid-->
</div>

</section>
</section>
  
    </div>
  
  </div>
  </template>
  
  <script>
  import SideBar from "@/components/SideBar.vue";
  import HeaderDashboard from "@/components/HeaderDashboard.vue";
  import HeadingTitle from "@/components/HeadingTitle.vue";
  export default {
    name: "HomeDashboard",
    // props: ['main_wrapper'],
    components: {
      SideBar,
      HeaderDashboard,
      HeadingTitle
    },
    data() {
      return {
        activeClass: 'sidebar',
        main_wrapper: 'main_wrapper',
        ranks:[],
        current_rank:'Not Achieved',
        next_rank:'Achieved All',
        total_business:0,
        power_leg:0,
        other_leg:0
      }
    },
    methods: {
      changeActiveMode(payload) {
        this.activeClass = payload.activeClass
          this.main_wrapper = payload.mainWrapperClass
      },
      async getResults() {
        this.$axios.get("getRankDetails").then((result) => {
          this.ranks = result.data.ranks;
          this.total_business = result.data.total_business
          this.power_leg = result.data.power_leg
          this.other_leg = result.data.other_leg
          if (this.$store.state.user.rank){
            this.current_rank = this.ranks[this.$store.state.user.rank - 1]['name']
          }
          if (this.$store.state.user.rank < 10){
            this.next_rank = this.ranks[this.$store.state.user.rank]['name']
          }
        });
      }
    },
    beforeMount() {
      this.getResults();
    }
  };
  </script>
  
<style scoped>

.box_a {
  background-size: 100% 100%;
  padding: 20px 25px;
  transition: .5s ease-in-out;
  min-height: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dash-icon.text-orange {
  background: rgba(236, 84, 68, 0.2);
}

.text-orange {
  color: #f86300 !important;
}

.dash-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 10px auto;
  line-height: 43px;
  position: relative;
  background: #e3e6f9;
  z-index: 1;
  text-align: center;
}

.dash-icon i {
  text-align: center;
  vertical-align: middle;
}

.fs-22 {
  font-size: 22px !important;
}

.dash-icon.text-orange:after {
  background: rgba(236, 84, 68, 0.1);
}

.dash-icon:after {
  position: absolute;
  content: "";
  width: 65px;
  height: 65px;
  border-radius: 50%;
  bottom: 0;
  top: -10px;
  right: 0;
  left: -10px;
  background: rgba(235, 237, 252, 0.4);
  z-index: -1;
}

.dash-icon.text-secondary1 {
  background: rgba(36, 228, 172, 0.2);
}

.text-secondary1 {
  color: #24e4ac !important;
}

.dash-icon.text-secondary1:after {
  background: rgba(36, 228, 172, 0.1);
}

.text-secondary {
  color: #9c52fd !important;
}

.dash-icon.text-secondary:after {
  background: rgb(156 82 253 / 27%);
}

.card {
  background-color: #282828 !important;
  box-shadow: 0px 0px 2px 0px rgb(255 255 255);
}
.text-secondary {
  color: #9c52fd !important;
}

.text-secondary-a{
color: #FFD596;
}
.dash-icon.text-secondary-a:after {
  background: #ffd59640;
}
.dash-icon.text-secondary-a {
    background-color: #5e5444;
}

.text-secondary-ab{
color: #8FA7F5 ;
}
.dash-icon.text-secondary-ab:after {
  background: #8fa7f559;
}
.dash-icon.text-secondary-ab {
  background-color: #4c5470;
}



</style>
  