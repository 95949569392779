<template>

<div id="cover" :class="activemode" v-if="load">
<div class="set_loder_position">
    <span class="loader">
            <span class="loader-inner"></span>
        </span>
        <span class="text-white set_text_loder">Loading Please Wait...</span>
</div>
    
    </div>





</template>

<script>
export default {
  name: "LoderWait",
  props: ['activemode' , 'load'],
}
</script>


<style scoped>
div#cover {
    height: calc(100vh - 65px);
    background-color: #000000d9;
    position: fixed;
    z-index: 9999;
    right: 0;
    width: calc(100% - 210px);
    bottom: 0;
}

.loader {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: absolute;
  z-index:3;
  border: 4px solid #Fff;
top: 46%;
  animation: loader 2s infinite ease;
  right: 0;
  left: 0;
  margin: 0 auto;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #fff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  
  25% {
    transform: rotate(180deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  75% {
    transform: rotate(360deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }
  
  25% {
    height: 0%;
  }
  
  50% {
    height: 100%;
  }
  
  75% {
    height: 100%;
  }
  
  100% {
    height: 0%;
  }
}

 .set_text_loder{
  position: absolute;
    top: 51%;
  font-size: 27px;
  left: 0;
  right: 0;
  text-align: center;
}
.left_loader{
  width: calc(100% - 81px) !important;
}
</style>