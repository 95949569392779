<template>
  <SideBar :activemode="activeClass"></SideBar>
  
  <div :class="main_wrapper">
    <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
    <div class="main_wrapper_b">
      <section class="bread_cum">
        <div class="container-fluid">
          <div class="row justify-content-end">
  
            <div class="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/dashboard"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                  </li>
                  <li class="breadcrumb-item text-white"><i class="fa-light fa-comments-dollar me-1"></i>Level Income</li>
  
                </ol>
              </nav>
            </div>
          </div>
  
        </div>
      </section>
  
  
      <section class="table-sec m-lg-3 rounded-2">
      
           
  
  <div class="container-fluid">
    <div class="row justify-content-center bg-light py-2 set_radius">
              <div class="col-lg-6 text-center">
                <HeadingTitle title="Income Detail"></HeadingTitle>
              </div>
            </div>
  </div>
  <div class="container-fluid p-4">
    <div class="row enteries-row align-items-center mb-5">
              <div class="col-xl-2 col-lg-5 col-md-6">
                <div class="entry-box ">
                  <h5>Show</h5>
                  <span><select class="select">
                      <option value="0">10</option>
                      <option value="0">20</option>
                      <option value="0">30</option>
                      <option value="0">40</option>
                      <option value="0">50</option>
                    </select></span>
    
                  <h6>enteries</h6>
                </div>
                <!--entry-box-->
              </div>
              <div class="col-xl-2 col-lg-4">
                <div class="input-group my-3">
                      <select class="form-select" id="inputGroupSelect01">
                        <option selected>Choose Level</option>
                        <option value="1">Level 1</option>
                        <option value="2">Level 2</option>
                        <option value="3">Level 3</option>
                      </select>
                    </div>
              </div>
              <!--col-xl-6 col-lg-6 col-md-6-->
    
              <div class="col-xl-7 col-lg-3 col-md-6">
                <div class="search-box">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1"><img src="../../assets/images/search.svg" alt="" class="img-fluid"></span>
                    <input type="text" class="form-control" placeholder="Search....." aria-label="Username" aria-describedby="basic-addon1">
                  </div>
                </div>
                <!--search-box-->
              </div>
              <!--col-xl-6 col-lg-6 col-md-6-->
            </div>
            <!--row enteries-row-->
    
            <div class="row table-row">
              <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="table-responsive">
                  <table class="table table-bordered ">
                    <thead>
                      <tr>
    
                        <th scope="col" class="fw-bold">S.NO <span><img src="../../assets/images/table_icon_1.png" alt="" class="img-fluid"></span></th>
                        <th scope="col" class="fw-bold">User Name <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                        <th scope="col" class="fw-bold">Wallet Address <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
                        <th scope="col" class="fw-bold">Action <span><img src="../../assets/images/table_icon_2.png" alt="" class="img-fluid"></span></th>
    
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--table-responsive-->
              </div>
              <!--col-xl-12 col-lg-12 col-md-12-->
            </div>
            <!--row table-row-->
    
            <div class="row pagination-row align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6">
                <div class="pages-entries-text">
                  <h5>showing</h5>
                  <span>1 to 10 of 57</span>
                  <h6>entries</h6>
                </div>
                <!--pages-entries-text-->
              </div>
              <!--col-xl-6 col-lg-6 col-md-6-->
    
              <div class="col-xl-6 col-lg-6 col-md-6">
                <div class="pagenation-inner">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item"><a class="page-link active" href="#">1</a></li>
                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <!--pagenation-inner-->
              </div>
              <!--col-xl-6 col-lg-6 col-md-6--->
            </div>
            <!--row pagination-row-->
         
          <!--container-fluid-->
  </div>
  
          
        </section>
    </div>
    <FooterDetail></FooterDetail>
  </div>
  </template>
  
    
  <script>
  import HeadingTitle from "@/components/HeadingTitle.vue";
  import SideBar from "@/components/SideBar.vue";
  import HeaderDashboard from "@/components/HeaderDashboard.vue";
  import FooterDetail from "@/components/FooterDetail.vue";
  export default {
    name: "HomeDashboard",
    // props: ['main_wrapper'],
    components: {
      SideBar,
      HeaderDashboard,
      HeadingTitle,
      FooterDetail
    },
    data() {
      return {
        activeClass: 'sidebar',
        main_wrapper: 'main_wrapper',
      }
    },
    methods: {
      changeActiveMode(payload) {
        console.log(payload);
        this.activeClass = payload.activeClass,
          this.main_wrapper = payload.mainWrapperClass
      }
    }
  }
  </script>
  
  <style scoped>
  .income_form {
    background-color: #282828;
    padding: 20px 30px;
    border-radius: 5px;
    box-shadow: #f8f9fa 0px 0px 2px;
  }
  select#inputGroupSelect01 {
      background-color: #282828;
      color: white;
      border: 1px solid #e9ecef52;
      background-image: url('../../assets/images/down_arrow.svg') !important;
  }
  
  </style>
  