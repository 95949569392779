<template>
<LoderInfo :load="load"></LoderInfo>
<section class="login_detail">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">

        <div class="login_a text-center">
          <div class="logo_login  mb-4">
            <!-- <img src="../assets/images/logo_text.png" alt="" class="img-fluid zoom"> -->
            <span class="logo_a">LOGO</span>
          </div>

          <div class="mb-3 position-relative">
            <label for="example" class="form-label text-white">Wallet Address</label>
            <input id="example" :value="account_token" ref="message" class="form-control" readonly placeholder="Click Connect to get your Wallet Address" aria-label="Recipient's username" aria-describedby="basic-addon2" />

            <button @click="connectToWallet" type="button" class="input-group-text" id="basic-addon2">Connect</button>
          </div>

          <div class="mb-3">
            <label for="disabledTextInput" class="form-label text-white">Joining Amount</label>
            <input type="text" id="disabledTextInput" class="form-control" value="$50" readonly>
          </div>

          <div class=" mb-3">
            <label for="" class="form-label text-white">Select Currency</label>
            <select class="form-select" v-model="currency" aria-label="Default select example">
              <!-- <option value="busd" v-for="currency in options">BUSD</option> -->
             <option value="" selected > Select Currency</option>
              <option v-for="(option, index) in currencyOptions" :key="index" :value="option.name">
                {{ (option.name).toUpperCase() }}
              </option>

            </select>
          </div>
          <div class="mb-3">
            <label for="refreelid" class="form-label text-white">Referral ID</label>
            <input v-model="referral_id" type="text" class="form-control" id="refreelid">

          </div>
          <div class="mb-3 form-check ">

            <input type="checkbox" v-model="terms" class="form-check-input" id="exampleCheck1">
            <label class="form-check-label text-white" for="exampleCheck1">
              <router-link to="/Terms" target="_blank">Term And Condition</router-link>
            </label>

          </div>
      
          <div class="form-floating mb-3">
            <p class="text-white">Disclaimer : Lorem Ipsum is simply dummy text of the printing.</p>

          </div>
          <div class="my-3 ">
            <button type="button" @click="registerUser" class="button_a">SignUp</button>
          </div>
          <div class="mb-3">
      <router-link to="/login" class="back_login">
          <p class="text-white">Already hava an account? Login here</p>
        </router-link>
    </div>
        </div>
      </div>
    </div>
  </div>

</section>
</template>

<script>
import Web3 from 'web3';
import transaction from "@/assets/js/transaction";
import LoderInfo from "@/components/LoderInfo.vue";

export default {
  components: {
    LoderInfo
  },
  data: () => ({
    account_token: '',
    currency:'',
    currencyOptions:'',
    referral_id:'root',
    terms:false,
    is_metamask:false,
    load: false,
    
  }),
  methods: {
    connectToWallet(){
      if (this.is_metamask){
        let web = new Web3(window.ethereum);
        web.eth.requestAccounts().then(accounts => {
          this.account_token = accounts[0];
        }).catch(error => {
          this.$swal({icon:'error',text:error.message})
        })
      }
    },
    async getActiveCurrencies(){
      this.$axios.get('getcurrencies').then(result => {
        this.currencyOptions = result.data.currencies;
      });
    },
    async registerUser(){
      try {
        let token_price = await this.validateForm();
        this.load = true;

        let amount_in_token = this.currency === 'busd' ? 50 : (50 / token_price).toFixed(8);
        let web = new Web3(window.ethereum);
         let trx_hash = await transaction(web,this.currency,this.account_token,amount_in_token);
        let user_register =  await this.$axios.post('register',{
          account_token:this.account_token,
          currency:this.currency,
          amount_in_token:amount_in_token,
          amount_in_usd:50,
          referral_id:this.referral_id,
          trx_hash
        })
        if (user_register.data[0]){
          this.$swal({icon:'success',text:user_register.data[1]}).then(() => {
            this.$router.push({name:'Login'});
          })
        }else {
          this.$swal({icon:'error',text:user_register.data[1]})
        }
        this.load = false;

      }catch (error) {
        let err = typeof error === 'object' ? error.message : error;
        this.$swal({icon:'error',text:err})
        this.load = false;

      }

    },

     validateForm(){
      return new Promise((resolve,reject) => {
        if (this.account_token.trim() !== ''){
          if (['busd','slt'].includes(this.currency)){
            if (this.terms){
              if (this.referral_id.trim() !== ''){
                // check referral and get current token price
                this.$axios.get('checkReferralAndUser/',{
                  params:{referral:this.referral_id,account_token:this.account_token}
                }).then(check_and_get => {
                  if (check_and_get.data[0]){
                    resolve(check_and_get.data[1]);
                  }
                  reject(check_and_get.data[1]);
                });
              }else {
                reject('Referral ID required')
              }
            }else {
              reject('Please agree to terms and conditions')
            }
          }else {
            reject('Invalid Currency')
          }
        }else {
          reject('Please provide valid wallet Address')
        }
      })
    }
  },
  mounted() {
    if (localStorage.getItem('token')){
      this.$router.push({name:'Dashboard'})
    }else {
      if (this.$route.query.referral){
        this.referral_id = this.$route.query.referral
      }
    }
    // axios.defaults.baseURL = this.$http;
    if (window.ethereum){
      this.is_metamask = true;
    }else {
      this.$swal('Please install MetaMask Extension First')
    }
  },
  beforeMount(){
    this.getActiveCurrencies();
  }
}
</script>

<style scoped>
.login_detail {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url(../assets/images/bg_login.jpg); */
  background-size: 100% 100%;
  background: linear-gradient(55deg, rgba(0, 0, 0, 1) 40%, rgba(56, 40, 16, 1) 100%);
}

.login_a {
  background-color: #000000bd;
  padding: 40px 30px;
  border-radius: 5px;
  box-shadow: 1px 5px 24px 0 #f8f9fa3d;
}

input#myInput {
  background-color: transparent !important;
}

button#success-alert {
  border: none;
  color: white;
  padding: 6px 20px;
  min-width: 100px;
  background-color: gray;
}

button#success-alert:active {
  font-size: 0;
}

button#success-alert:active::after {
  content: "Copied";
  color: #fff;
  font-size: 14px;
}

.input-group-text {
  color: white !important;
  background-color: #fcab35 !important;
  border: 1px solid #fcab35 !important;

}

input#example {
  background-color: white !important;
  color: #212529;
}

label {
  display: flex;
}

button#basic-addon2 {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 54%;
}
.back_login p {
    display: flex;
    justify-content: center;
    color: #fcab35 !important;
}
.back_login p:hover {
    text-decoration: underline;
}
span.logo_a {
    color: white;
    font-size: 22px;
}
</style>
