<template>
<SideBar :activemode="activeClass"></SideBar>

<div :class="main_wrapper">
  <HeaderDashboard @send="changeActiveMode"></HeaderDashboard>
  <div class="main_wrapper_b">
    <section class="bread_cum">
      <div class="container-fluid">
        <div class="row justify-content-end">

          <div class="col-lg-6">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/Home"><i class="fa-thin fa-grid me-1"></i> Dashboard</router-link>
                </li>
                <li class="breadcrumb-item text-white"> <i class="fa-light fa-circle-user me-1"></i>Autopool Tree</li>

              </ol>
            </nav>
          </div>
        </div>

      </div>
    </section>
    <section class="tree_detail">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="main_div mt-4">
              <div class="card set_radius ">
                <h2 class="header-title text-center fs-2  text-dark">{{this.pool}} Tree View </h2>
                      <div class="row">
                    <div class="col-lg-2 col-md-4 ms-lg-3">
                    <select class="form-select" aria-label="Default select example" v-model="pool" v-on:change="getMoreResults">
                      <option value="Pool1">One</option>
                      <option value="Pool2">Two</option>
                      <option value="Pool3">Three</option>
                      <option value="Pool4">Four</option>
                      <option value="Pool5">Five</option>
                      <option value="Pool6">Six</option>
                      <option value="Pool7">Seven</option>
                    </select>
                    </div>
 
                    </div>
                <div class="card-body set_a_bg pb-5">

                  <div class="col-lg-8 tree ">
                    <div class="panel panel-default">
              
                      <div class="panel-wrapper">
                        <div>
                          <ul class="custom-tree-data">
                            <li>
                              <div class="text-center">
                                <div class="img-data-tree">
                                  <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level_one_childs[0] ? 'opacity:30%': ''">
                                </div>
                                <a href="#" class="">
                                  <span data-id="1" id="user_1" class="set_text text-white" > {{this.$store.state.user.username}}</span>
                                </a>
                              </div>
                              <ul>
                                <li class="left">
                                  <div class="text-center">
                                    <div class="img-data-tree">
                                      <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level_one_childs[0] ? 'opacity:30%': ''" >
                                    </div>
                                    <a href="#" class="">
                                      <span class="set_text text-white">{{ level_one_childs[0] ? level_one_childs[0].child : 'demo' }}</span>
                                    </a>
                                  </div>
                                  <ul >
                                    <li class="left">
                                      <div class="text-center">
                                        <div class="img-data-tree">
                                          <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level2_1leg[0] ? 'opacity:30%': ''" >
                                        </div>
                                        <a href="#" class="">
                                          <span class="set_text text-white">{{ level2_1leg[0] ? level2_1leg[0].child : 'demo' }}2</span>
                                        </a>
                                      </div>
                                      <ul >
                                        <li class="left">
                                          <div class="text-center">
                                            <div class="img-data-tree">
                                              <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_1leg_first_3[0] ? 'opacity:30%': ''" >
                                            </div>
                                            <a href="#" class="">
                                              <span class="set_text text-white">{{ level3_1leg_first_3[0] ? level3_1leg_first_3[0].child : 'demo' }}2</span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_1leg_first_3[1] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_1leg_first_3[1] ? level3_1leg_first_3[1].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_1leg_first_3[2] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_1leg_first_3[2] ? level3_1leg_first_3[2].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                      </ul>
                                    </li>
                                    <li class="left" >
                                      <div class="text-center">
                                        <div class="img-data-tree">
                                          <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level2_1leg[1] ? 'opacity:30%': ''" >
                                        </div>
                                        <a href="#" class="">
                                          <span class="set_text text-white">{{ level2_1leg[1] ? level2_1leg[1].child : 'demo' }}</span>
                                        </a>
                                      </div>
                                      <ul >
                                        <li class="left">
                                          <div class="text-center">
                                            <div class="img-data-tree">
                                              <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_1leg_mid_3[0] ? 'opacity:30%': ''" >
                                            </div>
                                            <a href="#" class="">
                                              <span class="set_text text-white">{{ level3_1leg_mid_3[0] ? level3_1leg_mid_3[0].child : 'demo' }}2</span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_1leg_mid_3[1] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_1leg_mid_3[1] ? level3_1leg_mid_3[1].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_1leg_mid_3[2] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_1leg_mid_3[2] ? level3_1leg_mid_3[2].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                      </ul>
                                    </li>
                                    <li class="left">
                                      <div class="text-center">
                                        <div class="img-data-tree">
                                          <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level2_1leg[2] ? 'opacity:30%': ''" >
                                        </div>
                                        <a href="#" class="">
                                          <span class="set_text text-white">{{ level2_1leg[2] ? level2_1leg[2].child : 'demo' }}</span>
                                        </a>
                                      </div>
                                      <ul >
                                        <li class="left">
                                          <div class="text-center">
                                            <div class="img-data-tree">
                                              <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_1leg_last_3[0] ? 'opacity:30%': ''" >
                                            </div>
                                            <a href="#" class="">
                                              <span class="set_text text-white">{{ level3_1leg_last_3[0] ? level3_1leg_last_3[0].child : 'demo' }}2</span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_1leg_last_3[1] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_1leg_last_3[1] ? level3_1leg_last_3[1].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_1leg_last_3[2] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_1leg_last_3[2] ? level3_1leg_last_3[2].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                                <li class="left" >
                                  <div class="text-center">
                                    <div class="img-data-tree">
                                      <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level_one_childs[1] ? 'opacity:30%': ''">
                                    </div>
                                    <a href="#" class="">
                                      <span class="set_text text-white">{{ level_one_childs[1] ? level_one_childs[1].child : 'demo' }}</span>
                                    </a>
                                  </div>
                                  <ul >
                                    <li class="left" >
                                      <div class="text-center">
                                        <div class="img-data-tree">
                                          <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level2_2leg[0] ? 'opacity:30%': ''" >
                                        </div>
                                        <a href="#" class="">
                                          <span class="set_text text-white">{{ level2_2leg[0] ? level2_2leg[0].child : 'demo' }}</span>
                                        </a>
                                      </div>
                                      <ul>
                                        <li class="left">
                                          <div class="text-center">
                                            <div class="img-data-tree">
                                              <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_2leg_first_3[0] ? 'opacity:30%': ''" >
                                            </div>
                                            <a href="#" class="">
                                              <span class="set_text text-white">{{ level3_2leg_first_3[0] ? level3_2leg_first_3[0].child : 'demo' }}2</span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_2leg_first_3[1] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_2leg_first_3[1] ? level3_2leg_first_3[1].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_2leg_first_3[2] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_2leg_first_3[2] ? level3_2leg_first_3[2].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                      </ul>
                                    </li>
                                 
                                    <li class="left">
                                      <div class="text-center">
                                        <div class="img-data-tree">
                                          <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level2_2leg[1] ? 'opacity:30%': ''" >
                                        </div>
                                        <a href="#" class="">
                                          <span class="set_text text-white">{{ level2_2leg[1] ? level2_2leg[1].child : 'demo' }}</span>
                                        </a>
                                      </div>
                                      <ul>
                                        <li class="left">
                                          <div class="text-center">
                                            <div class="img-data-tree">
                                              <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_2leg_mid_3[0] ? 'opacity:30%': ''" >
                                            </div>
                                            <a href="#" class="">
                                              <span class="set_text text-white">{{ level3_2leg_mid_3[0] ? level3_2leg_mid_3[0].child : 'demo' }}2</span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_2leg_mid_3[1] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_2leg_mid_3[1] ? level3_2leg_mid_3[1].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_2leg_mid_3[2] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_2leg_mid_3[2] ? level3_2leg_mid_3[2].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                      </ul>
                                    </li>
                                    <li class="left">
                                      <div class="text-center">
                                        <div class="img-data-tree">
                                          <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level2_2leg[2] ? 'opacity:30%': ''" >
                                        </div>
                                        <a href="#" class="">
                                          <span class="set_text text-white">{{ level2_2leg[2] ? level2_2leg[2].child : 'demo' }}</span>
                                        </a>
                                      </div>
                                      <ul>
                                        <li class="left">
                                          <div class="text-center">
                                            <div class="img-data-tree">
                                              <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_2leg_last_3[0] ? 'opacity:30%': ''" >
                                            </div>
                                            <a href="#" class="">
                                              <span class="set_text text-white">{{ level3_2leg_last_3[0] ? level3_2leg_last_3[0].child : 'demo' }}2</span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_2leg_last_3[1] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_2leg_last_3[1] ? level3_2leg_last_3[1].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_2leg_last_3[2] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_2leg_last_3[2] ? level3_2leg_last_3[2].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                                <li class="left" >
                                  <div class="text-center">
                                    <div class="img-data-tree">
                                      <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level_one_childs[2] ? 'opacity:30%': ''">
                                    </div>
                                    <a href="#" class="">
                                      <span class="set_text text-white">{{ level_one_childs[2] ? level_one_childs[2].child : 'demo' }}</span>
                                    </a>
                                  </div>
                                  <ul >
                                    <li class="left" >
                                      <div class="text-center">
                                        <div class="img-data-tree">
                                          <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level2_3leg[0] ? 'opacity:30%': ''" >
                                        </div>
                                        <a href="#" class="">
                                          <span class="set_text text-white">{{ level2_3leg[0] ? level2_3leg[0].child : 'demo' }}</span>
                                        </a>
                                      </div>
                                      <ul>
                                        <li class="left">
                                          <div class="text-center">
                                            <div class="img-data-tree">
                                              <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_3leg_first_3[0] ? 'opacity:30%': ''" >
                                            </div>
                                            <a href="#" class="">
                                              <span class="set_text text-white">{{ level3_3leg_first_3[0] ? level3_3leg_first_3[0].child : 'demo' }}2</span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_3leg_first_3[1] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_3leg_first_3[1] ? level3_3leg_first_3[1].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_3leg_first_3[2] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_3leg_first_3[2] ? level3_3leg_first_3[2].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                      </ul>
                                    </li>
                                    <li class="left">
                                      <div class="text-center">
                                        <div class="img-data-tree">
                                          <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level2_3leg[1] ? 'opacity:30%': ''" >
                                        </div>
                                        <a href="#" class="">
                                          <span class="set_text text-white">{{ level2_3leg[1] ? level2_3leg[1].child : 'demo' }}</span>
                                        </a>
                                      </div>
                                      <ul>
                                        <li class="left">
                                          <div class="text-center">
                                            <div class="img-data-tree">
                                              <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_3leg_mid_3[0] ? 'opacity:30%': ''" >
                                            </div>
                                            <a href="#" class="">
                                              <span class="set_text text-white">{{ level3_3leg_mid_3[0] ? level3_3leg_mid_3[0].child : 'demo' }}2</span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_3leg_mid_3[1] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_3leg_mid_3[1] ? level3_3leg_mid_3[1].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_3leg_mid_3[2] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_3leg_mid_3[2] ? level3_3leg_mid_3[2].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                          </li>
                                      </ul>
                                    </li>
                                    <li class="left">
                                      <div class="text-center">
                                        <div class="img-data-tree">
                                          <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level2_3leg[2] ? 'opacity:30%': ''" >
                                        </div>
                                        <a href="#" class="">
                                          <span class="set_text text-white">{{ level2_3leg[2] ? level2_3leg[2].child : 'demo' }}</span>
                                        </a>
                                      </div>
                                      <ul>
                                        <li class="left">
                                          <div class="text-center">
                                            <div class="img-data-tree">
                                              <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_3leg_last_3[0] ? 'opacity:30%': ''" >
                                            </div>
                                            <a href="#" class="">
                                              <span class="set_text text-white">{{ level3_3leg_last_3[0] ? level3_3leg_last_3[0].child : 'demo' }}2</span>
                                            </a>
                                          </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_3leg_last_3[1] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_3leg_last_3[1] ? level3_3leg_last_3[1].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                        </li>
                                        <li class="left">
                                            <div class="text-center">
                                              <div class="img-data-tree">
                                                <img src="../assets/images/usericon.png" alt="" class="img-fluid" :style="!level3_3leg_last_3[2] ? 'opacity:30%': ''" >
                                              </div>
                                              <a href="#" class="">
                                                <span class="set_text text-white">{{ level3_3leg_last_3[2] ? level3_3leg_last_3[2].child : 'demo' }}2</span>
                                              </a>
                                            </div>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </section>
  </div>
  <FooterDetail></FooterDetail>
</div>
</template>

<script>
import SideBar from "@/components/SideBar.vue";
import HeaderDashboard from "@/components/HeaderDashboard.vue";
import FooterDetail from "@/components/FooterDetail.vue";
export default {
  name: "HomeDashboard",
  // props: ['main_wrapper'],
  components: {
    SideBar,
    HeaderDashboard,
    FooterDetail
  },
  data() {
    return {
      activeClass: "sidebar",
      main_wrapper: "main_wrapper",
      data: [],
      pool: 'Pool1',
      level_one_childs: [],
      level_two_childs: [],
      level_two_childs_leg_two: [],
      level_two_childs_leg_three: [],
      level_three_childs_leg_one: [],
      level_three_childs_leg_two: [],
      level_three_childs_leg_three: [],
    };
  },
 methods: {
    changeActiveMode(payload) {
      console.log(payload);
      (this.activeClass = payload.activeClass),
        (this.main_wrapper = payload.mainWrapperClass);
    },
    async getMoreResults() {
      this.getResults(this.pool);
    },
    async getResults() {
      this.$axios.get("autopoolTree?pool="+this.pool).then((childs) => {
        this.data = childs.data;

        this.level_one_childs = this.data.filter(function (child) {
          return child.level == 1  ;
        });

        this.level_two_childs = this.data.filter(function (child) {
          return child.level == 2;
        });

        this.level_three_childs_leg_one = this.data.filter(function (child) {
          return child.level == 3 && child.leg == 1;
        });

        this.level_three_childs_leg_two = this.data.filter(function (child) {
          return child.level == 3 && child.leg == 2 ;
        });

        this.level_three_childs_leg_three = this.data.filter(function (child) {
          return child.level == 3 && child.leg == 3 ;
        });

      });
    },
  },
  beforeMount() {
    this.getResults();
  },
  computed:{
    level2_1leg: function () {
      return this.level_two_childs.filter(function(u) {
         return u.leg == 1;
     });
    },
    level2_2leg: function () {
      return this.level_two_childs.filter(function(u) {
         return u.leg == 2;
     });
    },
    level2_3leg: function () {
      return this.level_two_childs.filter(function(u) {
         return u.leg == 3;
     });
    },    
    level3_1leg_first_3: function () {
      return this.level_three_childs_leg_one.slice(0,3)
    },
    level3_1leg_mid_3: function () {
      return this.level_three_childs_leg_one.slice(3,6);
    },
    level3_1leg_last_3: function () {
       return this.level_three_childs_leg_one.slice(6,9);
    },
    level3_2leg_first_3: function () {
      return this.level_three_childs_leg_two.slice(0,3)
    },
    level3_2leg_mid_3: function () {
      return this.level_three_childs_leg_two.slice(3,6);
    },
    level3_2leg_last_3: function () {
       return this.level_three_childs_leg_two.slice(6,9);
    },
    level3_3leg_first_3: function () {
      return this.level_three_childs_leg_three.slice(0,3)
    },
    level3_3leg_mid_3: function () {
      return this.level_three_childs_leg_three.slice(3,6);
    },
    level3_3leg_last_3: function () {
       return this.level_three_childs_leg_three.slice(6,9);
    },
  }
}
</script>

<style scoped>
.tree {
  overflow-x: auto;
  width: 90%;
  margin: 0 auto;
}

.panel {
  position: relative;
  border: 0 solid transparent;
}

.custom-tree-data img.img-fluid { width: 30px; height: 30px; } 

.custom-tree-data a { font-size: 10px; }


.tree ul {
  list-style: none;
  padding-left: 0;
  padding-top: 20px;
  position: relative;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  display: flex;
}

.tree li:only-child {
  padding-top: 0;
}

.tree li {
  list-style-type: none;
  position: relative;
  padding: 20px 2px 0 5px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree ul ul::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #ccc;
  width: 0;
  height: 20px;
}

.tree ul li.left {
  width: 100%;

}

.tree li:first-child::before,
.tree li:last-child::after {
  border: 0 none;
}

.tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.tree li::after {
  right: auto;
  left: 50%;
  border-left: 1px solid #ccc;
}

.tree li::before,
.tree li::after {
  content: '';
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 1px solid #ccc;
  width: 50%;
  height: 20px;
}

.tree li:last-child::before {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}

.tree li:only-child::before {
  padding-top: 0;
  display: none;
}

.main .toggle i.fa-light.fa-bars {
  display: block;
}

.main .toggle i.fa-light.fa-xmark {
  display: none;
}

.main.active .toggle i.fa-light.fa-bars {
  display: none;
}

.main.active .toggle i.fa-light.fa-xmark {
  display: block;
}

.set_button {
  background-color: #29b6fe;
  padding: 6px 16px;
  font-size: 20px;
  border-radius: 5px;
  color: white;
  border: none;
}

.card.set_radius {
  background-color: #242424;
}

.img-data-tree img {
  border-radius: 50%;
}

h2.header-title {
  background-color: white;
  padding: 10px 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  font-weight: 700;
  font-style: italic;

}
</style>
